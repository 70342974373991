import React from 'react';
import { getAPIData } from "../../utils/apiFunction";
import DataTableStandard from "../../components/DataTableStandard";
import { useSearchParams } from "react-router-dom";
import { hexToHSL, hslToHex } from "../../utils/colourFunctions";
const PurchaseOrdersPage = (props) => {

    const [cropCols, setCropCols] = React.useState([]);
    const [newColour, setNewColour] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    var type = searchParams.get("type");

    
    const organiseCols = (cols) => {
        var sCols = cols.sort((a, b) => {
            var ha = hexToHSL(a.colour).h;
            var sa = hexToHSL(a.colour).s;
            var la = hexToHSL(a.colour).l;
            var hb = hexToHSL(b.colour).h;
            var sb = hexToHSL(b.colour).s;
            var lb = hexToHSL(b.colour).l;
            var da = Math.sqrt(Math.pow(ha, 2) + Math.pow(sa, 2) + Math.pow(la, 2));
            var db = Math.sqrt(Math.pow(hb, 2) + Math.pow(sb, 2) + Math.pow(lb, 2));
            return da - db;
        }).map((cc) => { return { ...cc, name: cc.crop ? cc.crop : cc.description } });
        setCropCols(sCols);
        var bigDiff = 0;
        var indexofBigDiff = -1;
        for (var i = 0; i < sCols.length; i++) {
            if (i < sCols.length - 1) {
                var h1 = hexToHSL(sCols[i].colour).h;
                var h2 = hexToHSL(sCols[i + 1].colour).h;
                var hDiff = h2 - h1;
                if (hDiff > bigDiff) {
                    bigDiff = hDiff;
                    indexofBigDiff = i;
                }
            }
        }
        var newH = hexToHSL(sCols[indexofBigDiff].colour).h + (bigDiff / 2);
        var newS = hexToHSL(sCols[indexofBigDiff].colour).s;
        var newL = hexToHSL(sCols[indexofBigDiff].colour).l;
        setNewColour(hslToHex(newH,newS,newL));

    }

    React.useEffect(() => {
        const getData = async () => {
            setLoading(true);
            if (type) {
                await getAPIData('/Farms/Lookups/' + type, organiseCols);
            } else {
                await getAPIData('/Farms/Lookups/BatchColours', organiseCols);
            }
            setLoading(false);
        }
        getData();
    }, [type]);
    var columns = [
        {
            name: 'name',
            label: 'Name'
        },
        {
            name: 'colour',
            label: 'Colour',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span style={{ backgroundColor: cropCols[dataIndex].colour, padding: '10px', paddingRight: '50px' }} >{cropCols[dataIndex].colour}</span>
                },
            }
        }
    ];

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        responsive: 'standard',
        rowsPerPage: 1000,
    };

    return (
        <React.Fragment>
            <span  >Suggested New Color (do not trust):</span><span style={{ backgroundColor: newColour, padding:'10px' }} >{newColour}</span>
        <DataTableStandard
            title={'Colours'}
            data={cropCols}
            columns={columns}
            options={options}
            returnSearch={!loading}
        />
        </React.Fragment>
    );
};


export default PurchaseOrdersPage;
