import React from "react";
import ProcurementDashboard from "../pages/Procurement/DashboardOld";
import POSummary from "../pages/Procurement/POSummaryOld";
import WeeklySummary from "../pages/Procurement/WeeklySummary";
import { default as PODB2 } from "../pages/Procurement/Dashboard";
import { default as POSum2 } from "../pages/Procurement/POSummary";
import SupplierPricing from "../pages/Procurement/SupplierPricing";
import FarmersSuppliers from "../pages/Procurement/FarmersSuppliers";
import Estuaries from "../pages/Procurement/Estuaries";
import Inventory from "../pages/Processing/Inventory";
import InventoryHistory from "../pages/Processing/InventoryHistory";
import LiveInventory from "../pages/Processing/LiveInventory";
import FrozenRegrade from "../pages/Processing/FrozenRegrade";
import BackToWater from "../pages/Processing/BackToWater";
import HalfshellRoom from "../pages/Processing/HalfshellRoom";
import StockTake from "../pages/Processing/StockTake";
import Receivals from "../pages/Processing/Receivals";
import ProcessingLogs from "../pages/Processing/ProcessingLogs";
import QualityAssurance from "../pages/Processing/QualityAssurance";
import ConditionRatings from "../pages/Settings/ConditionRatings";
import UserRequests from "../pages/Settings/UserRequests";
import ShellRatings from "../pages/Settings/ShellRatings";
import OysterMateColours from "../pages/Settings/OysterMateColours";
import StaffMembers from "../pages/Settings/StaffMembers";
import PurchaseOrders from "../pages/Processing/PurchaseOrders";
import SalesOrdersReport from "../pages/Sales/SalesOrdersReport";
import ShipmentErrors from "../pages/Sales/ShipmentErrors";
import SalesOrders from "../pages/Sales/SalesOrders";
import SalesOrdersManagement from "../pages/Sales/SalesOrdersManagement";
import CustomerInfo from "../pages/Sales/CustomerInfo";
import CustomerPricing from "../pages/Sales/CustomerPricing";
import TaskLandingPage from "../pages/Tasks/LandingPage";
import IntoShucking from "../pages/Demo/IntoShucking";
import MatchShucker from "../pages/Demo/MatchShucker";
import Fullfilment from "../pages/Demo/Fullfilment";
import Connect from "../pages/Connect/Connect";
import CustomerOrder from "../pages/Processing/CustomerOrder";
import MonthlyProcurement from "../pages/Reports/MonthlyProcurement";
import ProcessingFigures from "../pages/Reports/ProcessingFigures";
import FarmReports from "../pages/Reports/FarmReports";
import FridgeStatus from "../pages/Reports/FridgeStatus";
import MonthlyFarmReport from "../pages/Farms/MonthlyFarmReport";
import TotalsReport from "../pages/Farms/TotalsReport";
import FarmOTRs from "../pages/Farms/FarmOTRs";
import SharepointViewer from "../pages/Farms/SharepointViewer";
import FactoryOTRs from "../pages/Processing/FactoryOTRs";
import Map from "../pages/OysterPL8/Map";
import OnShore from "../pages/OysterPL8/OnShore";
import History from "../pages/OysterPL8/History";
import DataDownload from "../pages/OysterPL8/DataDownloads";
import Movements from "../pages/OysterPL8/OMLogs/Movements";
import Deployments from "../pages/OysterPL8/OMLogs/Deployments";
import Agenda from "../pages/Farms/Agenda";
import { ACCESS, ACCESS_SECTIONS } from "../utils/constants/access";
import DailyPlan from "../pages/Reports/DailyPlan";
import HalfshellPlan from "../pages/Reports/HalfshellPlan";
import LeaseBreakDown from "../pages/OysterPL8/OMLogs/LeaseBreakDown";
import SOSummary from "../pages/Sales/SOSummary";
import HalfshellInOuts from "../pages/Reports/HalfshellInOuts";
import LiveInventoryReport from "../pages/Processing/LiveInventoryReport";

//const TestReportPage = () => {
//    return (
//        <div>
//            <iframe title="OysterMate Current Stock" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=477e4ee5-f1ab-4311-83fb-3ee259e1258b&autoAuth=true&ctid=bf6a0e22-99c4-4ddf-96e3-8e2575d41787" frameborder="0" allowFullScreen="true"></iframe>
//        </div>
//    )
//}

const routes = [
    {
        path: "/",
        element: <ProcurementDashboard />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/dashboard",
        element: <ProcurementDashboard />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/POSummary2",
        element: <POSum2 />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/dashboard2",
        element: <PODB2 />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/POSummary",
        element: <POSummary />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/WeeklySummary",
        element: <WeeklySummary />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/estuaries",
        element: <Estuaries />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/farmers",
        element: <FarmersSuppliers />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/procurement/supplierPricing",
        element: <SupplierPricing />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.ADMIN }
    },
    {
        path: "/processing/liveinventory",
        element: <LiveInventory />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/frozenregrade",
        element: <FrozenRegrade />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    },
    {
        path: "/processing/backtowater",
        element: <BackToWater />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    },
    {
        path: "/processing/halfshellroom",
        element: <HalfshellRoom />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/halfshellplan",
        element: <HalfshellPlan />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/halfshellinout",
        element: <HalfshellInOuts />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/inventory",
        element: <Inventory />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/inventoryhistory",
        element: <InventoryHistory />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/stocktake",
        element: <StockTake />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/purchaseorders",
        element: <PurchaseOrders />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/receivals",
        element: <Receivals />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/qualityAssurance",
        element: <QualityAssurance />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/processingLogs",
        element: <ProcessingLogs />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/processing/customerOrder",
        element: <CustomerOrder />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/sales/summary",
        element: <SOSummary />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    },
    {
        path: "/sales/salesorders",
        element: <SalesOrders />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    },
    {
        path: "/sales/report",
        element: <SalesOrdersReport />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    },
    {
        path: "/sales/salesordersmanage",
        element: <SalesOrdersManagement />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    },
    {
        path: "/sales/customerpricing",
        element: <CustomerPricing />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    },
    {
        path: "/sales/customerinfo",
        element: <CustomerInfo />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    },
     {
         path: "/sales/shipmenterrors",
        element: <ShipmentErrors />,
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/tasks",
        element: <TaskLandingPage />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    }
    ,
    {
        path: "/reports/DailyPlan",
        element: <DailyPlan />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/reports/MonthlyPro",
        element: <MonthlyProcurement />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/reports/ProcessingReport",
        element: <ProcessingFigures />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/reports/Farm",
        element: <FarmReports />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/reports/LiveInventory",
        element: <LiveInventoryReport />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/reports/Fridges",
        element: <FridgeStatus />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW }
    },
    {
        path: "/Farmers/FarmManagerReport",
        element: <MonthlyFarmReport />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/Farmers/Agenda",
        element: <Agenda />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/Farmers/OTR",
        element: <FarmOTRs />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/Factory/OTR",
        element: <FactoryOTRs />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    }
    ,
    {
        path: "/Demo/intoShucking",
        element: <IntoShucking />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    }
    ,
    {
        path: "/Demo/PRNtoShucker",
        element: <MatchShucker />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    }
    ,
    {
        path: "/Demo/Fullfilment",
        element: <Fullfilment />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    },
    {
        path: "/Connect",
        element: <Connect />,

    },
    {
        path: "/OysterM8",
        element: <Map />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/OnShore",
        element: <OnShore />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/History",
        element: <History />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/DataDownload",
        element: <DataDownload />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/TotalsReport",
        element: <TotalsReport />,
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/Logs/Movements",
        element: <Movements />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/Logs/Deployments",
        element: <Deployments />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/OysterM8/Logs/LeaseStats",
        element: <LeaseBreakDown />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/Farmers/Sharepoint",
        element: <SharepointViewer />,
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    },
    {
        path: "/settings/qualityAssurance/shell",
        element: <ShellRatings />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN }
    }
    ,
    {
        path: "/settings/qualityAssurance/condition",
        element: <ConditionRatings />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN }
    }
    ,
    {
        path: "/settings/UserRequests",
        element: <UserRequests />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }
    }
    ,
    {
        path: "/settings/OysterMateColours",
        element: <OysterMateColours />,
        access: { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.VIEW }
    },
    {
        path: "/settings/StaffMembers",
        element: <StaffMembers />,
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN }
    }
    //,
    //{
    //    path: "/OysterM8/TestReport",
    //    element: <TestReportPage />,
    //    access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW }
    //}
];
export default routes;


