import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { startOfWeek, shiftWeek } from "../../../utils/dateFunctions";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { delIcon } from "../../../themes/myStyles";
import { getAPIData, getAPIDataReturn, getAPIDataParams, postApi } from '../../../utils/apiFunction';
import { adalApiFetch } from '../../../../adalConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import DropZone from "../../../components/DropZone";
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { checkAccess } from '../../../utils/roleFunctions';
import { CircularProgress } from '@mui/material';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import OystersGroup from "../../../components/OysterGroup";
import OysterOrder from "../../../components/OysterOrder";
import { textfieldDateFormat, dateTimeFormat } from "../../../utils/dateFunctions";
import StaffMember from "../../../components/StaffMember";
import ControlledTextField from "../../../components/ControlledTextField";
import CropYearSelect from "../../../components/CropYearSelect";
import SelectTextField from "../../../components/SelectTextField";
import SupplierSelect from "../../../components/SupplierSelect";
import SelectWarehouse from "../../../components/SelectWarehouse";
import PurchaseOrderSelect from "../../../components/PurchaseOrderSelect";
import EstuaryStatusSelect from "../../../components/EstuaryStatusSelect";
import ETransferDisplay from "../../../components/ETransferDisplay";
import FormModal from '../../../components/FormModal';
import POSelectForm from './POSelectForm';
import PurchaseOrdersPage from '../PurchaseOrders';
import { Warehouse } from '@mui/icons-material';

const ConfirmRec = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const sinObjArr = [{}];
    const empArr = [];
    const [completed, setCompleted] = React.useState(props.completed);
    const [warehouse, setWarehouse] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [pageloading, setPageLoading] = React.useState(false);
    const [receival, setReceival] = React.useState(false);
    const [oysters, setOysters] = React.useState([{}]);
    const [POId, setPOId] = React.useState(null);
    const [OTR, setOTR] = React.useState(null);
    const [PRN, setPRN] = React.useState(null);
    const [harvestDate, setHarvestDate] = React.useState(null);
    const [estuary, setEstuary] = React.useState(null);
    const [harvestArea, setHarvestArea] = React.useState(null);
    const [lease, setLease] = React.useState(null);
    const [multipleCropYear, setMultipleCropYear] = React.useState(false);
    const [cropYear, setCropYear] = React.useState(null);
    const [cropYearCode, setCropYearCode] = React.useState(null);
    const [temperature, setTemperature] = React.useState(null);
    const [staffMember, setStaffMember] = React.useState(null);
    const [receivalComments, setReceivalComments] = React.useState(null);
    const [pickupDate, setPickUpDate] = React.useState(props.data ? (props.data.pickupDate) : '');
    const id = props.data ? props.data.id : '';
    const receivalId = props.data ? props.data.id : '';
    const [supplier, setSupplier] = React.useState(null);
    //const supplier = props.data ? props.data.supplierPredict : '';
    const vertical = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const Swal = useSwalWrapper();
    const [driver, setDriver] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    const [transfer, setTransfer] = React.useState(null);
    const [selectedOysters, setSelectedOysters] = React.useState([{}]);
    const [selectedPOOysters, setSelectedPOOysters] = React.useState([{}]);
    const [purchaseOrder, setPurchaseOrder] = React.useState(null);
    const [pOOysters, setPOOysters] = React.useState([{}]);
    const [NewWarehouse, setNewWarehouse] = React.useState(null);
    React.useEffect(() => {
        setPageLoading(true);
        getAPIDataReturn('/Receivals/Get/' + id).then((data) => {
            if (data.transferId) {
                getAPIData('/Transfers/Get/' + data.transferId, setTransfer);
            }
            if (data.purchaseOrderId) {
                getAPIData('/PurchaseOrder/Get/' + data.purchaseOrderId, (po) => { if (po?.length > 0) { setPurchaseOrder(po[0]) } });
            }
            setReturnData(data);
            setPageLoading(false);
        });

    }, [])
    console.log(oysters);
    console.log(selectedOysters);
    console.log(pOOysters);
    console.log(selectedPOOysters);
    React.useEffect(() => {
        console.log(returnData);
        if (returnData) {

            var crpYear = null;
            var oysterArr = [];
            var multi = false;
            for (var i = 0; i < returnData.receivalLines.length; i++) {
                oysterArr.push(returnData.receivalLines[i]);
            }

            setOysters(oysterArr.length > 0 ? [...oysterArr] : [{}]);
            setSelectedOysters(oysterArr.length > 0 ? [...oysterArr] : [{}]);
        }
    }, [returnData])
    React.useEffect(() => {
        if (purchaseOrder) {
            var oysterArr = [];
            if (purchaseOrder.orderInputLines) {
                for (var i = 0; i < purchaseOrder.orderInputLines.length; i++) {
                    purchaseOrder.orderInputLines[i].quantity = purchaseOrder.orderInputLines[i].ordered;
                    oysterArr.push(purchaseOrder.orderInputLines[i]);
                }
            }
            setSupplier(purchaseOrder.supplier);

            setPOOysters(oysterArr.length > 0 ? [...oysterArr] : [{}]);
            setSelectedPOOysters(oysterArr.length > 0 ? [...oysterArr] : [{}]);
        }
    }, [purchaseOrder])
    console.log(oysters)

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!NewWarehouse) {
            errorMessages.push('Please select a warehouse to split to');
        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const deleteAll = async () => {

    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var newPO = null;
        if (purchaseOrder) {
            var poData = {
                Id: purchaseOrder.id,
            }
            var respnewPO = await postApi('/PurchaseOrder/Duplicate/' + purchaseOrder.id, poData);
            newPO = await respnewPO.json();
            //do lines
            if (purchaseOrder.orderInputLines && purchaseOrder.orderInputLines.length > 0) {
                var promArr = [];
                //Oyster stock Items
                var reducedPOOysters = [];
                for (var i = 0; i < pOOysters.length; i++) {
                    var oyLine = reducedPOOysters.findIndex((oy) => {
                        if (oy.stockItem && pOOysters[i].stockItem) {
                            return ((oy.stockItem.id === pOOysters[i].stockItem.id) && (oy.price === pOOysters[i].price));
                        }
                        return false;
                    });
                    if (oyLine >= 0) {
                        reducedPOOysters[oyLine].quantity = Number(reducedPOOysters[oyLine].quantity ? reducedPOOysters[oyLine].quantity : 0) + Number(pOOysters[i].quantity ? pOOysters[i].quantity : 0);
                    } else {
                        reducedPOOysters.push({ ...pOOysters[i] });
                    }
                }
                console.log(reducedPOOysters);
                promArr = reducedPOOysters.map((oy) => {
                    var oyData = {
                        StockItemId: oy.stockItem ? oy.stockItem.id : 0,
                        Price: oy.price ? oy.price : 0,
                        WarehouseId: warehouse ? warehouse.id : 7,
                        VariationReason: oy.variationReason ? oy.variationReason : null,
                        Quantity: oy.quantity ? oy.quantity : 0,
                        ChangePrice: oy.changePrice ? oy.changePrice : false,
                    }
                    if (oy.id) {
                        return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Update/' + oy.id + '?' + new URLSearchParams({
                            ...oyData
                        }), {
                            method: 'post',
                        });
                    } else {
                        return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Insert/' + purchaseOrder.id + '?' + new URLSearchParams({
                            ...oyData
                        }), {
                            method: 'post',
                        });
                    }
                })

                if (purchaseOrder) {
                    if (purchaseOrder.orderInputLines && (purchaseOrder.orderInputLines.length > 0)) {
                        purchaseOrder.orderInputLines.map((rl) => {
                            var exID = reducedPOOysters.find((oy) => { return oy.id && (oy.id === rl.id) });
                            if (!exID) {
                                return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Delete/' + rl.id, {
                                    method: 'delete',
                                });
                            }
                        })
                    }
                }
                var newpromArr = [];

                //newLines
                reducedPOOysters = [];
                for (var i = 0; i < selectedPOOysters.length; i++) {
                    var oyLine = reducedPOOysters.findIndex((oy) => {
                        if (oy.stockItem && selectedPOOysters[i].stockItem) {
                            return ((oy.stockItem.id === selectedPOOysters[i].stockItem.id) && (oy.price === selectedPOOysters[i].price));
                        }
                        return false;
                    });
                    if (oyLine >= 0) {
                        reducedPOOysters[oyLine].quantity = Number(reducedPOOysters[oyLine].quantity ? reducedPOOysters[oyLine].quantity : 0) + Number(selectedPOOysters[i].quantity ? selectedPOOysters[i].quantity : 0);
                    } else {
                        reducedPOOysters.push({ ...selectedPOOysters[i] });
                    }

                }
                console.log(reducedPOOysters);
                newpromArr = reducedPOOysters.map((oy) => {
                    var oyData = {
                        StockItemId: oy.stockItem ? oy.stockItem.id : 0,
                        Price: oy.price ? oy.price : 0,
                        WarehouseId: NewWarehouse? NewWarehouse.id : 0,
                        VariationReason: oy.variationReason ? oy.variationReason : null,
                        Quantity: oy.quantity ? oy.quantity : 0,
                        ChangePrice: oy.changePrice ? oy.changePrice : false,
                    }

                    return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Insert/' + newPO.id + '?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });
                })
                await Promise.all(promArr);
                await Promise.all(newpromArr);
            }
        }
        //duplicate the receival
        console.log(newPO);
        var data = {
            WarehouseId: NewWarehouse.id,
        }
        if(newPO){
            data.PurchaseOrderId = newPO.id;
        }
        var respnewRec = await postApi('/Receivals/Duplicate/' + id, data);
        var newRec = await respnewRec.json();
        //new lines
        console.log(selectedOysters)
        if (returnData.receivalLines && (returnData.receivalLines.length > 0)) {
            var newLinesPrmArr = selectedOysters.map((oy) => {
                var oyData = {
                    receivalId: newRec.id,
                    stockItemId: oy.stockItem ? oy.stockItem.id : null,
                    Bags: oy.bags ? oy.bags : 0,
                    Bins: oy.bins ? oy.bins : 0,
                    Quantity: oy.quantity ? oy.quantity : 0,
                    CropYear: (oy.cropYear ? oy.cropYear : null)
                }

                return adalApiFetch(fetch, uri + '/ReceivalLines/Post?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });

            })

            var promArr = [];
            console.log(oysters)
            var reducedOysters = (oysters);
            promArr = reducedOysters.map((oy) => {
                var oyData = {
                    receivalId: id,
                    stockItemId: oy.stockItem ? oy.stockItem.id : null,
                    Bags: oy.bags ? oy.bags : 0,
                    Bins: oy.bins ? oy.bins : 0,
                    Quantity: oy.quantity ? oy.quantity : 0,
                    CropYear: (oy.cropYear ? oy.cropYear : null)
                }
                if (oy.id) {
                    return adalApiFetch(fetch, uri + '/ReceivalLines/Post/' + oy.id + '?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });
                } else {
                    return adalApiFetch(fetch, uri + '/ReceivalLines/Post?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });
                }
            })
            if (returnData) {
                if (returnData.receivalLines && (returnData.receivalLines.length > 0)) {
                    returnData.receivalLines.map((rl) => {
                        var exID = oysters.find((oy) => { return oy.id === rl.id });
                        if (!exID) {
                            return adalApiFetch(fetch, uri + '/ReceivalLines/Delete/' + rl.id, {
                                method: 'delete',
                            });
                        }
                    })
                }
            }


            await Promise.all(promArr);
            await Promise.all(newLinesPrmArr);
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    if (pageloading) {
        return <CircularProgress />
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        {returnData?.transferId ? <ETransferDisplay transferId={returnData.transferId} /> : <img src={returnData?.imagePath} alt='otr' width="100%" />}
                    </Grid>
                    <Grid container spacing={2} mb={1} >
                        <Grid item xs={6}  >
                            <SelectWarehouse warehouseId={returnData ? returnData.warehouseId : null} disabled={true} state={warehouse} setState={setWarehouse} />
                        </Grid>
                        <Grid item xs={6}  >
                            <SelectWarehouse warehouseId={null} disabled={completed} state={NewWarehouse} setState={setNewWarehouse} />
                        </Grid>
                    </Grid>
                    {purchaseOrder && purchaseOrder.orderInputLines?.length > 0 ? <Grid container spacing={2} mb={1} style={{ borderBottom: '1px solid black' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant="h3" gutterBottom component="div">
                                Purchase Order Lines
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ borderRight: '1px solid black' }}>
                            {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                            {purchaseOrder ?
                                <OysterOrder hideWarehouse halfSize={props.halfSize} customerId={supplier?.id} supplier oysters={pOOysters} starter={!(props.edit || completed || purchaseOrder?.orderInputLines?.length > 0)} setOysters={setPOOysters} disabled={completed} access={props.access} />
                                : null}
                        </Grid>
                        <Grid item xs={12} md={6} >
                            {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                            {purchaseOrder ?
                                <OysterOrder hideWarehouse halfSize={props.halfSize} customerId={supplier?.id} supplier oysters={selectedPOOysters} starter={!(props.edit || completed || purchaseOrder?.orderInputLines?.length > 0)} setOysters={setSelectedPOOysters} disabled={completed} access={props.access} />
                                : null}
                        </Grid>
                    </Grid> : null}
                    {returnData && returnData.receivalLines?.length > 0 ? <Grid container spacing={2} mb={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant="h3" gutterBottom component="div">
                                Receival Lines
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ borderRight: '1px solid black' }}>
                            {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                            {returnData ? <OystersGroup noUnits noTitle supplier={supplier} oysters={oysters} starter={!(props.edit || completed || returnData?.receivalLines?.length > 0)} setOysters={setOysters} disabled={completed} cropYear={supplier?.isInternal} /> : null}
                        </Grid>
                        <Grid item xs={12} md={6} >
                            {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                            {returnData ? <OystersGroup noUnits noTitle supplier={supplier} oysters={selectedOysters} starter={false} setOysters={setSelectedOysters} disabled={completed} cropYear={supplier?.isInternal} /> : null}
                        </Grid>
                    </Grid> : null}
                    <SubmitButtonDisplayer title="Split" completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

                </Grid>
            </Div>
        </Box >
    );
};
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}








export default ConfirmRec;
