import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import OysterOrder from "../../../components/OysterOrder";
import { textfieldDateFormat, startOfDay, tomorrow } from "../../../utils/dateFunctions";
import StaffMember from "../../../components/StaffMember";
import ControlledTextField from "../../../components/ControlledTextField";
import CropYearSelect from "../../../components/CropYearSelect";
import SelectTextField from "../../../components/SelectTextField";
import CustomerSelect from "../../../components/CustomerSelect";
import { GetAllCacheData, addToCache } from "../../../utils/cacheFunctions";
import { salesOrderTypes, SOstatusList } from '../../../utils/constants/selectFields';
import StringSelect from "../../../components/StringSelect";


const AddSalesOrder = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [shipVia, setShipVia] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [orderType, setOrderType] = React.useState(null);
    const [tempOrderType, setTempOrderType] = React.useState(null);
    const [customer, setCustomer] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [shipmentDate, setShipmentDate] = React.useState(null);
    const [oysters, setOysters] = React.useState([{}]);
    const [tempOysters, setTempOysters] = React.useState(null);
    const [nonStock, setNonStock] = React.useState([]);
    const [tempNonStock, setTempNonStock] = React.useState(null);
    const [orderDate, setOrderDate] = React.useState(new Date());
    const [frequency, setFrequency] = React.useState(1);
    const [seriesStart, setSeriesStart] = React.useState((startOfDay(tomorrow(new Date()))));
    const [seriesEnd, setSeriesEnd] = React.useState(null);
    const [weekDays, setWeekDays] = React.useState([false, false, false, false, false, false, false]);
    const id = props.data ? props.data.id : '';
    const [supplier, setSupplier] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    React.useEffect(() => {
        const checkArr = (data) => {
            var retData = data;
            if (Array.isArray(data)) {
                retData = data[0];
            }
            setReturnData(retData);
        }
        if (props.edit || props.completed || props.duplicate) {

            if (props.series) {
                getAPIData('/SalesSequence/Get?Id=' + id, setReturnData);
            } else {
                getAPIData('/SalesOrder/Get/' + id, checkArr);
            }
        }
    }, [])
    console.log(nonStock)
    const numToBool = (numArr) => {
        var boolArr = []
        for (var i = 0; i < 7; i++) {
            if (numArr.find((n) => n === i)) {
                boolArr.push(true);
            } else {
                boolArr.push(false);
            }
        }
        console.log(boolArr);
        return boolArr;
    }

    React.useEffect(() => {
        console.log(returnData);

        if (returnData) {
            if (!props.series) {
                setStatus(returnData.status);
                setShipVia(returnData.shipVia);
                setOrderDate(new Date(returnData.orderDate));
                setShipmentDate(new Date(returnData.shipmentDate));
                var orderTypeTempVal = salesOrderTypes.find((sot) => { return sot.value === returnData.orderType; });
                setOrderType({ ...orderTypeTempVal });
                setTempOrderType({ ...orderTypeTempVal });
                setCustomer(returnData.customer);
                setDescription(returnData.description);
                var oysterArr = [];
                var nonStockArr = [];
                if (returnData.salesOrderLines) {
                    for (var i = 0; i < returnData.salesOrderLines.length; i++) {
                        console.log(returnData.salesOrderLines[i].stockItemId)
                        if (returnData.salesOrderLines[i].stockItem ? !returnData.salesOrderLines[i].stockItem.isNonStockItem : true) {
                            oysterArr.push(returnData.salesOrderLines[i]);
                        } else {
                            nonStockArr.push(returnData.salesOrderLines[i])
                        }
                    }
                }
                setOysters([...oysterArr]);
                setTempOysters([...oysterArr]);

                setNonStock([...nonStockArr]);
                setTempNonStock([...(nonStockArr)]);
            } else {
                setFrequency(returnData.frequency);
                setSeriesStart(new Date(returnData.startDate));
                setDescription(returnData.description);
                setSeriesEnd(new Date(returnData.endDate));
                setWeekDays(returnData.salesSequenceDays ? numToBool(returnData.salesSequenceDays?.map(r => r.day)) : []);
                var oysterArr = [];
                var nonStockArr = [];
                if (returnData.salesSequenceLines) {
                    for (var i = 0; i < returnData.salesSequenceLines.length; i++) {
                        console.log(returnData.salesSequenceLines[i].stockItemId)
                        if (returnData.salesSequenceLines[i].stockItem ? !returnData.salesSequenceLines[i].stockItem?.isNonStockItem : true) {
                            oysterArr.push(returnData.salesSequenceLines[i]);
                        } else {
                            nonStockArr.push(returnData.salesSequenceLines[i])
                        }
                    }
                }
                setOysters([...oysterArr]);
                setTempOysters([...oysterArr]);

                setNonStock([...nonStockArr]);
                setTempNonStock([...nonStockArr]);
            }
        }
    }, [returnData])
    console.log(nonStock)

    const validate = () => {
        console.log(nonStock)
        var warningMessages = [];
        var errorMessages = [];

        if (!customer) {
            errorMessages.push("You must select a Customer");
        }
        if (!description || description === '') {
            errorMessages.push("You must enter a Description");
        }

        if (props.series) {
            if (frequency < 1) {
                errorMessages.push("Frequency must be at least 1 (every week)");
            }
            if (!seriesStart) {
                errorMessages.push("You must enter a Start Date");
            } else {
                if (!props.edit && (seriesStart < new Date())) {
                    errorMessages.push("Start Date must be in the future");
                }
            }
            if (!seriesEnd) {
                errorMessages.push("You must enter a End Date");
            }
            if (seriesStart >= seriesEnd) {
                errorMessages.push("Start date must be before end date");
            }
            if (!weekDays.reduce((a, c) => { return a || c }, false)) {
                errorMessages.push("You must select a day of the week");
            }
        } else {
            if (!status) {
                errorMessages.push("You must select a Status");
            }
            if (!shipmentDate) {
                errorMessages.push("You must enter a Shipment Date");
            }
            if (!orderDate) {
                errorMessages.push("You must enter a Order Date");
            }
        }

        for (var i = 0; i < oysters.length; i++) {
            if (!oysters[i].quantity) {
                warningMessages.push("You haven't entered a Quantity for every Oyster Line, Are you sure you want to continue?");

            }
            if (!oysters[i].price) {
                errorMessages.push("You must enter a Price for every Oyster Line");

            }
            if (!oysters[i].warehouseId) {
                errorMessages.push("You must select a Warehouse for every Oyster Line");

            }
            if (!oysters[i].stockItem) {
                errorMessages.push("You must select a Stock Item for every Oyster Line");

            }
            if (oysters[i].priceVary && !oysters[i].variationReason) {
                errorMessages.push("You must enter a reason that the price varies from this customers default price");

            }
        }
        for (var i = 0; i < nonStock.length; i++) {
            if (!nonStock[i].quantity) {
                errorMessages.push("You must enter a Quantity for every Non Stock Item Line");

            }
            if (!nonStock[i].price) {
                errorMessages.push("You must enter a Price for every Non Stock Item Line");

            }
            if (!nonStock[i].warehouseId) {
                errorMessages.push("You must select a Warehouse for every Non Stock Item Line");

            }
            if (!nonStock[i].stockItem) {
                errorMessages.push("You must select a Stock Item for every Non Stock Item Line");

            }
        }
        if (props.edit) {

            if (returnData?.seriesId && !props.series) {
                warningMessages.push("This will remove this sales order from the series. Are you sure you want to Continue?");

            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const deleteAll = async () => {

    }
    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var resp = null;
        if (!props.series) {
            var dateArr = buildDateArray(seriesStart, seriesEnd, frequency, weekDays);

            var seqId = uuidv4();

            var data = {
                SequenceId: seqId,
                CustomerId: customer?.id,
                Status: status,
                OrderDate: props.series ? textfieldDateFormat(dateArr[0]) : textfieldDateFormat(orderDate),
                ShipmentDate: props.series ? textfieldDateFormat(dateArr[0]) : textfieldDateFormat(shipmentDate),
                ShipVia: shipVia,
                OrderType: salesOrderTypes[0].value,
                Description: description ? description : ''
            }
            console.log(data, id);
            if (props.edit) {
                var response = await adalApiFetch(fetch, uri + '/SalesOrder/Update/' + id + '?' + new URLSearchParams({
                    ...data
                }), {
                    method: 'post',
                });
            } else {

                var response = await adalApiFetch(fetch, uri + '/SalesOrder/Insert/?' + new URLSearchParams({
                    ...data
                }), {
                    method: 'post',
                });
            }
            resp = await (response.json());
            ///build series
        } else {
            const toNumArr = (boolArr) => {
                var numArr = []
                for (var i = 0; i < boolArr.length; i++) {
                    if (boolArr[i]) {
                        numArr.push(i);
                    }
                }
                return numArr;
            }
            var data = {
                Id: id,
                CustomerId: customer?.id,
                StartDate: textfieldDateFormat(seriesStart),
                EndDate: textfieldDateFormat(seriesEnd),
                Frequency: frequency,
                Description: description ? description : ''
            }

            var Days = toNumArr(weekDays).reduce((a, c) => { return a + "&Days=" + c }, '');
            if (props.edit) {
                var response = await adalApiFetch(fetch, uri + '/SalesSequence/Update?' + new URLSearchParams({
                    ...data
                }) + Days, {
                    method: 'post',
                });
            } else {

                var response = await adalApiFetch(fetch, uri + '/SalesSequence/Insert?' + new URLSearchParams({
                    ...data
                }) + Days, {
                    method: 'post',
                });
            }
            resp = await (response.json());
        }

        console.log(resp);
        if (resp) {
            //Oyster stock Items
            var reducedOysters = [];
            for (var i = 0; i < oysters.length; i++) {
                var oyLine = reducedOysters.findIndex((oy) => {
                    if (oy.stockItem && oysters[i].stockItem) {
                        return ((oy.stockItem.id === oysters[i].stockItem.id) && (oy.warehouseId === oysters[i].warehouseId));
                    }
                    return false;
                });
                if (oyLine >= 0) {
                    reducedOysters[oyLine].quantity = Number(reducedOysters[oyLine].quantity ? reducedOysters[oyLine].quantity : 0) + Number(oysters[i].quantity ? oysters[i].quantity : 0);
                } else {
                    reducedOysters.push({ ...oysters[i] });
                }
            }
            // promArr = reducedOysters.map((oy) => {
            for (var j = 0; j < reducedOysters.length; j++) {
                var oy = reducedOysters[j];
                var oyData = {
                    SalesSequenceId: resp.id,
                    SalesOrderId: resp.id,
                    StockItemId: oy.stockItem ? oy.stockItem.id : 0,
                    Price: oy.price ? oy.price : 0,
                    WarehouseId: oy.warehouseId ? oy.warehouseId : 0,
                    VariationReason: oy.variationReason ? oy.variationReason : null,
                    Quantity: oy.quantity ? oy.quantity : 0,
                    changePrice: oy.changePrice ? oy.changePrice : false,
                }
                console.log(oy, resp)
                if (!props.series) {
                    await adalApiFetch(fetch, uri + '/SalesOrder/AddUpdateLine?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });
                } else {
                    await adalApiFetch(fetch, uri + '/SalesSequence/AddUpdateLine' + '?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });
                }
            }
            if (tempOysters) {
                console.log(tempOysters, reducedOysters)
                if (tempOysters && (tempOysters.length > 0)) {
                    for (var j = 0; j < tempOysters.length; j++) {
                        var rl = tempOysters[j];
                        // tempOysters.map((rl) => {
                        var exID = reducedOysters.find((oy) => ((oy.stockItem.id === rl.stockItemId) && (oy.warehouseId === rl.warehouseId)));
                        if (!exID) {
                            const delData = {
                                SalesSequenceId: resp.id,
                                SalesOrderId: resp.id,
                                StockItemId: rl.stockItemId,
                                WarehouseId: rl.warehouseId,
                            }
                            if (!props.series) {
                                await adalApiFetch(fetch, uri + '/SalesOrder/DeleteLine' + '?' + new URLSearchParams({
                                    ...delData
                                }), {
                                    method: 'delete',
                                });
                            } else {
                                await adalApiFetch(fetch, uri + '/SalesSequence/DeleteLine' + '?' + new URLSearchParams({
                                    ...delData
                                }), {
                                    method: 'delete',
                                });
                            }
                        }
                    }
                }
            }
            ///////////////////////////
            //Non stock Items
            var reducedItems = [];
            for (var i = 0; i < nonStock.length; i++) {
                var oyLine = reducedItems.findIndex((oy) => {
                    if (oy.stockItem && nonStock[i].stockItem) {
                        return ((oy.stockItem.id === nonStock[i].stockItem.id) && (oy.warehouseId === oysters[i].warehouseId));
                    }
                    return false;
                });
                if (oyLine >= 0) {
                    reducedItems[oyLine].quantity = Number(reducedItems[oyLine].quantity ? reducedItems[oyLine].quantity : 0) + Number(nonStock[i].quantity ? nonStock[i].quantity : 0);
                } else {
                    reducedItems.push({ ...nonStock[i] });
                }
            }
            // promArr = reducedItems.map((oy) => {
            for (var j = 0; j < reducedItems.length; j++) {
                var oy = reducedItems[j];
                var oyData = {
                    SalesSequenceId: resp.id,
                    SalesOrderId: resp.id,
                    StockItemId: oy.stockItem ? oy.stockItem.id : 0,
                    Price: oy.price ? oy.price : 0,
                    WarehouseId: oy.warehouseId ? oy.warehouseId : 0,
                    VariationReason: oy.variationReason ? oy.variationReason : null,
                    Quantity: oy.quantity ? oy.quantity : 0,
                    changePrice: oy.changePrice ? oy.changePrice : false,

                }
                if (!props.series) {
                    await adalApiFetch(fetch, uri + '/SalesOrder/AddUpdateLine' + '?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });

                } else {
                    await adalApiFetch(fetch, uri + '/SalesSequence/AddUpdateLine' + '?' + new URLSearchParams({
                        ...oyData
                    }), {
                        method: 'post',
                    });
                }
            }
            if (tempNonStock) {
                if (tempNonStock && (tempNonStock.length > 0)) {
                    // tempNonStock.map((rl) => {
                    for (var j = 0; j < tempNonStock.length; j++) {
                        var rl = tempNonStock[j];
                        console.log(reducedItems, rl)
                        var exID = reducedItems.find((oy) => ((oy.stockItem.id === rl.stockItemId) && (oy.warehouseId === rl.warehouseId)));
                        if (!exID) {
                            const delData = {
                                SalesSequenceId: resp.id,
                                SalesOrderId: resp.id,
                                StockItemId: rl.stockItemId,
                                WarehouseId: rl.warehouseId,
                            }
                            if (!props.series) {
                                await adalApiFetch(fetch, uri + '/SalesOrder/DeleteLine' + '?' + new URLSearchParams({
                                    ...delData
                                }), {
                                    method: 'delete',
                                });
                            } else {
                                await adalApiFetch(fetch, uri + '/SalesSequence/DeleteLine' + '?' + new URLSearchParams({
                                    ...delData
                                }), {
                                    method: 'delete',
                                });
                            }
                        }
                    }
                }
            }
            ////////////////////////////////////////////////////////////////////////


        }
        setLoading(false);
        if (props.reload) {
            console.log(props.reload)
            props.reload();
        }

    }
    const checkDayofWeek = (check, day) => {
        var dayList = [...weekDays];
        dayList[day] = check;
        setWeekDays(dayList);
    }
    const buildDateArray = (start, end, freq, days) => {
        var begin = new Date(start);
        var dateArr = [];
        console.log(start)
        var freqCounter = Number(freq);
        var dayCount = 0;
        if (freq > 0) {
            while (begin <= startOfDay(end)) {
                if (days[begin.getDay()] && (freqCounter === Number(freq))) {
                    dateArr.push(begin);
                }

                dayCount++;
                if (dayCount > 6) {
                    if (freqCounter > 1) {
                        freqCounter--;
                    } else {
                        freqCounter = Number(freq);
                    }
                    dayCount = 0;
                }
                begin = tomorrow(begin);
            }
        }
        return dateArr;
    }
    const generateTextDays = (start, end, freq, days) => {
        var dateArr = buildDateArray(start, end, freq, days);
        var textDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var dayStr = '';
        for (var i = 0; i < days.length; i++) {
            if (days[i]) {
                if (dayStr === '') {
                    dayStr = textDays[i];
                } else {
                    dayStr = dayStr + ', ' + textDays[i];
                }
            }
        }
        return 'This series will create ' + dateArr.length + ' Sales Orders. ' + (dateArr.length > 0 ? ('They will be created for ' + dayStr + ' every ' + (freq > 1 ? (freq + ' weeks') : ' week')) : '');
    }
    console.log(tempOysters)
    if (props.edit && !returnData) {
        return <CircularProgress />
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                {/*<Typography variant={"h3"}>Confirm OTR Information</Typography>*/}
                <Grid container spacing={0} mb={1}>
                    {!props.onlyOysters ?
                        <React.Fragment>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <CustomerSelect frmrId={(returnData ? returnData.customerId : null)} state={customer} setState={setCustomer} disabled={props.edit || completed} />
                            </Grid>
                            {!props.series ? <React.Fragment><Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

                                <DatePicker date={orderDate} setDate={setOrderDate} label="Order Date" />
                            </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

                                    <DatePicker date={shipmentDate} setDate={setShipmentDate} label="Shipment Date" />

                                </Grid></React.Fragment> : ''}
                            {!props.series ? <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <StringSelect title={"Status"} input={(returnData ? returnData.status : null)} state={status} setState={setStatus} options={SOstatusList} disabled={completed} default={0} />
                            </Grid> : null}
                            {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <StringSelect title={"Order Type"} input={(tempOrderType ? tempOrderType : null)} state={orderType} setState={setOrderType} options={salesOrderTypes} disabled={true} default={0} labelFunc={(a) => a.name} />
                    </Grid> */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <TextField
                                    disabled={completed}
                                    id="outlined-multiline-static"
                                    label="Description"
                                    value={description}
                                    onChange={(event) => { setDescription(event.target.value) }}
                                    multiline
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    rows={4} />
                            </Grid>
                            {props.series ? <React.Fragment>
                                <Grid item xs={12} >
                                    <h3 style={{ fontWeight: 450 }}>{"Series Description"}</h3>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

                                    <DatePicker date={seriesStart} setDate={setSeriesStart} label="Start Date" disabled={completed} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

                                    <DatePicker date={seriesEnd} setDate={setSeriesEnd} label="End Date" disabled={completed} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>

                                    <ControlledTextField key={'qua' + props.index} title="Frequency (Weeks)" state={frequency} setState={setFrequency} disabled={completed} number />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                    <FormGroup>
                                        <Grid container spacing={0} mb={1}>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[0]} onChange={(event) => { checkDayofWeek(event.target.checked, [0]); }} />} label="S" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[1]} onChange={(event) => { checkDayofWeek(event.target.checked, [1]); }} />} label="M" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[2]} onChange={(event) => { checkDayofWeek(event.target.checked, [2]); }} />} label="T" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[3]} onChange={(event) => { checkDayofWeek(event.target.checked, [3]); }} />} label="W" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[4]} onChange={(event) => { checkDayofWeek(event.target.checked, [4]); }} />} label="T" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[5]} onChange={(event) => { checkDayofWeek(event.target.checked, [5]); }} />} label="F" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={weekDays[6]} onChange={(event) => { checkDayofWeek(event.target.checked, [6]); }} />} label="S" disabled={completed} labelPlacement="top" />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} >
                                    <h5 style={{ fontWeight: 450 }}>{generateTextDays(seriesStart, seriesEnd, frequency, weekDays)}</h5>
                                </Grid>
                            </React.Fragment> : ''}
                        </React.Fragment> : ''}
                    <Grid item xs={12} >
                        {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                        <OysterOrder access={props.access} customerId={customer?.id} processed oysters={oysters} starter={!(props.edit || completed)} setOysters={setOysters} disabled={completed} />
                        <OysterOrder nonStock customerId={customer?.id} processed oysters={nonStock} starter={!(props.edit || completed)} setOysters={setNonStock} disabled={completed} />
                    </Grid>
                </Grid>

                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

            </Div>
        </Box >
    );
};

const convertToStockObj = (list) => {
    console.log('k')
    return list.map((l) => {
        var k = { ...l };
        k.stockItem = l.nonStockItem;
        k.stockItemId = l.nonStockItemId;
        return k;
    })
}
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}








export default AddSalesOrder;
