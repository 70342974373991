import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow, textfieldDateFormat, shiftHours, dateFormat } from "../../utils/dateFunctions";
import { getAPIData, getAPIDataParams, getAPIDataParamsReturn } from "../../utils/apiFunction";
import { numberFormat } from '../../utils/numberFunctions';
import DatePicker from "../../shared/DatePicker";
import SelectWarehouse from '../../components/SelectWarehouse';
import { WAREHOUSEGROUPS, WAREHOUSEMATCHES } from "../../utils/constants/warehouse";
import { useJumboTheme } from "@jumbo/hooks";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TableRow, Table, TableCell, TableHead, TableBody, Typography, CircularProgress, Paper } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, Line } from 'recharts';
import DateSelector from 'app/shared/DateSelector';
import { hexToHSL, hslToHex } from "../../utils/colourFunctions";

const HalfshellInOuts = (props) => {
    const { theme } = useJumboTheme();
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(shiftHours(new Date(), -24 * 30));
    const [endDate, setEndDate] = React.useState(new Date());
    const [location, setLocation] = React.useState(null);
    const [display, setDisplay] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [dailyGoals, setDailyGoals] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [OrderColumns, setOrderColumns] = React.useState(null);
    const [showTomorrow, setShowTomorrow] = React.useState(false);
    const [showChart, setShowChart] = React.useState(true);

    var showColumns = []; //only cahgne in geneterate body data
    const [topLevelList, setTopLevelList] = React.useState([]);
    const productPalette = theme.palette.oysterProducts;


    React.useEffect(() => {
        setLoading(true);
        if (location) {
            getAPIDataParamsReturn('/Reports/HalfshellOutputHistory', { EndDate: textfieldDateFormat(endDate), StartDate: textfieldDateFormat(startDate), WarehouseId: location?.id }).then((dgs) => {
                setData(dgs);
                setLoading(false);
            });
        }
    }, [location, startDate, endDate, reload]);

    const organiseForChart = (dataU) => {
        if (dataU) {
            //get unique list of dates
            var dates = [];
            for (var i = 0; i < dataU.length; i++) {
                if (!dates.includes(dataU[i].date)) {
                    dates.push(dataU[i].date);
                }
            }
            //group data by date
            var groupedData = []
            for (var i = 0; i < dates.length; i++) {
                var newGroup = { Date: dates[i], Output: 0, Input: 0 };
                for (var j = 0; j < dataU.length; j++) {
                    if (dataU[j].date === dates[i]) {
                        newGroup.Output += dataU[j].output;
                        newGroup.Input += dataU[j].input;
                    }
                }
                groupedData.push(newGroup);
            }
            var chartData = [];
            for (var i = 0; i < groupedData.length; i++) {
                var obj;
                obj = { name: groupedData[i].Date, output: groupedData[i].Output, input: groupedData[i].Input, difference: groupedData[i].Output - groupedData[i].Input };
                chartData.push(obj);
            }
            return chartData;
        }
        return [];
    }
    const organiseForTable = (dataU) => {
        if (dataU) {
            //get unique list of dates
            var dates = [];
            for (var i = 0; i < dataU.length; i++) {
                if (!dates.includes(dataU[i].date)) {
                    dates.push(dataU[i].date);
                }
            }
            //group data by date
            var groupedData = []
            for (var i = 0; i < dates.length; i++) {
                var newGroup = { Date: dates[i], entries: [] };
                for (var j = 0; j < dataU.length; j++) {
                    if (dataU[j].date === dates[i]) {
                        newGroup.entries.push(dataU[j]);
                    }
                }
                groupedData.push(newGroup);
            }

            return groupedData.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        }
        return [];
    }
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DateSelector onlyCustom startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} label="Date" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <SelectWarehouse access={props.access} enforceAccess={true} state={location} setState={setLocation} label="Date" all useCache  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <Button variant='contained' onClick={() => { setShowChart(sc => !sc) }}>{showChart ? 'Show Table' : 'Show Charts'}</Button>
                </Grid>
                <Grid item xs={12}>
                <Typography variant={'h1'} style={{ paddingTop: '25px' }}>{location?location.description:''} Processing Ins and Outs</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    {!showChart ?
                        <ToggleButtonGroup
                            value={display}
                            color="primary"
                            exclusive
                            onChange={(event, newValue) => {
                                setDisplay(newValue);
                            }}
                            aria-label="text alignment"
                        >
                            <ToggleButton value={0} aria-label="left aligned">
                                All
                            </ToggleButton>
                            <ToggleButton value={1} aria-label="centered">
                                Gains
                            </ToggleButton>
                            <ToggleButton value={2} aria-label="right aligned">
                                Losses
                            </ToggleButton>
                        </ToggleButtonGroup>

                        : null}
                </Grid>
                {location ? null : <Typography variant={'h1'} style={{ padding: '50px' }}><ArrowUpwardIcon />Please Choose a Warehouse<ArrowUpwardIcon /></Typography>}
                {loading ? <CircularProgress /> : (showChart ? <React.Fragment>


                    <Grid item xs={12}>
                        <BarChart width={1300} height={550} data={organiseForChart(data)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tickFormatter={dateFormat} />
                            <YAxis tickFormatter={numberFormat}/>
                            <Tooltip labelFormatter={dateFormat} formatter={numberFormat}/>
                            <Legend />
                            <Bar dataKey="input" name="Input" fill="#8884d8" />
                            <Bar dataKey="output" name="Output" fill="#82ca9d" />
                        </BarChart>
                    </Grid>
                    <Grid item xs={12}>
                        <BarChart width={1300} height={550} data={organiseForChart(data, true)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tickFormatter={dateFormat} />
                            <YAxis tickFormatter={numberFormat}/>
                            <Tooltip labelFormatter={dateFormat}  formatter={numberFormat} />
                            <Legend />
                            <Bar dataKey="difference" name="Difference (Out - In)" fill="#82ca9d" >
                                {organiseForChart(data).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.difference > 0 ? "#82ca9d" : 'red'} />
                                ))}
                            </Bar>
                        </BarChart>
                    </Grid>
                </React.Fragment> : (
                    organiseForTable(data).map((gd) => {

                        return (<Grid item xs={12}>
                            <Typography variant={'h3'} style={{ paddingTop: '25px' }}>{dateFormat(gd.Date)}</Typography>
                            <Paper>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Stock Item</TableCell>
                                            <TableCell>Supplier</TableCell>
                                            <TableCell>Lot Serial No.</TableCell>
                                            <TableCell>Input</TableCell>
                                            <TableCell>Output</TableCell>
                                            <TableCell>Difference</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {gd.entries.filter((e) => {
                                            if (display === 0) {
                                                return true;
                                            } else if (display === 1) {
                                                return e.output - e.input > 0;
                                            }
                                            return e.output - e.input < 0;

                                        }).sort((a, b) => (a.lotSerialNbr > b.lotSerialNbr ? 1 : -1)).map((entry) => {
                                            // row colour based on absolute value of difference on a scale to red to green
                                            var diff = Math.abs(entry.output - entry.input);
                                            var hVal = 120 - (diff * 5);
                                            if (hVal < 0) {
                                                hVal = 0;
                                            }
                                            var colour = hslToHex(hVal, 85, 70);


                                            return (<TableRow style={{ background: colour }}>
                                                <TableCell>{entry.stockItem?.description}</TableCell>
                                                <TableCell>{entry.supplier?.name}</TableCell>
                                                <TableCell>{entry.lotSerialNbr}</TableCell>
                                                <TableCell>{numberFormat(entry.input)}</TableCell>
                                                <TableCell>{numberFormat(entry.output)}</TableCell>
                                                <TableCell>{numberFormat(entry.output - entry.input)}</TableCell>
                                            </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>)
                    })
                ))}
            </Grid>
        </React.Fragment >
    );
};
export default HalfshellInOuts;


