import { adalApiFetch } from '../../adalConfig';

const uri = process.env.REACT_APP_BASE_URL;

export const getAPIData = async (path, stateSet, abortController) => {
    var options = null
    if (abortController) {
        options = { signal: abortController.signal };
    }
    const response = await adalApiFetch(fetch, uri + path, options);
    const data = await response.json();
    stateSet(data);
}

export const getAPIDataParams = async (path, stateSet, params, abortController) => {
    var options = null
    if (abortController) {
        options = { signal: abortController.signal };
    }
    const response = await adalApiFetch(fetch, uri + path + '?' + new URLSearchParams(params), options);
    try {
        const data = await response.json();
        stateSet(data);
    } catch (e) {
        stateSet(null);
    }
}
export const getAPIDataParamsReturn = async (path, params, abortController) => {
    var options = null
    if (abortController) {
        options = { signal: abortController.signal };
    }
    const response = await adalApiFetch(fetch, uri + path + '?' + new URLSearchParams(params), options );
    const data = await response.json();
    return (data);
}
export const getAPIDataReturn = async (path, abortController) => {
    var options = null
    if (abortController) {
        options = { signal: abortController.signal };
    }
    const response = await adalApiFetch(fetch, uri + path, options);
    const data = await response.json();
    return (data);
}

export const handlePagination = async (fetch, url, startDate, endDate, extraParams, abortController) => {
    var options = null
    if (abortController) {
        options = { signal: abortController.signal };
    }
    const response = await adalApiFetch(fetch, url + '?' + new URLSearchParams({
        ...extraParams,
        startDate,
        endDate
    }), options);
    var retData = [];
    var data = await response.json();
    if (data.cursor >= data.length) {
        retData = data.list;
    } else {
        retData = [...data.list, ...(await handlePagination(fetch, url, startDate, endDate, { start: (data.cursor ) }, abortController))];
    }
    return retData;
}

export const postApi = async (path, params) => {
    return await adalApiFetch(fetch, uri + path + '?' + new URLSearchParams(params), {
        method: 'post',
    });
}