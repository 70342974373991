import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import Inventory from './Tables/Inventory';
import FormModal from '../../components/FormModal';
import Button from "@mui/material/Button";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import InventoryForm from './Forms/InventoryForm';
import { adalApiFetch } from '../../../adalConfig';
import { dateFormat, textfieldDateFormat, yesterday, dayCompare } from "../../utils/dateFunctions";
import SelectInventoryForm from './Forms/SelectInventoryForm';
import SelectWarehouse from '../../components/SelectWarehouse';
import RegradeStockForm from './Forms/RegradeStockForm';
import TransferStockForm from './Forms/TransferStockForm';
import AdjustQuantityStockForm from './Forms/AdjustQuantityStockForm';
import CreateStockForm from './Forms/CreateStockForm';
import { CircularProgress, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

const InventoryDash = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [newForm, setNew] = React.useState(false);
    const [rowData, setRowData] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [processed, setProcessed] = React.useState(false);
    const [selectedLine, setSelectedLine] = React.useState(false);
    const [warehouse, setWarehouse] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const [formType, setFormType] = React.useState(0);
    const requiredEditAccess = { key: ACCESS_SECTIONS.INVENTORY, value: ACCESS.EDIT };
    const reloadFunc = () => {
        setReload(!reload);
    }
    

    const openCreateFunc = () => {
        setOpenCreate(true);
    }
    return (
        <React.Fragment>
            
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <SelectWarehouse access={props.access} enforceAccess={true} useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} all />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {warehouse ? <SelectInventoryForm reportTable reload={reload} onClick={()=>{}} warehouseId={warehouse?.id} access={props.access} hideFrozenButton /> : <CircularProgress />}
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default InventoryDash;
