import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DataTableStandard from "../../../components/DataTableStandard";
import { Button, IconButton, FormControlLabel, Switch } from "@mui/material";
import { dateTimeFormat, textfieldDateFormat } from '../../../utils/dateFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import Typography from '@mui/material/Typography';
import { getAPIData } from '../../../utils/apiFunction';
import FormModal from '../../../components/FormModal';
import { useJumboTheme } from "@jumbo/hooks";
import ConfirmReceivalForm from '../Forms/ConfirmReceivalForm';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { dateSortLong } from '../../../utils/sortFunctions';
import NewReceival from '../../Tasks/NewReceival';

const MYOSTATSUCCESS = 1;
const MYOSTATERROR = 2;
const MYOSTATLOADING = 0;
const MYOSTATLoadingText = 'In Progress';
const MYOSTATCompleteText = 'Complete';
const MYOSTATErrorText = 'Error';
const Receivals = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [tableData, setTableData] = React.useState([]);
    const [holdData, setHoldData] = React.useState([]);
    const [rowData, setRowData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openRec, setOpenRec] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [suppliers, setSuppliers] = React.useState({});
    const [columns, setColumns] = React.useState([]);
    const [edit, setEdit] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseRec = () => setOpenRec(false);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const cfForm = (rowD, rowState) => {
        setRowData(tableData[rowState.dataIndex])
        console.log(tableData, rowState)
        setOpen(true)
    }
    var options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,

        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(tableData) : cfForm,

        ...props.extraOptions
    };
    if (props.forMyob) {
        options.setRowProps = row => {
            if ((row[6] !== '') && (row[6] !== null) && (row[6] !== undefined)) {
                return {
                    style: { background: 'rgba(221, 83, 73,0.5)' }
                };
            } else {
                return {

                };
            }
        }
    }
    if (props.completed) {
        options.setRowProps = row => {
            if (row[6] === MYOSTATCompleteText) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (row[6] === MYOSTATLoadingText) {
                return {
                    style: { background: theme.palette.oysterProducts.pending }
                };
            } else if (row[6] === MYOSTATErrorText) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            } 
        }
    }
    React.useEffect(() => {
        var cols = [{
            name: 'id',
            label: 'ID'
        },
        {
            name: 'prn',
            label: 'PRN'
        },
        {
            name: 'otr',
            label: 'OTR'
        },
        {
            name: 'supplier',
            label: 'Supplier',
            options: props.supplierFilter ? { filterList: [props.supplierFilter] } : {}
        },
        {
            name: 'pickupDate',
            label: props.pickedup ? 'Harvest Date' : 'Date',
            options: {
                sortCompare: dateSortLong,
                sortDescFirst: true
            }
        },
        {
            name: 'warehouse',
            label: 'Warehouse',
            options: {
                filterList: props.warehouseFilter ? [props.warehouseFilter] : [],
                filterOptions: {
                    names: ["LIVE PRODUCT", "SYDNEY"],
                    logic(wHouse, filters) {
                        if (!(wHouse === "LIVE PRODUCT") && !(wHouse === "SYDNEY")) {
                            return false;
                        } else {
                            if (filters[0] === wHouse) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    }
                }
            }
        }];
        if (props.forMyob) {
            cols.push({
                name: 'warning',
                label: 'Warnings',
            })
        }
        if (props.completed) {
            cols.push({
                name: 'status',
                label: 'Status',
            })
        }
        setColumns(cols);
    }, [props.warehouseFilter, props.supplierFilter]);
    React.useEffect(() => {
        getAPIData('/farmerssuppliers', setSuppliers);

    }, []);
    React.useEffect(() => {
        var newData = holdData.map((dt) => {
            var cpyDT = { ...dt };
            var supp = null;
            if (suppliers.farmers) {
                supp = suppliers.farmers.find((sp) => { return sp.id === cpyDT.supplierId });
            }
            cpyDT.pickupDate = props.pickedup ? dateTimeFormat(new Date(cpyDT.harvestDate)) : dateTimeFormat(new Date(cpyDT.pickupDate));
            cpyDT.supplier = supp ? supp.name : cpyDT.supplierPredict;
            cpyDT.warehouse = cpyDT.warehouse?.description;
            cpyDT.warning = cpyDT.alerts.reduce((p, c) => { return p + '\r\n' + c }, '');
            cpyDT.status = cpyDT.myobStatus !== undefined ? (cpyDT.myobStatus === MYOSTATSUCCESS ? MYOSTATCompleteText : (cpyDT.myobStatus === MYOSTATLOADING ? MYOSTATLoadingText : MYOSTATErrorText)) : 'None';
            return cpyDT;
        })
        setTableData(newData);

    }, [holdData, suppliers]);
    React.useEffect(() => {
        setTableData([]);
        getData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
    }, [props.startDate, props.endDate, props.reload]);
    const openNewReceival = () => {
        setOpenRec(true);
    }
    var getData = async (startDate, endDate) => {
        setLoading(true);
        var urlPath = '';
        if (props.notConfirmed) {
            urlPath = '/ToReceive';
        } else if (props.notQA) {
            urlPath = '/NeedsQA';
        } else if (props.forMyob) {
            urlPath = '/ForMyob';
        } else if (props.noOTR) {
            urlPath = '/noOTR';
        } else if (props.completed) {
            urlPath = '/Complete';
        } else if (props.pickedup) {
            urlPath = '/NeedsPO';
        } else if (props.adminEdit) {
            urlPath = '/AllOpen';
        }
        const response = await adalApiFetch(fetch, uri + '/Receivals/Get' + urlPath + '?' + new URLSearchParams({
            startDate,
            endDate
        }));
        if (response.ok) {
            const data = await response.json();
            var sortData = data.sort((a, b) => {
                return new Date(a.pickUpDate) > new Date(b.pickupDate) ? 1 : -1
            })
            if (props.reportLength) {
                props.reportLength(sortData.length);
            }
            setLoading(false);
            setHoldData(props.filter ? props.filter(sortData) : sortData);
        } else {
            setHoldData([]);
            if (props.reportLength) {
                props.reportLength(0);
            }
        }
    }
    var tableTitle = 'Receivals';
    if (props.title) {
        tableTitle = props.title;
    } else {
        if (props.forMyob) {
            tableTitle = "Ready for MYOB";
        } else if (props.notQA) {
            tableTitle = "QA Required";
        } else if (props.notConfirmed) {
            tableTitle = "Confirmation of Receival Required";
        } else if (props.noOTR) {
            tableTitle = "OTR Required";
        } else if (props.completed) {
            tableTitle = "Completed and Submitted to MYOB";
        } else if (props.pickedup) {
            tableTitle = 'Picked Up and need to be matched to PO';
        }
    }
    return (

        <React.Fragment >

            <FormModal open={open} handleClose={handleClose} title={"Confirm Recieval Form"} >
                <ConfirmReceivalForm data={rowData} access={props.access} handleClose={handleClose} completed={!edit} edit={edit} reload={props.reload} />
            </FormModal>
            <FormModal open={openRec} handleClose={handleCloseRec} title={"New Recieval Form"} >
                <NewReceival access={props.access} handleClose={handleCloseRec} />
            </FormModal>
            <DataTableStandard
                title={<div><Typography variant={"h3"} style={{ display: 'inline' }}>{tableTitle}</Typography>{checkAccess(props.access, requiredEditAccess) && !props.notConfirmed && !props.notQA && !props.forMyob && !props.noOTR && !props.completed && !props.adminEdit && !props.pickedup ? <Button onClick={openNewReceival} variant="contained" style={{ marginLeft: '20px' }}>New Receival</Button> : (checkAccess(props.access, requiredAdminAccess) && props.editButton ? <FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={edit} onChange={(event) => { setEdit(event.target.checked) }} />} label={edit ? 'Edit Enabled' : 'Edit Disabled'} /> : '')}</div>}
                data={props.filter ? props.filter(tableData) : tableData}
                columns={columns}
                options={options}
                returnSearch={!loading}
                reload={props.reloadFunc}
            />
        </React.Fragment>
    );
};

export default Receivals;
