import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { startOfWeek, endOfWeek, textfieldDateFormat } from "../../../utils/dateFunctions";
import { getAPIDataReturn, getAPIDataParamsReturn, postApi, getAPIData, getAPIDataParams } from "../../../utils/apiFunction";
import { numberFormatwithNeg } from "../../../utils/numberFunctions";
import OystersGroup from "../../../components/OysterGroup";
import Div from "@jumbo/shared/Div";
import { adalApiFetch } from '../../../../adalConfig';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import DropZone from "../../../components/DropZone";
import SelectTextField from "../../../components/SelectTextField";
import ControlledTextField from "../../../components/ControlledTextField";
import DriverSelect from "../../../components/DriverEntry";
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import StaffMember from "../../../components/StaffMember";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { number } from 'prop-types';
import FormModal from '../../../components/FormModal';
import DeleteCurrentRun from '../../../components/DeleteCurrentRun';


const ReceivalForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const hasStarted = !(props.selectedLine?.processingStatus === 'Not started');
    const [completed, setCompleted] = React.useState(props.completed);
    const [updateComplete, setUpdateCompleted] = React.useState(false);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [editInput, setEditInput] = React.useState(false);
    const [oystersOut, setOystersOut] = React.useState([{}]);
    const [currentOutputs, setCurrentOutputs] = React.useState(null);
    const [staffMember, setStaffMember] = React.useState(null);
    const [stockItems, setStockItems] = React.useState(null);
    const [scheduleList, setScheduleList] = React.useState(props.scheduleList);
    const [showDelete, setShowDelete] = React.useState(false);
    const [editBench, setEditBench] = React.useState(null);
    var recData = props.data ? props.data : {};
    const Swal = useSwalWrapper();
    const [equipment, setEquipment] = React.useState(null);
    const [equipmentList, setEquipmentList] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const requiredMYOBAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MYOB };
    const CompletedAndEdit = edit && completed;
    const CompletedSoDisabled = !edit && completed;
    const [oystersIn, setOystersIn] = React.useState([{
        bags: props.selectedLine.bags, bins: props.selectedLine.bins, stockItemId: props.selectedLine.stockItemId, quantity: (CompletedAndEdit ? props.selectedLine.quantity : props.selectedLine.amount),
    }]);
    const supplier = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.receival?.supplier?.name : props.selectedLine.supplier) : '';
    const lotNbr = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.lotSerialNbr : props.selectedLine.lotSerialNbr) : '';
    const [comment, setComment] = React.useState(CompletedAndEdit ? props.selectedLine?.comment : (hasStarted ? props.selectedLine?.processRun?.comment : null));
    const [workers, setWorkers] = React.useState(CompletedAndEdit ? (props.selectedLine?.staff) : (hasStarted ? props.selectedLine?.processRun?.staff : null));
    const processRunId = CompletedAndEdit ? props.selectedLine.id : props.selectedLine.processRunId
  
            console.log(props.warehouseId)
    React.useEffect(() => {
        getAPIDataReturn('/Processing/GetProductionLines').then((data) => {
            setEquipmentList(data.filter((d) => d.warehouseId === props.warehouseId));
        });
    }, []);
    React.useEffect(() => {
        if (hasStarted) {
            getAPIData('/StockItems/Get', setStockItems);
        }
    }, []);

    React.useEffect(() => {
        // get production line
        //equipmentList

        ///get existing outputs
        if (props.selectedLine && processRunId) {
            getAPIDataParamsReturn('/Processing/GetProcessingOutputs', { ProcessRunId: processRunId }).then((data) => {
                var oys = data.map((d) => {
                    return { stockItemId: d.stockItemId, quantity: d.quantity, bins: d.bins, bags: d.noOfUnits, id: d.id }
                });
                setCurrentOutputs(oys);
                setOystersOut(oys.length > 0 ? oys : [{}]);
            }).catch((error) => {
                console.log(error);
                setOystersOut([{}]);
            });
        } else {
            setOystersOut([{}]);
        }
    }, [])

    const getVariance = (oysIn, oysOut, perc) => {
        var totalOystersOut = 0;
        for (var i = 0; i < oysOut.length; i++) {
            totalOystersOut += oysOut[i].quantity ? Number(oysOut[i].quantity) : 0;
        }
        var diff = Number(oysIn[0]?.quantity) - Number(totalOystersOut);
        if (perc) {
            return Math.abs((diff) / Number(oysIn[0]?.quantity));
        } else {
            return diff;
        }
    }


    const validate = (finished) => () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)
        if (!equipment.allowMultiple) {
            var matchSchedule = scheduleList?.find((s) => s.processRun?.processLineId === equipment.id);
            if (matchSchedule) {
                if (matchSchedule?.id !== props.selectedLine?.id) {
                    errorMessages.push("There is already a process running on this line");
                }
            }
        }
        if (props.selectedLine?.totalAvailable < oystersIn[0].quantity) {
            errorMessages.push("You have entered more oysters than are available in the receival");
        }
        if (!equipment) {
            errorMessages.push("You must select the line you are using");
        }
        if (!workers || (workers < 0)) {
            errorMessages.push("You must enter the number of workers for this run");

        }
        //cant have duplicate stock items
        var stockItemsids = [];
        for (var i = 0; i < oystersOut.length; i++) {
            if (stockItemsids.includes(oystersOut[i].stockItem?.id)) {
                errorMessages.push("You cannot have duplicate stock items for Output");
            }
            stockItemsids.push(oystersOut[i].stockItem?.id);
        }

        if (!staffMember) {
            errorMessages.push("You must select a Staff Member");

        }
        for (var i = 0; i < oystersIn.length; i++) {
            if (!oystersIn[i].quantity || (oystersIn[i].quantity <= 0)) {
                errorMessages.push("You must enter a quantity of oysters for input");
            }
            if (!oystersIn[i].stockItem) {
                errorMessages.push("You must select a stockitem for input");
            }
        }
        if (hasStarted) {
            for (var i = 0; i < oystersOut.length; i++) {
                if (!oystersOut[i].quantity || (oystersOut[i].quantity <= 0)) {
                    errorMessages.push("You must enter a quantity of oysters for output");
                }
                if (!oystersOut[i].stockItem) {
                    errorMessages.push("You must select a stockitem for output");
                }
            }
        }
        if (finished) {
            var gradeNTotal = [];
            for (var j = 0; j < oystersOut.length; j++) {
                var gradeOrder = (stockItems.find((s) => (s.id === (oystersOut[j].stockItem?.id))))?.grade?.sortOrder;
                var index = gradeNTotal.findIndex((g) => g.grade === gradeOrder);
                if (index >= 0) {
                    gradeNTotal[index].total += oystersOut[j].quantity;
                } else {
                    gradeNTotal.push({ grade: gradeOrder, total: oystersOut[j].quantity });
                }
            }
            var showApprovalButton = errorMessages.length === 0; //if no other errors and then there are errors below it will show approval button
            for (var i = 0; i < oystersIn.length; i++) {
                for (var j = 0; j < gradeNTotal.length; j++) {
                    var oyInGrade = stockItems.find((s) => s.id === oystersIn[i].stockItem?.id)?.grade?.sortOrder;
                    var oyOutGrade = gradeNTotal[j].grade;
                    var variance = 1 - (Math.abs(oystersIn[i].quantity - gradeNTotal[j].total) / oystersIn[i].quantity);
                    var direction = 'downgrading';
                    if (oyInGrade < oyOutGrade) {
                        direction = 'upgrading';
                    }
                    console.log(oyInGrade - oyOutGrade)
                    //need to do internal vs external
                    var warnVar = 0.05;
                    var errorVar = 0.07;
                    var gradDistance = '';
                    var changeInGrade = false;
                    if ((Math.abs(oyInGrade - oyOutGrade) > 2)) {
                        changeInGrade = true;
                        gradDistance = 'three grades';

                        if (props.selectedLine?.supplierObject?.isInternal) {
                            warnVar = 0.07;
                            errorVar = 0.15;
                        } else {
                            warnVar = 0.05;
                            errorVar = 0.125;
                        }
                    } else if (Math.abs(oyInGrade - oyOutGrade) > 1) {
                        changeInGrade = true;
                        gradDistance = 'two grades';
                        if (props.selectedLine?.supplierObject?.isInternal) {
                            warnVar = 0.1;
                            errorVar = 0.2;
                        } else {
                            warnVar = 0.07;
                            errorVar = 0.15;
                        }
                    } else if (Math.abs(oyInGrade - oyOutGrade) > 0) {
                        changeInGrade = true;
                        if (props.selectedLine?.supplierObject?.isInternal) {
                            warnVar = 0.2;
                            errorVar = 0.3;
                        } else {
                            warnVar = 0.1;
                            errorVar = 0.2;
                        }
                    }
                    if (changeInGrade) {
                        if (variance > errorVar) {
                            errorMessages.push("You are " + direction + " " + gradDistance + " more than " + ((errorVar * 100).toFixed(0)) + "%! Seek Approval!");
                        } else if (variance > warnVar) {
                            warningMessages.push("You are " + direction + " " + gradDistance + " more than " + ((warnVar * 100).toFixed(0)) + "%! Are you sure this is correct?");
                        }
                    }
                }
            }
            var totalOystersOut = 0;
            for (var i = 0; i < oystersOut.length; i++) {
                totalOystersOut += oystersOut[i].quantity;
            }
            if (getVariance(oystersIn, oystersOut, true) > 0.1) {
                warningMessages.push("Variance is greater than 10%! Are you sure this is correct?");
            }
            if (getVariance(oystersIn, oystersOut, true) >= 0.15) {
                errorMessages.push("Variance is greater than 15%! This cannot be completed. Please see a manager.");
            }
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            if (showApprovalButton) {
                Swal.fire({
                    html: formatErrorMessage(errorMessages),
                    icon: 'error',
                    title: 'Oops...',
                    showCancelButton: true,
                    confirmButtonText: 'Approve this Variance',
                    cancelButtonText: 'Edit Values',
                }).then(result => {
                    if (result.value) {
                        if (checkAccess(props.access, requiredAdminAccess)) {
                            submit(finished);
                        } else {
                            let pin = prompt("Please enter your admin PIN");
                            if (pin.toLowerCase() === 'freeoysters') {
                                submit(finished);
                            }
                        }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {

                    }
                });
            } else {
                Swal.fire({
                    html: formatErrorMessage(errorMessages),
                    icon: 'error',
                    title: 'Oops...',

                });
            }
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit(finished);
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit(finished);
        }
    }
    const submit = async (finished) => {
        var allOk = false;
        var respArr = [];
        setLoading(true);
        if (hasStarted) {
            //update input
            if (editInput) {
                const params = {
                    ProcessRunId: processRunId,
                    ProductionLineId: equipment.id,
                    Amount: oystersIn[0].quantity,
                    Bins: oystersIn[0].bins,
                    Bags: oystersIn[0].bags,
                    Staff: workers,
                    StaffMemberId: staffMember.id
                }
                respArr.push(await postApi('/Processing/UpdateProcess', params));
            }
            var prmArr = [];
            for (var i = 0; i < currentOutputs.length; i++) {
                if (!oystersOut.find((x) => x.stockItem.id === currentOutputs[i].stockItemId)) {
                    params = {
                        ProcessRunId: processRunId,
                        StockItemId: currentOutputs[i].stockItemId,
                        Amount: 0,
                        NoOfUnits: 0,
                    }
                    prmArr.push(await postApi('/Processing/AddUpdateProcessingOutput', params));
                }
            }
            await Promise.all(prmArr);
            respArr = [...respArr, ...prmArr];
            //update output
            //update add output
            prmArr = [];
            var params = {};
            for (var i = 0; i < oystersOut.length; i++) {
                params = {
                    ProcessRunId: processRunId,
                    StockItemId: oystersOut[i].stockItem.id,
                    NoOfUnits: oystersOut[i].bags ? oystersOut[i].bags : 0,
                    Amount: oystersOut[i].quantity,
                }
                prmArr.push(await postApi('/Processing/AddUpdateProcessingOutput', params));
            }
            await Promise.all(prmArr);
            respArr = [...respArr, ...prmArr];
            
            // is it finished?
            if (finished) {
                const paramsFin = {
                    ProcessRunId: processRunId
                }
                respArr.push(await postApi('/Processing/EndProcessing', paramsFin));
            }
            if (comment) {
                const commentprm = {
                    ProcessRunId: processRunId,
                    Comment: comment,
                }
                respArr.push(await postApi('/Processing/AddComment', commentprm));
            }
        } else {

            const params = {
                ExpiryDate: textfieldDateFormat(new Date(props.selectedLine.expiryDate)),
                LotSerialNbr: props.selectedLine.lotSerialNbr,
                ProductionLineId: equipment.id,
                StockItemId: oystersIn[0].stockItem.id,
                Amount: oystersIn[0].quantity,
                Bins: oystersIn[0].bins?oystersIn[0].bins:0,
                Bags: oystersIn[0].bags?oystersIn[0].bags:0,
                Staff: workers,
                StaffMemberId: staffMember.id,
               
            }
            respArr.push(await postApi('/Processing/StartProcessing', params));
        }
        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            setLoading(false);
            setCompleted(true);
            setUpdateCompleted(true);
        } else {
            setError("Failed to submit");
        }


    }
    const enableInputEdit = () => {
        setEditInput(true);
    }
    const remSR = (name) => {
        if (name && name.description) {
            return name.description.replace('Sydney Rock', '');
        } else {
            return '';
        }
    }
    const deleteCurrentRun = () => {
        setShowDelete(true);
        setEditBench(props.selectedLine?.processRun);

    }
    const handleCloseDelete = () => {
        setShowDelete(false);
        props.handleClose();
    }

    const staffMemId = CompletedAndEdit ? props.selectedLine?.staffMemberId : (hasStarted ? props.selectedLine?.processRun?.staffMemberId : null);
    const procLineId = CompletedAndEdit ? props.selectedLine?.processLineId : (hasStarted ? props.selectedLine?.processRun?.processLineId : null);
    console.log(props.onlyLive);
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            {checkAccess(props.access, requiredMYOBAccess)  ? <FormModal open={showDelete} handleClose={handleCloseDelete} title={"Delete Current Process"} noWidth >
                <DeleteCurrentRun outputs={{ ...editBench, processRunOutputs: currentOutputs }} access={props.access} handleClose={handleCloseDelete} selectedBench={props.selectedLine?.processRun} warehouseId={props.warehouse?.id} warehouse={props.warehouse} />
            </FormModal > : null}
            {true ? null : <IconButton style={{ paddingBottom: '15px', position: 'absolute', top: '5%', right: '1%' }} variant={'h3'} onClick={() => { deleteCurrentRun() }}>< DeleteIcon /></IconButton>}
            <Div>

                <TextField
                    disabled
                    id="outlined-required"
                    label="Supplier"
                    value={supplier}
                />
                <TextField
                    disabled
                    id="outlined-required"
                    label="Lot Nbr"
                    value={lotNbr}
                />
            </Div>
            <Div>
                {/*//comments*/}
                {/*//processing time*/}
                {/*//no of staff on line*/}

                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StaffMember staffId={staffMemId} driver={staffMember} setDriver={setStaffMember} disabled={(hasStarted && !editInput) || CompletedSoDisabled} noTitle />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <SelectTextField key={'si' + props.index}  checkID={procLineId} title="Select Line" state={equipment} nameFunc={(n) => (n.description)} list={props.onlyLive?equipmentList.filter((el)=>(!el.isHalfshell)):equipmentList} setState={setEquipment} disabled={(hasStarted && !editInput) || CompletedSoDisabled} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <ControlledTextField key={'bg' + props.index} title="Number of Workers" state={workers} setState={setWorkers} disabled={(hasStarted && !editInput) || CompletedSoDisabled} number />
                    </Grid>
                    {hasStarted ? <Grid item xs={12} >
                        <TextField
                            disabled={CompletedSoDisabled}
                            id="outlined-multiline-static"
                            label="Comment"
                            value={comment}
                            onChange={(event) => { setComment(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rows={4} />
                    </Grid> : ''}
                    <Grid item xs={12} >
                        {/*Icon button edit */}


                        <Typography variant={'h3'}>{"Input Stock"}{hasStarted ? <IconButton aria-label="cci" onClick={CompletedSoDisabled ? () => { } : enableInputEdit}>
                            <EditIcon />
                        </IconButton> : null}</Typography>
                        <OystersGroup allStock={props.WIPcode||props.allStock} processed={props.processed} disableStockItem oysters={oystersIn} starter={false} setOysters={setOystersIn} disabled={(hasStarted && !editInput) || CompletedSoDisabled} single noTitle /> 
                    </Grid>

                    {hasStarted ? <Grid item xs={12} >
                        <Typography variant={'h3'}>{"Output Stock"}</Typography>
                        <OystersGroup showAsUnits oysters={oystersOut} setOysters={setOystersOut} disabled={CompletedSoDisabled} noTitle WIPcode={props.WIPcode} processed processRunId={processRunId} nameFunc={remSR} /> 
                    </Grid> : null}

                    {CompletedAndEdit ?
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/*<FormGroup>*/}
                            {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                            {/*</FormGroup>*/}
                            <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer title={'Update'} completed={updateComplete} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                            </div>
                        </Grid>
                        : (hasStarted ? <React.Fragment>
                            <Grid item xs={12} >
                                <Typography variant={'h3'}> {"Current Variance: " + numberFormatwithNeg((getVariance(oystersIn, oystersOut, true) * 100)?.toFixed(0)) + '% (' + numberFormatwithNeg(getVariance(oystersIn, oystersOut, false)) + ' doz)'}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/*<FormGroup>*/}
                                {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                                {/*</FormGroup>*/}
                                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer title={'Add Output'} completed={CompletedSoDisabled} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                                </div>
                            </Grid>
                            {stockItems ? <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/*<FormGroup>*/}
                                {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                                {/*</FormGroup>*/}
                                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer title={'Add Output and Complete'} completed={CompletedSoDisabled} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(true)} handleClose={props.handleClose} />
                                </div>
                            </Grid> : null}
                        </React.Fragment> :
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {/*<FormGroup>*/}
                                {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                                {/*</FormGroup>*/}
                                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer title={'Start Processing'} completed={CompletedSoDisabled} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                                </div>
                            </Grid>)}
                </Grid>
            </Div>
        </Box >
    );
};


export default ReceivalForm;


