export const ACCESS = {
    NONE: 'none',
    VIEW: 'view',
    EDIT: 'edit',
    MYOB: 'myob',
    ADMIN: 'admin',
    MASTER: 'master'
};


export const ACCESS_SECTIONS = {
    PROCUREMENT: 'procurement',
    PROCESSING: 'processing',
    FULFILMENT: 'fulfilment',
    SALES: 'sales',
    MYOB_PROCUREMENT: 'myob_procurement',
    FARMS: 'Farms',
    BERMAGUI: 'Bermagui',
    HASTINGS: 'Hastings',
    MERIMBULA: 'Merimbula',
    NAROOMA: 'Narooma',
    SHOALHAVEN: 'Shoalhaven',
    WALLIS: 'Wallis',
    WAPENGO: 'Wapengo',
    HATCHERY: 'Hatchery',
    OYSTERMATE: 'OysterMate',
    NEWFEATURE: 'NewFeature',
    SPECIALACCESS: 'SpecialAccess',
    SYDNEY: 'SYDNEY',
    LIVEPRODUCT: 'LIVE PRODUCT',
    UNDO: 'Undo',
    INVENTORY: 'Inventory',
};