import jsPDF from 'jspdf';
import 'jspdf-autotable'
import logo from '../../images/AOCLogo.png';
import { numberFormat } from '../../utils/numberFunctions'
import { dateFormat } from '../../utils/dateFunctions'
import { adalApiFetch } from '../../../adalConfig';
import { getAPIDataReturn } from '../../utils/apiFunction';

const OTRPdf = async (data, printBin, printBag) => {
    const ifUndef = (field) => {
        return field ? field : '';
    }

    console.log(data)
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [100, 150]
    });

    var smallerText = 10;
    var fontmedium = 12;
    var fontbig = 15;
    var fontbigger = 18;
    var fontbiggerText = 25;
    var fontbiggest = 35;
    var fontText = 7;
    var grades = await getAPIDataReturn('/Farms/Lookups/Grades');

    var titleI = new Image();
    titleI.src = logo;
    var noLabels = 0;
    if (data.coreTransferLines) {
        for (var i = 0; i < data.coreTransferLines.length; i++) {
            if (data.coreTransferLines[i].group) {
                var loops = (printBin ? data.coreTransferLines[i].bins : 0) + (printBag ? data.coreTransferLines[i].bags : 0);
                for (var j = 0; j < loops; j++) {
                    if (noLabels !== 0) {
                        doc.addPage();
                    }
                    noLabels++;
                    var isSeed = false;
                    if ((data.coreTransferLines[i].stockItem.myobid === 'GROWOUT') || (data.coreTransferLines[i].stockItem.myobid === 'SEEDSPAT')) {
                        isSeed = true;
                    }


                    var lineWidthTight = 5;
                    var lineWidth = 6
                    var max = 18;
                    var titleBarX = 10;
                    var line = 0;
                    var tempLine = line;
                    doc.addImage(titleI, 'PNG', 25, line, 50, 42);

                    line = 60;
                    doc.setFontSize(fontbig);
                    var compCode = data.prn ? data.prn : data.ivr;
                    var compCodeTag = data.prn ? 'PRN:' : 'IVR:';
                    doc.text(compCodeTag, 10, line);
                    //doc.setTextColor(0, 0, 0);
                    doc.setFontSize(fontbiggest);
                    doc.text(compCode, 25, line);
                    doc.setFontSize(smallerText);

                    line = line + 9;
                    doc.text('30 Cranbrook Rd, Batemans Bay NSW', 20, line);
                    line = line + 6;
                    doc.text('Lic: 51487', 41, line);
                    line = line + 9;
                    doc.setFontSize(fontmedium);
                    doc.text('To: ', 10, line);
                    doc.setFontSize(fontbigger);
                    var destinationName = data.destination?.myobid;
                    if (destinationName === 'LIVEPRODCT') {
                        destinationName = 'AOC BATEMANS BAY';
                    } else if (destinationName === 'SYDNEY') {
                        destinationName = 'AOC KINGSGROVE';
                    }

                    doc.text(destinationName, 20, line);
                    doc.setDrawColor(0, 0, 0);
                    doc.rect(70, line + 1, 25, 18);
                    doc.setFontSize(fontbiggerText);

                    line = line + 8;
                    doc.setFontSize(fontmedium);
                    doc.text('Qty (' + (isSeed ? 'pcs' : 'doz') + '):', 75, line);
                    doc.setFontSize(fontbigger);
                    doc.text(String((data.coreTransferLines[i].group?.average / (isSeed ? 1 : 12)).toFixed(0)), 73, line + 8);
                    doc.setFontSize(fontbigger);
                    doc.text(data.coreTransferLines[i].stockItem?.species ? data.coreTransferLines[i].stockItem?.species?.description : 'Sydney Rock', 10, line);
                    doc.setFontSize(fontbiggerText + 2);

                    line = line + 10;
                    var grade = data.coreTransferLines[i].stockItem?.description.replace('Sydney Rock Single Seed', '').replace('Sydney Rock', '');
                    if ((grade === 'Oysters Grow-Out') || (grade === 'Seed Spat')) {
                        doc.setFontSize(fontmedium);
                        grade = grades.find((g) => { return g.id === data.coreTransferLines?.[i]?.group.gradeId })?.description;
                    }
                    doc.text(grade, 10, line);
                    doc.setFontSize(fontbigger);
                    line = line + 8;
                    doc.text('Harvest Date: ' + dateFormat(data.harvestDate), 10, line);
                    line = line + 8;
                    doc.setFontSize(fontbigger - 3);
                    doc.text(data.source?.myobid, 10, line);
                    doc.setFontSize(smallerText);
                    doc.text(ifUndef(data.coreTransferLines?.[i]?.group?.previousLocation?.line?.lease?.harvestArea?.description), 65, line);
                    line = line + 7;
                    doc.text(ifUndef(data.coreTransferLines?.[i]?.group?.previousLocation?.line?.lease?.number), 65, line);
                    line = line + 7;
                    //doc.text('Export Approved', 65, line);
                    doc.setFontSize(fontbigger);
                    line = line + 10;
                    doc.text('Store Between 15 - 21C', 15, line);
                    //10 lines

                }
            }
        }
    }

    if (noLabels > 0) {
        doc.save('Labels For ' + data.otr + '.pdf');
    }
}



function buildDesc(oy) {
    console.log(oy)
    if (oy.stockItem) {
        if (oy.stockItem.species && oy.stockItem.grade) {
            return (oy.stockItem.species.description + ' ' + oy.stockItem.grade.description)
        } else {
            return oy.stockItem.description;
        }
    } else {
        return 'Unknown';
    }

}

export default OTRPdf;