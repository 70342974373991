import React from 'react';
import ListSubheader from "@mui/material/ListSubheader";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import { useJumboLayoutSidebar } from "@jumbo/hooks";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import { useTranslation } from "react-i18next";
import { userInfo } from '../../../adalConfig';
import { getUsersAccess, checkAccess } from '../../../app/utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../../app/utils/constants/access';

const JumboNavSection = ({ item, translate }) => {
    const { sidebarOptions } = useJumboLayoutSidebar();
    const { t } = useTranslation();
    const [access, setAccess] = React.useState(getUsersAccess(userInfo()));
    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);

    const label = React.useMemo(() => {
        return translate ? t(item.label) : item.label;
    }, [item, translate, t]);

    if (!item || !item.label) return null;

    const subMenus = (item && item.children && item.children.length > 0) ? item.children : null;
    var colorObj = {};
    if (item.access) {
        if (item.access.value === ACCESS.MASTER) {
            colorObj = { color: 'red' };

        } else if (item.access.key === ACCESS_SECTIONS.NEWFEATURE) {
            colorObj = { color: 'blue' };
        }
    }
    return (
        <>
            {
                !isMiniAndClosed &&
                <ListSubheader
                    component="li"
                    disableSticky
                    sx={{
                        ...colorObj,
                        fontSize: '80%',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        textTransform: 'uppercase',
                        bgcolor: 'transparent',
                        p: theme => theme.spacing(3.75, 3.75, 1.875)
                    }}
                >
                    {label}
                </ListSubheader>
            }
            {
                subMenus &&
                subMenus.map((child, index) => {
                    if (child.access && (!(checkAccess(access, child.access)))) {
                        return '';
                    } else {
                        if (!child.onClick) {
                            child = { ...child, onClick: item.onClick }
                        }
                        return (<JumboNavIdentifier item={child} key={index} />)
                    }
                })
            }
        </>
    )
};

//todo: put an equal deep check for the props
export default React.memo(JumboNavSection);
