import React, { useMemo } from 'react';
import { dateFormat, textfieldDateFormat, generateTextDays, generateDates, dayCompare } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { getAPIDataParamsReturn, postApi, getAPIData } from "../../../utils/apiFunction";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import FormModal from '../../../components/FormModal';
import ProcessStockForm from '../../Processing/Forms/ProcessStockForm';
import SelectInventoryForm from '../../Processing/Forms/SelectInventoryForm';
import { Table, TableBody, TableRow, TableCell, Avatar, TableHead } from "@mui/material";
import SelectTextField from "../../../components/SelectTextField";
import TableContainer from "@mui/material/TableContainer";
import { useJumboTheme } from "@jumbo/hooks";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { checkAccess } from '../../../utils/roleFunctions';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Button from "@mui/material/Button";
import { numberFormat } from '../../../utils/numberFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ViewQAButton } from "../../../components/TableItemViews";
import AddCommentIcon from '@mui/icons-material/AddComment';
import RefreshIcon from '@mui/icons-material/Refresh';
const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

const PurchaseOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [openInv, setOpenInv] = React.useState(false);
    const [commentOpen, setCommentOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [daySelection, setDaySelection] = React.useState([generateDates(props.startDate, props.endDate).findIndex((vl) => { return dayCompare(vl, new Date()) })]);
    const [procurement, setProcurement] = React.useState(null);
    const [unlock, setUnlock] = React.useState(false);
    const [savingOrder, setSavingOrder] = React.useState(false);
    const [originalOrderString, setOriginalOrderString] = React.useState(null);
    const [selectedLine, setSelectedLine] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    const [equipments, setEquipments] = React.useState([]);
    const [processed, setProcessed] = React.useState(false);
    var showOrderButton = false;
    const getOrderString = (dta) => {
        var os = '';
        if (dta !== null) {
            for (var i = 0; i < dta.length; i++) {
                if (i !== 0) {
                    os += ',';
                }
                os += dta[i]?.id;
            }
        }
        return os;

    }
    if (getOrderString(procurement) !== originalOrderString) {
        showOrderButton = true;
    } else {
        showOrderButton = false;
    }
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setProcurement(arrayMove(procurement, oldIndex, newIndex));
    };
    const updateProcurement = (index, key) => (value) => {
        var proc = [...procurement];
        proc[index][key] = value;
        setProcurement(proc);
    };
    React.useEffect(() => {
        getAPIData('/Processing/GetProductionLines', setEquipments);
    }, []);

    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        if (props.startDate && !isNaN(props.startDate.valueOf())) {
            getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, props.reload, daySelection, reload]);

    var getProcurementData = async (location, startDate, endDate, abortController) => {
        setLoading(true);
        if (location) {
            var data = await getAPIDataParamsReturn('/Processing/GetSchedule', { WarehouseId: location, Date: startDate }, abortController);
            //get receival
            setProcurement(data);
            setOriginalOrderString(getOrderString(data));
            setLoading(false);
        }
    }
    const unlockLock = (stat) => () => {
        setUnlock(stat);
    }
    const saveOrder = () => {
        //post api
        setLoading(true);
        setSavingOrder(true);
        postApi('/Processing/UpdateSchedule', { WarehouseId: props.location, Date: textfieldDateFormat(props.startDate), Ids: getOrderString(procurement) }).then(async (resp) => {
            if (resp.ok) {
                var data = await resp.json()
                setProcurement(data);
                setSavingOrder(false);
                setLoading(false);
                setOriginalOrderString(getOrderString(data));

            }
        }).catch((e) => { console.log(e); });

    }
    const processOysters = (data) => (e) => {
        if (!unlock) {
            setOpen(true);
            setSelectedLine(data);
        }
        console.log(data);
    }
    const addAdminComment = (data) => (e) => {
        setCommentOpen(true);
        setSelectedLine(data);
    }
    const handleClose = () => {
        setOpen(false);
        setReload(!reload);
        props.reloadPage();
        setProcessed(false);
    }
    const selectInv = (e) => {
        setOpenInv(true);
    }
    const handleCloseInv = () => {
        setOpenInv(false);
        setReload(!reload);
        props.reloadPage();
        setProcessed(false);
    }
    const handleCloseComment = () => {
        setCommentOpen(false);
        setReload(!reload);
    }
    const useInProcess = (data) => (row, index) => {
        //var selData = data[index.dataIndex];
        //setProcessed(selData.stockItem?.isProcessed);
        //setOpen(true);
        //selData = {
        //    ...selData,
        //    processingStatus: 'Not started',
        //    stockItem: selData.stockItem?.description,
        //    stockItemId: selData.stockItem?.id,
        //    amount: selData.portalQuantity,
        //    supplier: selData.supplier?.name,
        //};
        //setSelectedLine(selData);
    }
    var hideInv = true;
    const filteredProcurement = procurement?.filter((p) => {
        if(p.processRun){
            var line = equipments.find((e) => { return e.id === p.processRun?.processLineId });
            if(line){
                return !line.isHalfshell;
            }
        }
        return true;
    });
    return (
        <JumboCardQuick
            title={<div>Daily Runs {checkAccess(props.access, requiredEditAccess) ? <React.Fragment><IconButton onClick={unlockLock(!unlock)}>{unlock ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}</IconButton>{unlock && showOrderButton ? <Button onClick={saveOrder} variant="contained">{savingOrder ? <CircularProgress /> : 'Save Order'}</Button> : null}</React.Fragment> : null}<IconButton onClick={() => { setReload(!reload) }} ><RefreshIcon /></IconButton>{!hideInv ? <Button variant={'outlined'} onClick={selectInv} >Select From Inventory</Button> : null}</div>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >
            <DragTable {...props} data={filteredProcurement} onSortEnd={onSortEnd} useDragHandle={true} unlock={unlock} processOysters={processOysters} updateProcurement={updateProcurement} addAdminComment={addAdminComment} loading={loading} equipments={equipments} />
            <FormModal open={open} handleClose={handleClose} title={"Process Task"} fixedWidth>
                <ProcessStockForm onlyLive processed={processed} access={props.access} handleClose={handleClose} selectedLine={selectedLine} scheduleList={procurement} warehouseId={props.location} />
            </FormModal >
            <FormModal open={commentOpen} handleClose={handleCloseComment} title={"Note"} fixedWidth >
                <CommentBox access={props.access} handleClose={handleCloseComment} selectedLine={selectedLine} />
            </FormModal >
            <FormModal open={openInv} handleClose={handleCloseInv} title={"Process Task"} fixedWidth>
                <SelectInventoryForm onClick={useInProcess} access={props.access} handleClose={handleCloseInv} warehouseId={props.location} showType />
            </FormModal >
        </JumboCardQuick>

    );
};
const removeRL = (inp) => {
    if (inp.includes('RL')) {
        return inp.replace('RL', '');
    } else {
        return inp;
    }
}
const CommentBox = (props) => {
    const [comment, setComment] = React.useState(props.selectedLine.comment);
    const [loading, setLoading] = React.useState(false);
    const saveComment = () => {
        setLoading(true);
        postApi('/Processing/UpdateAdminComment', { Id: removeRL(props.selectedLine.id), Comment: comment }).then(async (resp) => {
            if (resp.ok) {
                var data = await resp.json()
                props.handleClose();
            }
        }).catch((e) => { console.log(e); });
    }
    return (
        <React.Fragment>
            <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                fullWidth
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
                variant="outlined"
            />
            <Button onClick={saveComment} variant="contained">{loading ? <CircularProgress /> : 'Save'}</Button>
        </React.Fragment>
    );
}
const getTotalsInfo = (data, type) => {
    return data?.reduce((p, c) => {
        if (c.stockItem.includes(type)) {
            if (c.status !== DoNotProcess) {
                p += c.amount;
            }
        }
        return p;
    }, 0);
}
const DragTable = SortableContainer((props) => {
    var POCellSortable = useMemo(() => { return checkAccess(props.access, requiredEditAccess) && props.unlock ? SortableElement(POCell) : POCell }, [props.unlock, props.access, props.data]);
    var data = props.data;
    console.log(data);
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>

                        </TableCell>
                        <TableCell>
                            Lot Nbr
                        </TableCell>
                        <TableCell>
                            Supplier
                        </TableCell>
                        <TableCell>
                            Note
                        </TableCell>
                        <TableCell>
                            Grade
                        </TableCell>
                        <TableCell>
                            Production Line
                        </TableCell>
                        <TableCell>
                            Quantity (Doz)
                        </TableCell>
                        <TableCell>
                            QA
                        </TableCell>
                        {/*<TableCell>*/}
                        {/*    Est. App*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*    Est. Premium*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*    Est. Loss*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*    Graded/Ungraded*/}
                        {/*</TableCell>*/}
                        <TableCell>
                            Process Type
                        </TableCell>
                        <TableCell>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!props.loading ? data?.map((dt, index) => (
                        <POCellSortable key={index} index={index} ind={index} data={dt} addAdminComment={props.addAdminComment} processOysters={props.processOysters} unlock={props.unlock} updateProcurement={props.updateProcurement} equipments={props.equipments} />
                    )) : <CircularProgress />}
                    <TableRow style={{ zIndex: 1000 }} >
                        <TableCell sx={{ pl: 3 }}>
                        </TableCell>
                        <TableCell sx={{ pl: 3 }} colSpan={10} >
                            <Grid container spacing={2} mb={1}>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div" style={{ fontWeight: 'bold' }} >
                                        Totals:
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Cocktail: {getTotalsInfo(data, 'Cocktail')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Bottle: {getTotalsInfo(data, 'Bottle')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Bistro: {getTotalsInfo(data, 'Bistro')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Plate: {getTotalsInfo(data, 'Plate')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
});
const DragHandle = SortableHandle((props) => { return <div style={{ cursor: 'grab', verticalAlign: 'middle' }}>{props.children}</div>; });
const DoNotProcess = 'Do Not Process';
const OnHold = 'On Hold';
const Process = 'Process';
const POCell = props => {

    const { data } = props;
    var status = data?.status;
    const [starred, setStarred] = React.useState(data.starred);
    const { theme } = useJumboTheme();
    var hasStarted = false;
    var processTypeList = ['Grade, Wash, Count', 'Wash, Count'];
    if (!(data?.bins && data?.bins > 0)) {
        processTypeList.push('Rebrand');
    }
    
    var getCol = theme.palette.oysterProducts.open;
    if (data.processingStatus === 'Started') {
        hasStarted = true;
        getCol = theme.palette.oysterProducts.shipping;

    } else if (!data?.canStart) {
        getCol = theme.palette.oysterProducts.onHold;

    }
    var clickable = data?.canStart || hasStarted;
    if (data.status === DoNotProcess) {
        getCol = theme.palette.oysterProducts.cancelled;
        clickable = false;
    } else if (data.status === OnHold) {
        getCol = theme.palette.oysterProducts.onHold;
        clickable = false;
    }
    //if ((data.status === Process)&& !data?.canStart) {
    //    status = 'Not Received';
    //}
    const remSS = (inp) => {
        if (inp.includes('Single Seed')) {
            return inp.replace('Single Seed', '');
        } else {
            return inp;
        }
    }
    return (
        //row colour based on status refer to customer order shet
        <TableRow style={{ zIndex: 1000, background: getCol }} onClick={clickable ? props.processOysters(data) : () => { }} >
            <TableCell sx={{ pl: 3 }}>
                <DragHandle >
                    <Typography variant={"h6"} mb={0}>{props.ind + 1}</Typography>
                </DragHandle>
            </TableCell>
            <TableCell >
                <DragHandle >
                    <Typography variant={"h6"} mb={0}>{data?.lotSerialNbr}</Typography>
                </DragHandle>
            </TableCell>
            <TableCell >
                <DragHandle >
                    <Typography variant={"h6"} mb={0}>{data?.supplier}</Typography>
                </DragHandle>
            </TableCell>
            <TableCell >
                <Typography variant={"h6"} mb={0}> {data?.comment}{!props.unlock ? null : <IconButton onClick={props.addAdminComment(data)}><AddCommentIcon /> </IconButton>}</Typography>
            </TableCell>
            <TableCell >
                <DragHandle >
                    <Typography variant={"h6"} mb={0}>{remSS(data.stockItem)}</Typography>
                </DragHandle>
            </TableCell>
            <TableCell >
                <DragHandle >
                    <Typography variant={"h6"} mb={0}>{props.equipments.find((e) => { return e.id === data?.processRun?.processLineId })?.description}</Typography>
                </DragHandle>
            </TableCell>
            <TableCell >
                <DragHandle >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{numberFormat(data?.amount)}</Typography>
                </DragHandle>
            </TableCell>
            <TableCell >
                {data?.qaId ? <ViewQAButton access={props.access} id={data.qaId} recId={data.receivalId} /> : null}
            </TableCell>
            {/*<TableCell >*/}
            {/*    <DragHandle >*/}
            {/*        <Typography variant={"h6"} mb={0}></Typography>*/}
            {/*    </DragHandle>*/}
            {/*</TableCell>*/}
            {/*<TableCell >*/}
            {/*    <DragHandle >*/}
            {/*        <Typography variant={"h6"} mb={0}></Typography>*/}
            {/*    </DragHandle>*/}
            {/*</TableCell>*/}
            {/*<TableCell >*/}
            {/*    <DragHandle >*/}
            {/*        <Typography variant={"h6"} mb={0}></Typography>*/}
            {/*    </DragHandle>*/}
            {/*</TableCell>*/}
            {/*<TableCell >*/}
            {/*    <Typography variant={"h6"} mb={0}>{!props.unlock || hasStarted ? (data?.graded) : <SelectInTable key={props.ind + 'e'} api='UpdateGraded' id={data?.id} list={['Graded', 'UnGraded']} state={data?.graded} updateProcurement={props.updateProcurement(props.ind, 'graded')} />}</Typography>*/}

            {/*</TableCell>*/}
            <TableCell >
                <Typography variant={"h6"} mb={0}>{!props.unlock || hasStarted ? (data?.processType) : <SelectInTable key={props.ind + 'e'} api='UpdateProcessType' id={data?.id} list={processTypeList} state={data?.processType} updateProcurement={props.updateProcurement(props.ind, 'processType')} />}</Typography>
            </TableCell>
            <TableCell >
                <Typography variant={"h6"} mb={0}>{!props.unlock || hasStarted ? (status) : <SelectInTable key={props.ind + 'a'} api='UpdateStatus' id={data?.id} list={[Process, DoNotProcess, OnHold]} state={data?.status} updateProcurement={props.updateProcurement(props.ind, 'status')} />}</Typography>
            </TableCell>

        </TableRow>
    );
};

const SelectInTable = (props) => {
    const [value, setValue] = React.useState(props.state);
    const [loading, setLoading] = React.useState(false);
    const changeValue = async (state) => {
        setValue(state);
        props.updateProcurement(state)
        setLoading(true);
        var formatId = removeRL(props.id);
        var params = { id: formatId, processType: state };
        if (props.api === 'UpdateStatus') {
            params = { id: formatId, status: state };
        } else if (props.api === 'UpdateProcessType') {
            params = { id: formatId, processType: state };
        } else if (props.api === 'UpdateGraded') {
            params = { id: formatId, graded: state };
        }

        postApi('/Processing/' + props.api, params).then(() => {
            setLoading(false);
        })
    }
    if (loading) {
        return <CircularProgress />
    } else {
        return <SelectTextField key={'si' + props.id} title="" state={value} nameFunc={(n) => (n)} list={props.list} setState={changeValue} disabled={props.disabled} />
    }
}

export default PurchaseOrders;
