//Assumptions
//Can only select the same crop at one time
//Single bags wont happen in teh middle of a polygon

//Note
//States are stored 

import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Collapse from '@mui/material/Collapse';
import Div from "@jumbo/shared/Div";
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import RefreshIcon from '@mui/icons-material/Refresh';
import { checkAccess } from '../../utils/roleFunctions';
import { Table, TableBody, TableRow, TableCell, TableContainer, TableHead } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FormModal from "../../components/FormModal";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from "@mui/material/Typography";
import SelectionWindow from "./Forms/SelectionWindow";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
//import { row } from './tempData';
import { useJumboTheme } from "@jumbo/hooks";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { supplierToMatch } from '../../utils/constants/warehouse'
import { getAPIDataParamsReturn, getAPIData } from "../../utils/apiFunction"
import { setMapState, getMapState, findLocation, cropSupplier } from "./utils/mapFunction";
import { dateFormat } from "../../utils/dateFunctions";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { extractGroups } from "./utils/mapFunction";
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { displayDozFormat } from '../../utils/numberFunctions';
import ReplayIcon from '@mui/icons-material/Replay';
import { AccountTree } from '@mui/icons-material';
import HistoryModal from './components/HistoryModal';
import UndoModal from './components/UndoModal';
import { textColorContrast } from "../../utils/renderFunctions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';
const EMPTY = 'Empty'; //When there is no grade or batch
var mapStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}
var statusCols = [
    { description: 'Open', colour: 'green' },
    { description: 'Closed', colour: 'red' }
]

const generateColCase = (colList, type) => {
    var caseList = [];
    for (var i = 0; i < colList.length; i++) {
        caseList.push(['==', type, colList[i].description]);
        caseList.push(colList[i].colour);
    }
    return caseList;
}

function convertToBool(text) {
    if (text === 'False') {
        return false;
    } else {
        return true;
    }
}
const MapPage = (props) => {
    const { theme } = useJumboTheme();
    const IsProd = process.env.REACT_APP_MYOB_TENANT !== 'AOCTest';
    const mapContainer = React.useRef(null);
    const [map, setMap] = React.useState(null);
    const [selected, setSelected] = React.useState(null);
    const [resetLayer, setResetLayer] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [satImage, setSatImage] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [leases, setLeases] = React.useState(null);
    const [otherLeases, setOtherLeases] = React.useState(null);
    const [unitLabels, setUnitLabels] = React.useState(null);
    const [harvestAreas, setHarvestAreas] = React.useState(null);
    const [edgeButtons, setEdgeButtons] = React.useState(null);
    const [groupMarkers, setGroupMarkers] = React.useState(null);
    const [rows, setRows] = React.useState(null);
    const [start, setStart] = React.useState(null);
    const [savedGroupId, setSavedGroupId] = React.useState(searchParams.get("GroupId"));
    const [savedGroup, setSavedGroup] = React.useState(searchParams.get("GroupId"));
    const [savedUnits, setSavedUnits] = React.useState(searchParams.get("units"));
    const [savedWarehouseId, setSavedWarehouseId] = React.useState(searchParams.get("warehouseId"));
    const [end, setEnd] = React.useState(null);
    const [grades, setGrades] = React.useState([]);
    const [unitTypes, setUnitTypes] = React.useState([]);
    const [showGrade, setShowGrade] = React.useState(null);
    const [showUnitType, setShowUnitType] = React.useState(null);
    const [showDepDate, setShowDepDate] = React.useState(null);
    const [showGMs, setShowGMs] = React.useState(null);
    const [cropCols, setCropCols] = React.useState([]);
    const [showHAs, setShowHAs] = React.useState(true);
    const [showOLs, setShowOLs] = React.useState(false);
    const [selection, setSelection] = React.useState(false);
    const popUpRef = React.useRef(new mapboxgl.Popup({ offset: 3 }));
    const controlWindowRef = React.useRef(new mapboxgl.Popup());
    const [lineLoad, setLineLoad] = React.useState(true);
    const [leaseLoad, setLeaseLoad] = React.useState(true);
    const [dataLeases, setDataLeases] = React.useState([]);
    const [otherLeaseLoad, setOtherLeaseLoad] = React.useState(true);
    const [haLoad, setHaLoad] = React.useState(true);
    const [labelLoad, setLabelLoad] = React.useState(true);
    const [aocFarms, setAOCFarms] = React.useState([]);
    const [currZoom, setCurrZoom] = React.useState(8);
    const [zoom, setZoom] = React.useState(8);
    const [renderedFeats, setRenderedFeats] = React.useState([]);
    const [unitsValCorrect, setUnitsValCorrect] = React.useState(true);
    const [bagOverflow, setBagOverflow] = React.useState(false);
    const [linesStore, setLinesStore] = React.useState([]);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [openUndo, setOpenUndo] = React.useState(false);
    const [selGroupId, setSelGroupId] = React.useState(null);
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const requiredEditAccess = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.EDIT };
    const requiredGPSACCESS = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.MASTER };
    const requiredUndoAccess = { key: ACCESS_SECTIONS.UNDO, value: ACCESS.EDIT };
    const accessPage = props.access;
    mapboxgl.accessToken = 'pk.eyJ1IjoidWdseWJ1bnlpcCIsImEiOiJjbGZvNnZrbHYwajMyNDJwdGk0MHZlYWdlIn0.m2XdPYhVDEe00Zt4EcWk9g';

    const generateSelectCols = (row, positions) => {
        var caseList = [];
        caseList.push('case');
        for (var i = 0; i < positions.length; i++) {
            caseList.push(['==', ["concat", ['get', 'LineId'], '-', ['get', 'rowPos']], (String(row) + '-' + String(positions[i]))]);
            caseList.push('blue');
        }
        caseList.push('rgba(0,0,0,0)');
        return caseList;

    }
    const reloadWarehouseLines = async (wID) => {
        var updatedFeat = await getAPIDataParamsReturn('/GeoJSON/UnitLines', { warehouseId: wID });
        setRows(concatLines(linesStore.map((sl) => {
            if (sl.warehouseId !== wID) {
                return { ...sl };
            } else {
                return {
                    warehouseId: wID,
                    feature: updatedFeat
                }
            }
        })));
    }
    const reloadLeaseLines = async (wID, lID) => {
        var updatedFeat = await getAPIDataParamsReturn('/GeoJSON/UnitLines', { warehouseId: wID, leaseID: lID });
        setRows(concatLines(linesStore.map((sl) => {
            if (sl.warehouseId !== wID) {
                return { ...sl };
            } else {
                var cpySL = { ...sl };
                var cpyFeats = [...sl.feature.features];
                var remLeaseSL = cpyFeats.filter((cf) => (cf.properties.LeaseId !== lID));
                var newFeats = [...remLeaseSL, ...updatedFeat.features];
                cpySL.feature.features = newFeats;
                return {
                    ...cpySL
                }
            }
        })));
    }
    React.useEffect(() => {
        const getCC = async () => {
            const converttoDesc = (data) => {
                setCropCols(data.map((cc) => {
                    return { ...cc, description: cc.crop };
                }));
            }
            await getAPIData('/Farms/Lookups/BatchColours', converttoDesc);
        };
        getCC();
    }, []);
    React.useEffect(() => {
        const setGroups = (inp) => {
            var aocfarmlist = inp.farmers?.filter((f) => { return f.isInternal });
            //setFarm(aocfarmlist[0]);
            var newCombo = []
            if ((aocfarmlist.length > 0)) {
                for (var i = 0; i < aocfarmlist.length; i++) {
                    var matchObj = supplierToMatch(aocfarmlist[i].name);
                    if (props.access?.find((a) => { return a.key === matchObj.access })) {
                        newCombo.push(aocfarmlist[i])
                    }
                }
            }
            setAOCFarms(newCombo);
        }
        getAPIData('/farmerssuppliers', setGroups);
        getAPIData('/Farms/Lookups/Grades', setGrades);
        getAPIData('/Farms/Lookups/UnitTypes', setUnitTypes);
    }, []);

    const pullMapData = async (type, setFunc, load, setLoad, abortController) => {
        var arr = [];
        var promArr = [];
        setLoad(false);

        const concatArr = (arr) => {
            var newArr = [];
            if (arr.length > 0) {
                newArr = arr[0];
                for (var i = 1; i < arr.length; i++) {
                    newArr.features = [...newArr.features, ...arr[i].features];
                }
            }
            return newArr;
        }

        for (var i = 0; i < aocFarms.length; i++) {
            promArr.push(getAPIDataParamsReturn('/GeoJSON/' + type, { warehouseId: aocFarms[i].warehouseId }, abortController));
        }
        arr = concatArr(await Promise.all(promArr));
        setFunc(arr);
        setLoad(true);
    }
    const pullMapDataLines = async (type, setFunc, load, setLoad, abortController) => {
        var arr = [];
        var promArr = [];
        var warehouseIdArr = [];
        setLoad(false);


        for (var i = 0; i < aocFarms.length; i++) {
            warehouseIdArr.push(aocFarms[i].warehouseId);
            promArr.push(getAPIDataParamsReturn('/GeoJSON/' + type, { warehouseId: aocFarms[i].warehouseId }, abortController));
        }
        var arr = await Promise.all(promArr);
        var combArr = arr.map((feat, ind) => { return { warehouseId: warehouseIdArr[ind], feature: { ...feat } } });
        setLinesStore(combArr);
        var newArr = concatLines(combArr);
        setFunc(newArr);
        setLoad(true);
        buildGroupMarkers(newArr);
    }

    const loadFarm = async (farm) => {
        console.log(farm);
        if (!linesStore.find((ls) => { return ls.warehouseId === farm.id })) {
            //loadlines
            setLineLoad(false);
            setLabelLoad(false);

            var prmBoth = [];
            prmBoth.push(getAPIDataParamsReturn('/GeoJSON/UnitLines', { warehouseId: farm.id }));
            prmBoth.push(getAPIDataParamsReturn('/GeoJSON/UnitLabels', { warehouseId: farm.id }));
            var both = await Promise.all(prmBoth);
            var feat = both[0];
            var comboArr = [...linesStore, { warehouseId: farm.id, feature: feat }];
            setLinesStore(comboArr);
            var newArr = concatLines(comboArr);
            setRows(newArr);
            buildGroupMarkers(newArr);
            //loadlabels
            var labfeat = both[1];
            var newLabels = labfeat;
            if (unitLabels && unitLabels.features && unitLabels.features.length > 0) {
                newLabels = { ...unitLabels };
                newLabels.features = [...newLabels.features, ...labfeat.features];
            }
            setUnitLabels(newLabels);
            setLineLoad(true);
            setLabelLoad(true);


        }
    }
    var concatLines = (comboArr) => {
        var newArr = [];
        var arr = JSON.parse(JSON.stringify(comboArr));
        if (arr.length > 0) {
            for (var i = 0; i < arr[0].feature.features.length; i++) {
                arr[0].feature.features[i].properties.warehouseId = arr[0].warehouseId;
            }
            newArr = { ...arr[0].feature };

            for (var i = 1; i < arr.length; i++) {
                for (var j = 0; j < arr[i].feature.features.length; j++) {
                    arr[i].feature.features[j].properties.warehouseId = arr[i].warehouseId;
                }
                newArr.features = [...newArr.features, ...arr[i].feature.features];
            }
        }
        return newArr;
    }
    const buildGroupMarkers = (linesArr) => {
        //get the start and end of each line and create a feature point for it
        var newMarkers = [];
        for (var i = 0; i < linesArr.features.length; i++) {
            var line = linesArr.features[i];
            var bearVal = bearing([
                line.properties.StartLng,
                line.properties.StartLat
            ], [
                line.properties.EndLng,
                line.properties.EndLat
            ]);
            if (line.properties.Crop !== EMPTY) {
                //new geojson point

                var startMarker = {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            line.properties.StartLng,
                            line.properties.StartLat
                        ]
                    },
                    "properties": {
                        rotation: bearVal
                    }
                };
                var endMarker = {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            line.properties.EndLng,
                            line.properties.EndLat
                        ]
                    },
                    "properties": {
                        rotation: bearVal
                    }
                };
                newMarkers.push(startMarker);
                newMarkers.push(endMarker);
            }
        }
        setGroupMarkers({ type: "FeatureCollection", features: newMarkers });

    }

    React.useEffect(async () => {
        if (savedGroupId && savedWarehouseId) {
            //only get groups if deploying from onshore
            var groups = await getAPIDataParamsReturn('/Farms/Groups/OnShore', { WarehouseId: savedWarehouseId });
            setSavedGroup(groups.find((grp) => { return grp.id === Number(savedGroupId) }));
        }
    }, [savedGroupId, savedWarehouseId]);

    React.useEffect(() => {
        const abortController = new AbortController();
        if ((aocFarms.length > 0)) {

            pullMapData('AOCLeases', setLeases, leaseLoad, setLeaseLoad, abortController);
            pullMapData('HarvestAreas', setHarvestAreas, haLoad, setHaLoad, abortController);
            pullMapData('OtherLeases', setOtherLeases, otherLeaseLoad, setOtherLeaseLoad, abortController);
            pullDataLeases(setDataLeases, abortController);
            if ((aocFarms.length === 1)) {
                pullMapDataLines('UnitLines', setRows, lineLoad, setLineLoad, abortController); //save each farm individually for quick updates
                pullMapData('UnitLabels', setUnitLabels, labelLoad, setLabelLoad, abortController);
            }
        }
        return () => {
            abortController.abort();
        };
    }, [aocFarms]);
    const pullDataLeases = async (setFunc, abortController) => {
        var arr = [];
        var promArr = [];
        var warehouseIdArr = [];
        for (var i = 0; i < aocFarms.length; i++) {
            promArr.push(getAPIDataParamsReturn('/Farms/Lookups/Leases', { warehouseId: aocFarms[i].warehouseId }, abortController));
        }
        var arr = await Promise.all(promArr);
        var combArr = arr.reduce((p, c) => { return [...p, ...c] }, []);
        setFunc(combArr);
    }

    const refreshLines = () => {
        pullMapDataLines('UnitLines', setRows, lineLoad, setLineLoad);
        pullMapData('UnitLabels', setUnitLabels, labelLoad, setLabelLoad);
    }

    React.useEffect(() => {
        const setMapCenter = async () => {
            if (aocFarms.length > 0) {
                var lng = 150.170690043914;
                var lat = -35.719974685415416;//cranbrook
                var zoomL = 8;
                if (savedWarehouseId) { // use this more dynamically
                    var activeW = aocFarms.find((f) => { return Number(f.warehouseId) === Number(savedWarehouseId) });
                    if (activeW) {
                        lng = activeW.warehouseObject?.lng;
                        lat = activeW.warehouseObject?.lat;
                        zoomL = 13.5;
                    }
                    map.flyTo({
                        center: ([lng, lat]),
                        zoom: zoomL
                    });
                } else if (aocFarms.length === 1) {
                    if (aocFarms[0].warehouseObject?.lng && aocFarms[0].warehouseObject?.lat) {
                        lng = aocFarms[0].warehouseObject?.lng;
                        lat = aocFarms[0].warehouseObject?.lat;
                        zoomL = 13.5;
                    }

                    map.flyTo({
                        center: ([lng, lat]),
                        zoom: zoomL
                    });
                } else {
                    //remain
                }
            }
        }
        setMapCenter();
    }, [aocFarms, map, savedWarehouseId]);
    React.useEffect(() => {
        if (map) {
            if (grades.length > 0) {
                try {
                    map.setPaintProperty('grade_layer', 'fill-color', ['case',
                        ...generateColCase(grades, ['get', 'Grade']),
                        'rgba(255,255,255,0)'
                    ]);
                } catch {

                }
            }
        }
    }, [map, grades, resetLayer]);
    React.useEffect(() => {
        if (map) {
            if (unitTypes.length > 0) {
                try {
                    map.setPaintProperty('unittype_layer', 'fill-color', ['case',
                        ...generateColCase(unitTypes, ['get', 'UnitType']),
                        'rgba(255,255,255,0)'
                    ]);
                } catch {

                }
            }
        }
    }, [map, unitTypes, resetLayer]);

    React.useEffect(() => {
        if (map) {
            try {//show or hide layers based on sel
                map.setLayoutProperty('grade_layer', 'visibility', showGrade ? 'visible' : 'none');
                map.setLayoutProperty('unittype_layer', 'visibility', showUnitType ? 'visible' : 'none');
                map.setLayoutProperty('depdate_layer', 'visibility', showDepDate ? 'visible' : 'none');
                map.setPaintProperty('row_layer', 'fill-color', showGrade || showDepDate || showUnitType ? 'rgba(0,0,0,0)' : ['case',
                    ...generateColCase(cropCols, ['get', 'CropLabel']),
                    'rgba(0,0,0,0)'
                ]);
            } catch {

            }

        }
    }, [showGrade, showUnitType, showDepDate, map, resetLayer, cropCols]);

    React.useEffect(() => {
        if (map) {
            try {//show or hide layers based on sel
                console.log(showGMs);
                map.setLayoutProperty('GroupMarkers_Layer', 'visibility', showGMs ? 'visible' : 'none');
            } catch {

            }

        }
    }, [showGMs, map, resetLayer]);

    React.useEffect(() => {
        if (showGrade) {
            setShowUnitType(false);
            setShowDepDate(false);
        }
    }, [showGrade]);
    React.useEffect(() => {
        if (showUnitType) {
            setShowGrade(false);
            setShowDepDate(false);
        }
    }, [showUnitType]);
    React.useEffect(() => {
        if (showDepDate) {
            setShowUnitType(false);
            setShowGrade(false);
        }
    }, [showDepDate]);
    React.useEffect(() => {
        if (map) {
            try {
                map.setLayoutProperty('Other_lease_outline', 'visibility', showOLs ? 'visible' : 'none');
                map.setLayoutProperty('Other_lease_layer', 'visibility', showOLs ? 'visible' : 'none');
                map.setLayoutProperty('Other_lease_names', 'visibility', showOLs ? 'visible' : 'none');
            } catch {

            }
        }
    }, [showOLs]);
    React.useEffect(() => {
        if (map) {
            try {
                map.setLayoutProperty('HA_names', 'visibility', showHAs ? 'visible' : 'none');
                map.setLayoutProperty('HA_outline', 'visibility', showHAs ? 'visible' : 'none');
                map.setLayoutProperty('HA_layer', 'visibility', showHAs ? 'visible' : 'none');
            } catch {

            }
        }
    }, [showHAs]);
    //Attach Data To source field
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('leases').setData(leases);
            } catch {

            }
        }
    }, [map, leases, resetLayer]);
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('HAs').setData(harvestAreas);
            } catch {

            }
        }
    }, [map, harvestAreas, resetLayer]);
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('otherLeases').setData(otherLeases);
            } catch {

            }
        }
    }, [map, otherLeases, resetLayer]);
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('ULs').setData(unitLabels);
            } catch {

            }
        }
    }, [map, unitLabels, resetLayer]);
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('edgebutton').setData(edgeButtons);
            } catch {

            }
        }
    }, [map, edgeButtons, resetLayer]);
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('rows').setData(rows);
                map.getSource('clicks').setData(generateClickLayer(rows, map));
            } catch {

            }
        }
    }, [map, rows, resetLayer]);
    React.useEffect(() => {
        if (map) {
            try {
                map.getSource('GroupMarkers').setData(groupMarkers);
            } catch {

            }
        }
    }, [map, groupMarkers, resetLayer]);
    React.useEffect(() => {

        var lng = 150.170690043914;
        var lat = -35.719974685415416;//cranbrook
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [lng, lat],
            zoom: zoom
        });

        map.on('style.load', () => {
            map.addSource('selected', {
                type: 'geojson',
                data: selected
            });
            map.addSource('leases', {
                type: 'geojson',
                data: leases
            });
            map.addSource('rows', {
                type: 'geojson',
                data: rows
            });
            map.addSource('clicks', {
                type: 'geojson',
                data: null
            });
            map.addSource('otherLeases', {
                type: 'geojson',
                data: null
            });
            map.addSource('HAs', {
                type: 'geojson',
                data: null
            });
            map.addSource('edgebutton', {
                type: 'geojson',
                data: null
            });
            var styleJson = map.getStyle();
            var lineColor = 'grey';
            if (styleJson.name === "Mapbox Streets") {
                lineColor = '#000';
            }
            map.addLayer({
                'id': 'row_line_layer',
                'type': 'line',
                'source': 'rows',
                'paint': {
                    'line-color': lineColor,
                    'line-width': 1,
                    'line-opacity': 1
                },
                'minzoom': 16
            });
            map.addLayer({
                'id': 'row_layer',
                'type': 'fill',
                'source': 'rows',
                'paint': {
                    'fill-color': 'rgba(0,0,0,0)',
                    'fill-opacity': 1
                },
                'minzoom': 16
            });
            map.addLayer({
                'id': 'grade_layer',
                'type': 'fill',
                'source': 'rows',
                'paint': {
                    'fill-color': 'rgba(0,0,0,0)',
                    'fill-opacity': 1
                },
                'layout': {
                    'visibility': 'none',
                },
                'minzoom': 16
            });
            map.addLayer({
                'id': 'unittype_layer',
                'type': 'fill',
                'source': 'rows',
                'paint': {
                    'fill-color': 'rgba(0,0,0,0)',
                    'fill-opacity': 1
                },
                'layout': {
                    'visibility': 'none',
                },
                'minzoom': 16
            });
            map.addLayer({
                'id': 'depdate_layer',
                'type': 'fill',
                'source': 'rows',
                'paint': {
                    'fill-color': 'rgba(0,0,0,0)',
                    'fill-opacity': 1
                },
                'layout': {
                    'visibility': 'none',
                },
                'minzoom': 16
            });

            map.addLayer({
                'id': 'selected_layer',
                'type': 'fill',
                'source': 'selected',
                'paint': {
                    'fill-color': 'blue',
                    'fill-opacity': 1
                },
                'minzoom': 17
            });
            map.addLayer({
                'id': 'click_layer',
                'type': 'fill',
                'source': 'clicks',
                'paint': {
                    'fill-color': 'rgba(0,0,0,0)',
                    //'fill-outline-color': 'rgba(0,0,0,0.5)',
                },
                'minzoom': 17
            });
            map.addLayer({
                'id': 'click_layer_outline',
                'type': 'line',
                'source': 'clicks',
                'paint': {
                    'line-color': lineColor,
                    'line-width': 1,
                    'line-opacity': 0.5
                },
                'minzoom': 18
            });
            map.addLayer({
                'id': 'edgebutton_layer',
                'type': 'fill',
                'source': 'edgebutton',
                'paint': {
                    'fill-color': [
                        'case',
                        ...generateColCase([{ description: 'arrow', colour: 'white' }, { description: 'block', colour: 'black' }], ['get', 'Type']),
                        'rgba(255,255,255,0)'
                    ],
                    'fill-outline-color': 'rgba(0,0,0,0.5)',
                },
                'minzoom': 17
            });
            //Leases
            map.addLayer({
                'id': 'lease_layer',
                'type': 'fill',
                'source': 'leases',
                'paint': {
                    'fill-color': '#fac92c',
                    'fill-opacity': 0.7
                },
                'maxzoom': 16
            });


            map.addLayer({
                'id': 'lease_outline',
                'type': 'line',
                'source': 'leases',
                'layout': {},
                'paint': {
                    'line-color': '#000',
                    'line-width': 1
                },
                'maxzoom': 16
            });

            map.addLayer({
                'id': 'lease_names',
                'type': 'symbol',
                'source': 'leases',
                'paint': {
                    'text-color': ["step", ["zoom"],
                        'black',
                        16, 'lightgrey'
                    ]
                },
                'layout': {
                    'text-field': [
                        'format',
                        ['upcase', ['get', 'Name']],
                        { 'font-scale': 0.7 },
                        '\n',
                        {},
                        ['upcase', ['get', 'Lease_No']],
                        { 'font-scale': 0.7 }
                    ]
                },
                'minzoom': 14
            });


            // Other Leases
            map.addLayer({
                'id': 'Other_lease_layer',
                'type': 'fill',
                'source': 'otherLeases',
                'paint': {
                    'fill-color': 'white',
                    'fill-opacity': 0.5
                },
                'layout': {
                    'visibility': 'none',
                },
            });


            map.addLayer({
                'id': 'Other_lease_outline',
                'type': 'line',
                'source': 'otherLeases',
                'paint': {
                    'line-color': '#000',
                    'line-width': 1
                },
                'layout': {
                    'visibility': 'none',
                },
            });
            map.addLayer({
                'id': 'Other_lease_names',
                'type': 'symbol',
                'source': 'otherLeases',
                'layout': {
                    'visibility': 'none',
                    'text-field': [
                        'format',
                        ['upcase', ['get', 'Lease_No']],
                        { 'font-scale': 0.7 }
                    ]
                },
            });
            // Harvest areas
            map.addLayer({
                'id': 'HA_layer',
                'type': 'fill',
                'source': 'HAs',
                'paint': {
                    'fill-color': [
                        'case',
                        ...generateColCase(statusCols, ['get', 'Status']),
                        'rgba(0,0,0,0)'
                    ],
                    'fill-opacity': 0.4
                },
                'maxzoom': 16
            });


            map.addLayer({
                'id': 'HA_outline',
                'type': 'line',
                'source': 'HAs',
                'layout': {},
                'paint': {
                    'line-color': '#000',
                    'line-width': 1
                },
                'maxzoom': 16
            });
            map.addLayer({
                'id': 'HA_names',
                'type': 'symbol',
                'source': 'HAs',
                'layout': {
                    'text-field': [
                        'format',
                        ['upcase', ['get', 'HaZone']],
                        { 'font-scale': 0.8 },
                    ]
                },
                'maxzoom': 16

            });
            //UnitLables
            map.addSource('ULs', {
                type: 'geojson',
                data: null
            });
            //draw image from pixels
            var square = new Uint8Array(28 * 28 * 4);
            //draw white square from pixels

            //get width and height of square
            var width = Math.sqrt(square.length / 4);
            //draw a circle by pixels

            for (var y = 0; y < width; y++) {
                for (var x = 0; x < width; x++) {
                    var offset = (y * width + x) * 4;
                    var dist = Math.sqrt((x - width / 2) * (x - width / 2) + (y - width / 2) * (y - width / 2));
                    if (dist < width / 2) {
                        square[offset] = 255;
                        square[offset + 1] = 255;
                        square[offset + 2] = 255;
                        square[offset + 3] = 255;
                    }
                }
            }



            map.addImage('squarebox', { data: square, width: width, height: width });
            map.addLayer({
                'id': 'UnitLabels_layer',
                'type': 'symbol',
                'source': 'ULs',
                'layout': {
                    // get the title name from the source's "title" property
                    'icon-image': 'squarebox',
                    'icon-allow-overlap': true,
                    'icon-size': 0.7,
                    'text-field': ['get', 'LineNbr'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                },
                'minzoom': 18

            });
            map.addSource('GroupMarkers', {
                type: 'geojson',
                data: null,
            });
            //
            //draw triangle image from pixels
            //overkill solution
            var twidth = 40;
            var halfWidth = twidth / 2;
            var tAngle = 75 * Math.PI / 180;
            var triangle = new Uint8Array(twidth * twidth * 4);
            var adjustment = twidth * 0.2;
            //draw white triangle from pixels
            var innerAngle = 30 * Math.PI / 180;
            var tAngle2 = tAngle + innerAngle;
            for (var y = 0; y < twidth; y++) {
                for (var x = 0; x < twidth; x++) {
                    var testa = false;
                    if ((tAngle > (Math.PI / 2)) && (tAngle < (3 * Math.PI / 2))) {
                        if ((x > (Math.tan(tAngle) * y + (halfWidth - (halfWidth * Math.tan(tAngle)))))) {
                            testa = true;
                        }
                    } else {
                        if ((x < (Math.tan(tAngle) * y + (halfWidth - (halfWidth * Math.tan(tAngle)))))) {
                            testa = true;
                        }
                    }

                    var testb = false;
                    if ((tAngle2 > (Math.PI / 2)) && (tAngle2 < (3 * Math.PI / 2))) {
                        if ((x < (Math.tan(tAngle2) * y + (halfWidth - (halfWidth * Math.tan(tAngle2)))))) {
                            testb = true;
                        }
                    } else {
                        if ((x > (Math.tan(tAngle2) * y + (halfWidth - (halfWidth * Math.tan(tAngle2)))))) {
                            testb = true;
                        }
                    }

                    //if (testa && testb) {
                    if (testa && testb) {
                        var offset = (y * twidth + x) * 4;
                        triangle[offset] = 0;
                        triangle[offset + 1] = 0;
                        triangle[offset + 2] = 0;
                        triangle[offset + 3] = 255;
                    }
                }
            }



            map.addImage('markerArrow', { data: triangle, width: twidth, height: twidth });
            map.addLayer({
                'id': 'GroupMarkers_Layer',
                'type': 'symbol',
                'source': 'GroupMarkers',
                'layout': {
                    // get the title name from the source's "title" property
                    'icon-allow-overlap': true,
                    'icon-image': 'markerArrow',
                    'icon-size': 0.9,
                    'text-field': ['get', 'LineNbr'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'icon-rotate': ['get', 'rotation'],
                    'visibility': 'none',
                },
                'minzoom': 18

            });
            map.on('mouseleave', 'click_layer', () => {
                map.getCanvas().style.cursor = 'grab'
            })

            map.on('mouseleave', 'edgebutton_layer', () => {
                map.getCanvas().style.cursor = 'grab'
            })

            map.on('mouseleave', 'lease_layer', () => {
                map.getCanvas().style.cursor = 'grab'
            })
            map.on('mouseenter', 'click_layer', () => {
                map.getCanvas().style.cursor = 'pointer'
            })
            map.on('mouseenter', 'edgebutton_layer', () => {
                map.getCanvas().style.cursor = 'pointer'
            })
            map.on('mouseenter', 'lease_layer', () => {
                map.getCanvas().style.cursor = 'pointer'
            })
            map.on('click', function (e) {
                var clickedFeat = (map.queryRenderedFeatures(e.point));
                if (!(clickedFeat.length > 0)) {
                    var states = getMapState(map, 'selected', 'stateSave');
                    if (states.start) {

                        setStartandEnd(map, null, null)
                        setSelection(false);
                    }
                }
            });
            if (checkAccess(props.access, requiredGPSACCESS) && !IsProd) {
                map.on('contextmenu', function (e) {
                    const tooltipNode = document.createElement('div');
                    ReactDOM.render(<ThemeProvider theme={theme}> <GPSWindow lnglat={e.lngLat} /></ThemeProvider>, tooltipNode);
                    popUpRef.current
                        .setLngLat(e.lngLat)
                        .setDOMContent(tooltipNode)
                        .addTo(map)

                });
            }
            map.on('click', 'lease_layer', (e) => {
                map.flyTo({
                    center: (e.lngLat),
                    zoom: 17
                });
            });
            //map on move set renderedFeats as rendered Features
            map.on('move', () => {
                if (map.getZoom() >= 16) {
                    setRenderedFeats(map.queryRenderedFeatures({ layers: ['row_layer'] }));
                }
            });


            map.on('zoom', () => {
                setCurrZoom(map.getZoom());
            })
            map.on('click', 'HA_layer', haInfo(map));
            map.on('click', 'click_layer', clickFunction(map));
            map.on('click', 'edgebutton_layer', clickFunction(map));
            map.on('click', 'UnitLabels_layer', selectRowLabel(map));


            //see what layers are on screen
            //map.on('movestart', () => {
            //    const features = map.queryRenderedFeatures({ layers: ['airport'] });
            //});


            setResetLayer(Math.random()); //To rebuild all the layers
            setMap(map);
        });
        return () => map.remove();
    }, []);
    React.useEffect(() => {
        if (map) {
            if (satImage) {

                map.setStyle('mapbox://styles/mapbox/satellite-v9');

            } else {
                map.setStyle('mapbox://styles/mapbox/streets-v11');

            }
        }
    }, [satImage]);


    const selectRowLabel = (currMap) => (e) => {
        const tooltipNode = document.createElement('div');
        ReactDOM.render(<ThemeProvider theme={theme}> <RowWindow feature={e.features[0]} lnglat={e.lngLat} currMap={currMap} /></ThemeProvider>, tooltipNode);
        popUpRef.current
            .setLngLat(e.lngLat)
            .setDOMContent(tooltipNode)
            .addTo(currMap)
    }
    const haInfo = (currMap) => (e) => {
        const tooltipNode = document.createElement('div');
        ReactDOM.render(<ThemeProvider theme={theme}> <HAInfo feature={e.features[0]} lnglat={e.lngLat} currMap={currMap} /></ThemeProvider>, tooltipNode);
        popUpRef.current
            .setLngLat(e.lngLat)
            .setDOMContent(tooltipNode)
            .addTo(currMap)
    }

    const setStartandEnd = (currMap, startF, endF) => {
        setMapState(currMap, 'selected', 'stateSave', {
            start: startF,
            end: endF
        });
        setUnitsValCorrect(true);
        setBagOverflow(false);
        setStart(startF);
        setEnd(endF);
    }
    const findFeature = (currMap, row, pos) => {
        var layer = currMap.getSource('clicks');
        var features = layer._data.features;
        for (var i = 0; i < features.length; i++) {
            if (features[i].properties.LineId === row) {
                if (features[i].properties.rowPos === pos) {
                    return features[i];
                }
            }
        }
        return null;

    }
    const findStartandEndOfBatch = (row, rowPos) => {
        var currSumPos = 0;
        for (var i = 0; i < row.length; i++) {
            currSumPos += (row[i].len);
            if ((currSumPos - 1) >= rowPos) {
                return [currSumPos - row[i].len, currSumPos - 1];
            }
        }
        return [currSumPos - row[i - 1].len, currSumPos - 1];
    }
    const getPosOfGroup = (currMap, feature) => {
        var states = getMapState(currMap, 'selected', ('row' + feature.properties.LineId));
        var [start, end] = findStartandEndOfBatch(states.row, feature.properties.rowPos);
        var left = true;
        var right = true;
        var high = start;
        var low = end;
        if (start < end) {
            low = start;
            high = end;
        }
        var i = 0;
        var counter = 1;
        while ((left || right) && (i < 10000)) { //back up if stuck inloop
            i++;
            if (left) {
                if (allNextToEachOther(currMap, feature.properties.LineId, low, low - counter, feature.properties.CropLabel)) {
                    var [newStart, newEnd] = findStartandEndOfBatch(states.row, low - counter);
                    low = newStart;
                } else {
                    left = false;
                }
            }
            if (right) {
                if (allNextToEachOther(currMap, feature.properties.LineId, high, high + counter, feature.properties.CropLabel)) {
                    var [newStart, newEnd] = findStartandEndOfBatch(states.row, high + counter);
                    high = newEnd;
                } else {
                    right = false;
                }
            }
            counter++;
        }
        return [low, high];
    }
    const wholeGroup = (currMap, feature) => () => {
        var startPos = feature.properties.rowPos;
        var [low, high] = getPosOfGroup(currMap, feature);
        var start = low;
        var end = high;
        if (Math.abs(startPos - low) > Math.abs(startPos - high)) {
            start = high;
            end = low;
        }
        setStartandEnd(currMap, findFeature(currMap, feature.properties?.LineId, start), findFeature(currMap, feature.properties?.LineId, end));
    }
    const pickUp = (currMap, feature, amount) => () => {
        var density = feature.properties.density;
        var [low, high] = getPosOfGroup(currMap, feature);
        var startPos = feature.properties.rowPos;
        var endPos = high;
        if ((high - startPos) > (startPos - low)) {// need to check if it is able to select the one next to it first
            var newStartPos = findFirstOfParrellel(feature?.properties?.rowPos, density, true, true);
            if (allNextToEachOther(currMap, feature?.properties?.LineId, feature?.properties?.rowPos, newStartPos, feature?.properties?.CropLabel)) {
                startPos = newStartPos;
            }
            endPos = startPos + (amount - 1);
            if (endPos > high) {
                endPos = high;
            }
        } else {
            var newStartPos = findFirstOfParrellel(feature?.properties?.rowPos, density, true, false);
            if (allNextToEachOther(currMap, feature?.properties?.LineId, feature?.properties?.rowPos, newStartPos, feature?.properties?.CropLabel)) {
                startPos = newStartPos;
            }
            endPos = startPos - (amount - 1);
            if (endPos < low) {
                endPos = low;
            }

        }
        setStartandEnd(currMap, findFeature(currMap, feature.properties?.LineId, startPos), findFeature(currMap, feature.properties?.LineId, endPos));
    }
    const moveBagsForTotal = (currMap) => (total) => {
        var feats = getMapState(currMap, 'selected', 'stateSave');
        var startFeature = feats.start;
        if (total) {
            if (startFeature) {
                var states = getMapState(currMap, 'selected', ('row' + startFeature.properties.LineId));
                if (states.row) {
                    var endPos = 0;
                    var [low, high] = getPosOfGroup(currMap, startFeature);
                    if (feats.end) {
                        if (startFeature.properties.rowPos === feats.end.properties.rowPos) {
                            if (Math.abs(startFeature.properties.rowPos - low) < Math.abs(startFeature.properties.rowPos - high)) {
                                endPos = Number(startFeature.properties.rowPos) + Number(total) - 1;
                            } else {
                                endPos = Number(startFeature.properties.rowPos) - Number(total) + 1;
                            }
                        } else if (startFeature.properties.rowPos < feats.end.properties.rowPos) {
                            endPos = Number(startFeature.properties.rowPos) + Number(total) - 1;
                        } else {
                            endPos = Number(startFeature.properties.rowPos) - Number(total) + 1;
                        }
                    } else {

                        if (Number(total) === 1) {
                            endPos = Number(startFeature.properties.rowPos);
                        } else {
                            if (Math.abs(startFeature.properties.rowPos - low) < Math.abs(startFeature.properties.rowPos - high)) {
                                var startPos = findFirstOfParrellel(startFeature?.properties?.rowPos, startFeature?.properties?.density, true, true);
                                if (startPos !== startFeature?.properties?.rowPos) {
                                    if (allNextToEachOther(currMap, startFeature.properties.LineId, startFeature.properties.rowPos, startPos, startFeature.properties.CropLabel)) {
                                        startFeature = findFeature(currMap, startFeature.properties?.LineId, startPos)
                                    }
                                }
                                endPos = Number(startFeature.properties.rowPos) + Number(total) - 1;
                            } else {
                                var startPos = findFirstOfParrellel(startFeature?.properties?.rowPos, startFeature?.properties?.density, true, false);
                                if (startPos !== startFeature?.properties?.rowPos) {
                                    if (allNextToEachOther(currMap, startFeature.properties.LineId, startFeature.properties.rowPos, startPos, startFeature.properties.CropLabel)) {
                                        startFeature = findFeature(currMap, startFeature.properties?.LineId, startPos)
                                    }
                                }
                                endPos = Number(startFeature.properties.rowPos) - Number(total) + 1;
                            }
                        }
                    }
                    //if it overshoots greater than lease allows - need to handle
                    if (allNextToEachOther(currMap, startFeature.properties.LineId, startFeature.properties.rowPos, endPos, startFeature.properties.CropLabel)) {
                        setStartandEnd(currMap, startFeature, findFeature(currMap, startFeature?.properties?.LineId, endPos))
                        setUnitsValCorrect(true);
                        setBagOverflow(false);
                    } else {
                        if (startFeature.properties.Crop !== 'Empty') {
                            setUnitsValCorrect(false);
                        } else {
                            setBagOverflow(true);
                        }
                    }
                }
            }
        }
    }
    const allNextToEachOther = (currMap, row, startPos, endPos, cropLabel) => {
        //assumes row is the same
        //check for array in the middle
        var states = getMapState(currMap, 'selected', ('row' + row));
        if (states.row) {
            var startInd = findLocation(states.row, startPos);
            var endInd = findLocation(states.row, endPos);
            if ((startInd >= 0) && (endInd >= 0)) {
                //if (Math.abs(startInd - endInd) === 1) {
                //    return true;
                //}
                var high = 0;
                var low = 0;
                if (startInd > endInd) {
                    high = startInd;
                    low = endInd;
                } else {
                    high = endInd;
                    low = startInd;
                }
                for (var i = low; i <= high; i++) {
                    if (states.row[i].props.CropLabel !== cropLabel) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    const findFirstOfParrellel = (posA, density, start, forwards) => {
        if (density === 2) {
            if (posA % density === 0) {
                if (forwards) {
                    if (start) {
                        return posA;
                    } else {
                        return posA + 1;
                    }
                } else {
                    if (start) {
                        return posA + 1;
                    } else {
                        return posA;
                    }
                }
            } else if (posA % density === 1) {
                if (forwards) {
                    if (start) {
                        return posA - 1;
                    } else {
                        return posA;
                    }
                } else {
                    if (start) {
                        return posA;
                    } else {
                        return posA - 1;
                    }
                }

            }
        } else if (density === 1) {
            return posA
        }
    }
    const openHistoryModal = (groupId) => (e) => {
        setSelGroupId(groupId);
        setOpenHistory(true);
    }
    const openUndoModal = (groupId) => (e) => {
        setSelGroupId(groupId);
        setOpenUndo(true);
    }
    const clickFunction = (currMap) => (e) => {


        var density = Number(e.features[0].properties.density);

        const batch = e.features[0].properties.Grade;

        const closePopup = () => {
            controlWindowRef.current.remove();
        }


        if (currMap) {
            var states = getMapState(currMap, 'selected', 'stateSave');
            // same row and same batch and next to each other
            if ((states.start) && (states.start?.properties?.LineId === e.features[0]?.properties?.LineId) && (states.start?.properties?.CropLabel === e.features[0]?.properties?.CropLabel) && allNextToEachOther(currMap, states.start?.properties?.LineId, states.start?.properties?.rowPos, e.features[0]?.properties?.rowPos, e.features[0]?.properties?.CropLabel)) {
                const tooltipNode = document.createElement('div');
                var start = states.start;
                var end = e.features[0];
                if (states.start?.properties?.rowPos !== e.features[0]?.properties?.rowPos) {
                    var forwards = ((states.start?.properties?.rowPos - e.features[0]?.properties?.rowPos) < 0);
                    var newStartPos = findFirstOfParrellel(states.start?.properties?.rowPos, density, true, forwards);
                    if (newStartPos !== states.start?.properties?.rowPos) {
                        if (allNextToEachOther(currMap, states.start?.properties?.LineId, states.start?.properties?.rowPos, newStartPos, states.start?.properties?.CropLabel)) {
                            var newFeat = findFeature(currMap, states.start?.properties?.LineId, newStartPos);
                            start = newFeat;
                        }
                    }

                    var newEndPos = findFirstOfParrellel(e.features[0]?.properties?.rowPos, density, false, forwards);
                    if (newEndPos !== e.features[0]?.properties?.rowPos) {
                        if (allNextToEachOther(currMap, e.features[0]?.properties?.LineId, e.features[0]?.properties?.rowPos, newEndPos, e.features[0]?.properties?.CropLabel)) {
                            var newFeat = findFeature(currMap, e.features[0]?.properties?.LineId, newEndPos);
                            end = newFeat;
                        }
                    }
                }

                setStartandEnd(currMap, start, end)

                setSelection(true);
            } else {

                const tooltipNode = document.createElement('div');
                ReactDOM.render(<ThemeProvider theme={theme}> <InfoWindow openHistoryModal={openHistoryModal} openUndoModal={openUndoModal} feature={e.features[0]} lnglat={e.lngLat} currMap={currMap} wholeGroup={wholeGroup} pickUp={pickUp} /></ThemeProvider>, tooltipNode);
                popUpRef.current
                    .setLngLat(e.lngLat)
                    .setDOMContent(tooltipNode)
                    .addTo(currMap)


                setStartandEnd(currMap, e.features[0], end)

            }
        }



    }
    const testIfParallel = (posA, posB, dens) => {
        if (dens === 1) {

        } else if (dens === 2) {

            if (Math.abs(posA - posB) === 1) {
                if (posA % dens === 0) {
                    if (posA < posB) {
                        return true;
                    }
                } else if (posA % dens === 1) {
                    if (posA > posB) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    React.useEffect(() => {
        if (map) {
            try {
                var states = getMapState(map, 'selected', 'stateSave');
                var startSel = states.start;
                var endSel = states.end;
                const numbersFromTo = (from, to) => {
                    var arr = [];
                    var a = from;
                    var b = to;
                    if (a > b) {
                        b = from;
                        a = to;
                    }
                    for (var i = a; i <= b; i++) {
                        arr.push(i);
                    }
                    return arr;
                }
                var featCol = {
                    "type": "FeatureCollection", "features": []
                };
                var list = [];
                const groupDef = {
                    type: 'feature',
                    properties: {},
                    geometry: {
                        coordinates: [[]]
                    }
                };
                const drawSelectedForStraightLine = (startS, endS) => {
                    var listIn = [];
                    var startPos = Number(startS.properties.rowPos);
                    var endPos = Number(endS.properties.rowPos);
                    if (startPos === endPos) {
                        var group = JSON.parse(JSON.stringify(groupDef));
                        group.geometry.type = "Polygon";
                        group.geometry.coordinates[0] = (startS.geometry.coordinates[0]);
                        listIn.push(group);
                    } else {

                        var width = Number(startS.properties.width);
                        var length = Number(startS.properties.length);
                        var density = Number(startS.properties.density);
                        var start = JSON.parse(startS.properties.start);
                        var end = JSON.parse(endS.properties.start);

                        if (startPos < endPos) {
                            end = JSON.parse(endS.properties.end);
                        } else {
                            start = JSON.parse(startS.properties.end);
                        }

                        var bear = bearing(start, end);
                        var posBear = 1;
                        if (startPos < endPos) {
                            if (!(startPos === findFirstOfParrellel(startPos, density, true, true))) {
                                var group = JSON.parse(JSON.stringify(groupDef));
                                group.geometry.type = "Polygon";
                                group.geometry.coordinates[0] = (startS.geometry.coordinates[0]);
                                listIn.push(group);
                                startPos = startPos + 1;
                                start = getPoint(length, bear, start);
                            }
                            if (!(endPos === findFirstOfParrellel(endPos, density, false, true))) {

                                var group = JSON.parse(JSON.stringify(groupDef));
                                group.geometry.type = "Polygon";
                                group.geometry.coordinates[0] = (endS.geometry.coordinates[0]);
                                listIn.push(group);
                                endPos = endPos - 1;
                                end = getPoint(-length, bear, end);

                            }

                        } else {
                            posBear = -1;
                            if (!(startPos === findFirstOfParrellel(startPos, density, true, false))) {

                                var group = JSON.parse(JSON.stringify(groupDef));
                                group.geometry.type = "Polygon";
                                group.geometry.coordinates[0] = (startS.geometry.coordinates[0]);
                                listIn.push(group);
                                startPos = startPos - 1;
                                start = getPoint(length, bear, start);
                            }
                            if (!(endPos === findFirstOfParrellel(endPos, density, false, false))) {

                                var group = JSON.parse(JSON.stringify(groupDef));
                                group.geometry.type = "Polygon";
                                group.geometry.coordinates[0] = (endS.geometry.coordinates[0]);
                                listIn.push(group);
                                endPos = endPos + 1;
                                end = getPoint(-length, bear, end);
                            }
                        }
                        var group = JSON.parse(JSON.stringify(groupDef));
                        group.geometry.type = "Polygon";
                        group.geometry.coordinates[0].push(start);
                        group.geometry.coordinates[0].push(getPoint(posBear * width * density, bear + 90, start));
                        group.geometry.coordinates[0].push(getPoint(posBear * width * density, bear + 90, end));
                        group.geometry.coordinates[0].push(end);
                        group.geometry.coordinates[0].push(start);
                        listIn.push(group);
                    }
                    return listIn;
                }
                if (startSel && endSel) {
                    if (startSel.properties.LineRefId === endSel.properties.LineRefId) {
                        list = drawSelectedForStraightLine(startSel, endSel);
                        featCol.features = list;
                    } else {
                        var existingGroups = extractGroups(map, startSel.properties.rowPos, endSel.properties.rowPos, startSel.properties.LineId);
                        for (var i = 0; i < existingGroups.length; i++) {
                            list = [...list, ...drawSelectedForStraightLine(findFeature(map, startSel.properties.LineId, existingGroups[i].startPos), findFeature(map, startSel.properties.LineId, existingGroups[i].endPos))];
                            console.log(list);
                        }
                        featCol.features = list;

                    }

                } else if (startSel) {
                    var group = JSON.parse(JSON.stringify(groupDef));
                    group.geometry.type = "Polygon";
                    group.geometry.coordinates[0] = (startSel.geometry.coordinates[0]);
                    list.push(group);
                    featCol.features = list;

                } else {

                }
                map.getSource('selected').setData(featCol);

            } catch {
                console.log('failed to update states')

            }
        }
    }, [start, end])

    //Creating a layer to display each bag. It also assigns each bag a position number so we know what it is next too ( batch and crop agnostic);
    const generateClickLayer = (groups, currMap) => {
        var density = 2;
        var featCol = {
            "type": "FeatureCollection", "features": []
        };
        var list = [];
        const groupDef = {
            type: 'feature',
            properties: {},
            geometry: {
                coordinates: [[]]
            }
        };
        var allFeatures = [...groups.features];
        //AllFeatures sort by row
        //Allfeatures sort by order
        allFeatures.sort((a, b) => {
            if ((a.properties.ParentId === b.properties.ParentId)) {
                if (a.properties.SeqNo === b.properties.SeqNo) {
                    return Number(a.properties.Order) > Number(b.properties.Order) ? 1 : -1;
                } else {
                    return Number(a.properties.SeqNo) > Number(b.properties.SeqNo) ? 1 : -1;
                }
            } else {
                return Number(a.properties.ParentId) > Number(b.properties.ParentId) ? 1 : -1;
            }
        });
        var rowPos = 0;
        var currRow = null;
        var rowArr = [];
        var edgeButs = [];
        var blockWid = 0.7;
        for (var i = 0; i < allFeatures.length; i++) {
            var featureCount = 0;
            var currF = allFeatures[i];
            density = currF.properties.Density ? Number(currF.properties.Density) : 2;

            if (!(currRow) || (currRow !== currF.properties.ParentId)) {
                if (i !== 0) {
                    if ((allFeatures[i - 1].properties.Crop !== EMPTY)) {// put expandbutton on end of row // 
                        //var thisFeature = ((i + 1) === allFeatures.length) ? allFeatures[i] : allFeatures[i - 1]; // only happens on the last group of all features
                        var thisFeature = allFeatures[i - 1];
                        var wid = thisFeature.properties.UnitWidth ? Number(thisFeature.properties.UnitWidth) : 1;
                        var localEnd = [Number(thisFeature.properties.EndLng), Number(thisFeature.properties.EndLat)];
                        var localStart = [Number(thisFeature.properties.StartLng), Number(thisFeature.properties.StartLat)];
                        var localBear = bearing(localStart, localEnd);
                        var nextCorn = getPoint(blockWid, localBear, localEnd);
                        var eb = JSON.parse(JSON.stringify(groupDef));
                        var localDensity = thisFeature.properties.Density ? Number(thisFeature.properties.Density) : 2;
                        var fullWid = wid * localDensity;
                        eb.properties.rowPos = rowPos;
                        eb.properties.id = EMPTY;
                        eb.properties.CropLabel = EMPTY;
                        eb.properties.Crop = EMPTY;
                        eb.properties.overflow = true;
                        eb.properties.Supplier = EMPTY;
                        eb.properties.LeaseId = thisFeature.properties.LeaseId;
                        eb.properties.LineId = thisFeature.properties.ParentId;
                        eb.properties.UnitType = EMPTY;
                        eb.properties.bagNo = rowPos;
                        eb.properties.Grade = EMPTY;
                        eb.properties.type = EMPTY;
                        eb.properties.warehouseId = thisFeature.properties.warehouseId;
                        eb.properties.Type = 'block';
                        eb.geometry.type = "Polygon";
                        eb.geometry.coordinates[0].push(localEnd);
                        eb.geometry.coordinates[0].push(nextCorn);
                        eb.geometry.coordinates[0].push(getPoint(fullWid, (localBear + 90), nextCorn));
                        eb.geometry.coordinates[0].push(getPoint(fullWid, (localBear + 90), localEnd));
                        eb.geometry.coordinates[0].push(localEnd);
                        edgeButs.push(eb);

                        var startCorner = getPoint(fullWid * 0.3, (localBear + 90), getPoint(blockWid * 0.25, localBear, localEnd));
                        var eb = JSON.parse(JSON.stringify(groupDef));
                        eb.properties.rowPos = rowPos;
                        eb.properties.id = EMPTY;
                        eb.properties.CropLabel = EMPTY;
                        eb.properties.Crop = EMPTY;
                        eb.properties.overflow = true;
                        eb.properties.Supplier = EMPTY;
                        eb.properties.LeaseId = thisFeature.properties.LeaseId;
                        eb.properties.LineId = thisFeature.properties.ParentId;
                        eb.properties.UnitType = EMPTY;
                        eb.properties.bagNo = rowPos;
                        eb.properties.Grade = EMPTY;
                        eb.properties.type = EMPTY;
                        eb.properties.warehouseId = thisFeature.properties.warehouseId;
                        eb.properties.Type = 'arrow';
                        eb.geometry.type = "Polygon";
                        eb.geometry.coordinates[0].push(startCorner);
                        eb.geometry.coordinates[0].push(getPoint(fullWid * 0.4, (localBear + 90), startCorner));
                        eb.geometry.coordinates[0].push(getPoint(fullWid * 0.5, (localBear + 90), getPoint(blockWid * 0.8, localBear, localEnd)));
                        eb.geometry.coordinates[0].push(startCorner);
                        edgeButs.push(eb);

                    }
                }
                if ((currF.properties.Crop !== EMPTY)) {// put expandbutton on beginning of row
                    var wid = currF.properties.UnitWidth ? Number(currF.properties.UnitWidth) : 1;
                    var localStart = [Number(currF.properties.StartLng), Number(currF.properties.StartLat)];
                    var localEnd = [Number(currF.properties.EndLng), Number(currF.properties.EndLat)];
                    var localBear = bearing(localStart, localEnd);
                    var nextCorn = getPoint(-blockWid, localBear, localStart);
                    var localDensity = currF.properties.Density ? Number(currF.properties.Density) : 2;
                    var fullWid = wid * localDensity;
                    var eb = JSON.parse(JSON.stringify(groupDef));
                    eb.properties.rowPos = -1;
                    eb.properties.id = EMPTY;
                    eb.properties.CropLabel = EMPTY;
                    eb.properties.Crop = EMPTY;
                    eb.properties.overflow = true;
                    eb.properties.Supplier = EMPTY;
                    eb.properties.LeaseId = currF.properties.LeaseId;
                    eb.properties.LineId = currF.properties.ParentId;
                    eb.properties.UnitType = EMPTY;
                    eb.properties.bagNo = -1;
                    eb.properties.Grade = EMPTY;
                    eb.properties.type = EMPTY;
                    eb.properties.warehouseId = currF.properties.warehouseId;
                    eb.properties.Type = 'block';
                    eb.geometry.type = "Polygon";
                    eb.geometry.coordinates[0].push(localStart);
                    eb.geometry.coordinates[0].push(nextCorn);
                    eb.geometry.coordinates[0].push(getPoint(fullWid, (localBear + 90), nextCorn));
                    eb.geometry.coordinates[0].push(getPoint(fullWid, (localBear + 90), localStart));
                    eb.geometry.coordinates[0].push(localStart);
                    edgeButs.push(eb);

                    var startCorner = getPoint(fullWid * 0.3, (localBear + 90), getPoint(-blockWid * 0.25, localBear, localStart));
                    var eb = JSON.parse(JSON.stringify(groupDef));
                    eb.properties.rowPos = -1;
                    eb.properties.id = EMPTY;
                    eb.properties.CropLabel = EMPTY;
                    eb.properties.Crop = EMPTY;
                    eb.properties.overflow = true;
                    eb.properties.Supplier = EMPTY;
                    eb.properties.LeaseId = currF.properties.LeaseId;
                    eb.properties.LineId = currF.properties.ParentId;
                    eb.properties.UnitType = EMPTY;
                    eb.properties.bagNo = -1;
                    eb.properties.Grade = EMPTY;
                    eb.properties.type = EMPTY;
                    eb.properties.warehouseId = currF.properties.warehouseId;
                    eb.properties.Type = 'arrow';
                    eb.geometry.type = "Polygon";
                    eb.geometry.coordinates[0].push(startCorner);
                    eb.geometry.coordinates[0].push(getPoint(fullWid * 0.4, (localBear + 90), startCorner));
                    eb.geometry.coordinates[0].push(getPoint(fullWid * 0.5, (localBear + 90), getPoint(-blockWid * 0.8, localBear, localStart)));
                    eb.geometry.coordinates[0].push(startCorner);
                    edgeButs.push(eb);
                }

                if (i !== 0) {
                    setMapState(currMap, 'selected', ('row' + currRow), {
                        row: rowArr,
                    });
                }
                rowPos = 0;
                currRow = currF.properties.ParentId;
                rowArr = [];

            }

            var start = [Number(currF.properties.StartLng), Number(currF.properties.StartLat)];
            var end = [Number(currF.properties.EndLng), Number(currF.properties.EndLat)];
            var dist = distance(start, end); /// adjust for one bag per metre
            var bear = bearing(start, end);
            var totalOffsetBags = Number(currF.properties.StartOffset) + Number(currF.properties.EndOffset);
            var totalBags = Number(currF.properties.TotalUnits) + totalOffsetBags;

            var bagRows = Math.ceil(totalBags / density);
            var width = currF.properties.UnitWidth ? Number(currF.properties.UnitWidth) : 1;
            var met = dist / bagRows;
            if (Number(currF.properties.TotalUnits) === 0) { //To be removed when total units is always there
                if (dist < 2 && dist > 0) {
                    bagRows = 1;
                } else {
                    bagRows = Math.floor(dist);
                }
                met = 1;
            }
            var BagNo = 0;
            var nextPoint = start;
            //Only ever the forst bag or last bag are missing
            //also allows for if it 2 bags are missing
            //function to convert text isHatched to Boolean


            for (var j = 0; j < bagRows; j++) {
                var firstcrnPt = getPoint(width, bear + 90, nextPoint);
                var secondcrnPt = getPoint(width * 2, bear + 90, nextPoint);
                var nextMiddle = getPoint(met, bear, nextPoint);

                if ((!((BagNo === 0) && (Number(currF.properties.StartOffset) >= 1))) && (!((BagNo === 1) && (Number(currF.properties.StartOffset) == 2)))) {
                    featureCount++;
                    var group = JSON.parse(JSON.stringify(groupDef));
                    group.properties.rowPos = rowPos;
                    group.properties.density = density;
                    group.properties.length = met;
                    group.properties.width = width;
                    group.properties.id = currF.properties.GroupId;
                    group.properties.CropLabel = currF.properties.CropLabel;
                    group.properties.Crop = currF.properties.Crop;
                    group.properties.IsHatched = convertToBool(currF.properties.IsHatched);
                    group.properties.Supplier = currF.properties.Supplier;
                    group.properties.LeaseId = currF.properties.LeaseId;
                    group.properties.LineId = currF.properties.ParentId;
                    group.properties.LineRefId = currF.properties.LineId;
                    group.properties.UnitType = currF.properties.UnitType;
                    group.properties.Average = currF.properties.Average;
                    group.properties.DeploymentDate = currF.properties.DeploymentDate;
                    group.properties.bagNo = BagNo;
                    group.properties.Grade = currF.properties.Grade;
                    group.properties.bearing = bear;
                    group.properties.start = JSON.stringify(nextPoint);
                    group.properties.end = JSON.stringify(nextMiddle);
                    group.properties.type = 'FloatingBags';
                    group.properties.warehouseId = currF.properties.warehouseId;
                    group.properties.Notes = currF.properties.Notes;
                    group.geometry.type = "Polygon";
                    group.geometry.coordinates[0].push(nextPoint);
                    group.geometry.coordinates[0].push(firstcrnPt);
                    group.geometry.coordinates[0].push(getPoint(met, bear, firstcrnPt));
                    group.geometry.coordinates[0].push(getPoint(met, bear, nextPoint));
                    group.geometry.coordinates[0].push(nextPoint);

                    list.push(group);
                    BagNo++;
                    rowPos++;
                }

                if ((density > 1) && (!((BagNo === (totalBags - totalOffsetBags)) && (Number(currF.properties.EndOffset) >= 1))) && (!((BagNo === (totalBags - totalOffsetBags - 1)) && (Number(currF.properties.EndOffset) == 2)))) {
                    featureCount++;
                    var group = JSON.parse(JSON.stringify(groupDef));
                    group.properties.rowPos = rowPos;
                    group.properties.density = density;
                    group.properties.length = met;
                    group.properties.width = width;
                    group.properties.id = currF.properties.GroupId;
                    group.properties.CropLabel = currF.properties.CropLabel;
                    group.properties.Crop = currF.properties.Crop;
                    group.properties.IsHatched = convertToBool(currF.properties.IsHatched);
                    group.properties.Supplier = currF.properties.Supplier;
                    group.properties.LeaseId = currF.properties.LeaseId;
                    group.properties.LineId = currF.properties.ParentId;
                    group.properties.LineRefId = currF.properties.LineId;
                    group.properties.UnitType = currF.properties.UnitType;
                    group.properties.Average = currF.properties.Average;
                    group.properties.DeploymentDate = currF.properties.DeploymentDate;
                    group.properties.bagNo = BagNo;
                    group.properties.Grade = currF.properties.Grade;
                    group.properties.bearing = bear;
                    group.properties.start = JSON.stringify(nextPoint);
                    group.properties.end = JSON.stringify(nextMiddle);
                    group.properties.type = 'FloatingBags';
                    group.geometry.type = "Polygon";
                    group.properties.warehouseId = currF.properties.warehouseId;
                    group.properties.Notes = currF.properties.Notes;
                    group.geometry.coordinates[0].push(firstcrnPt);
                    group.geometry.coordinates[0].push(secondcrnPt);
                    group.geometry.coordinates[0].push(getPoint(met, bear, secondcrnPt));
                    group.geometry.coordinates[0].push(getPoint(met, bear, firstcrnPt));
                    group.geometry.coordinates[0].push(firstcrnPt);

                    list.push(group);
                    BagNo++;
                    rowPos++;
                }
                nextPoint = nextMiddle;

            }

            rowArr.push({ len: featureCount, props: currF.properties });
        }
        setMapState(currMap, 'selected', ('row' + currRow), {
            row: rowArr,
        });
        if ((allFeatures[allFeatures.length - 1].properties.Crop !== EMPTY)) {// put expandbutton on end of row for very last feature // 
            var thisFeature = allFeatures[allFeatures.length - 1];
            var wid = thisFeature.properties.UnitWidth ? Number(thisFeature.properties.UnitWidth) : 1;
            var localEnd = [Number(thisFeature.properties.EndLng), Number(thisFeature.properties.EndLat)];
            var localStart = [Number(thisFeature.properties.StartLng), Number(thisFeature.properties.StartLat)];
            var localBear = bearing(localStart, localEnd);
            var nextCorn = getPoint(blockWid, localBear, localEnd);
            var eb = JSON.parse(JSON.stringify(groupDef));
            var localDensity = thisFeature.properties.Density ? Number(thisFeature.properties.Density) : 2;
            var fullWid = wid * localDensity;
            eb.properties.rowPos = rowPos;
            eb.properties.id = EMPTY;
            eb.properties.CropLabel = EMPTY;
            eb.properties.Crop = EMPTY;
            eb.properties.overflow = true;
            eb.properties.Supplier = EMPTY;
            eb.properties.LeaseId = thisFeature.properties.LeaseId;
            eb.properties.LineId = thisFeature.properties.ParentId;
            eb.properties.UnitType = EMPTY;
            eb.properties.bagNo = rowPos;
            eb.properties.Grade = EMPTY;
            eb.properties.type = EMPTY;
            eb.properties.warehouseId = thisFeature.properties.warehouseId;
            eb.properties.Type = 'block';
            eb.geometry.type = "Polygon";
            eb.geometry.coordinates[0].push(localEnd);
            eb.geometry.coordinates[0].push(nextCorn);
            eb.geometry.coordinates[0].push(getPoint(fullWid, (localBear + 90), nextCorn));
            eb.geometry.coordinates[0].push(getPoint(fullWid, (localBear + 90), localEnd));
            eb.geometry.coordinates[0].push(localEnd);
            edgeButs.push(eb);

            var startCorner = getPoint(fullWid * 0.3, (localBear + 90), getPoint(blockWid * 0.25, localBear, localEnd));
            var eb = JSON.parse(JSON.stringify(groupDef));
            eb.properties.rowPos = rowPos;
            eb.properties.id = EMPTY;
            eb.properties.CropLabel = EMPTY;
            eb.properties.Crop = EMPTY;
            eb.properties.overflow = true;
            eb.properties.Supplier = EMPTY;
            eb.properties.LeaseId = thisFeature.properties.LeaseId;
            eb.properties.LineId = thisFeature.properties.ParentId;
            eb.properties.UnitType = EMPTY;
            eb.properties.bagNo = rowPos;
            eb.properties.Grade = EMPTY;
            eb.properties.type = EMPTY;
            eb.properties.warehouseId = thisFeature.properties.warehouseId;
            eb.properties.Type = 'arrow';
            eb.geometry.type = "Polygon";
            eb.geometry.coordinates[0].push(startCorner);
            eb.geometry.coordinates[0].push(getPoint(fullWid * 0.4, (localBear + 90), startCorner));
            eb.geometry.coordinates[0].push(getPoint(fullWid * 0.5, (localBear + 90), getPoint(blockWid * 0.8, localBear, localEnd)));
            eb.geometry.coordinates[0].push(startCorner);
            edgeButs.push(eb);

        }
        var edgeButCol = { ...featCol };
        edgeButCol.features = edgeButs;
        setEdgeButtons(edgeButCol);
        featCol.features = list;
        return featCol;
    }
    const changeLayer = (currMap, desc, ll) => () => {
        //if (currMap) {
        //    var newDt = { ...leases() };
        //    for (var i = 0; i < newDt.features.length; i++) {
        //        if (newDt.features[i].properties.name === desc) {
        //            console.log(ll)
        //            newDt.features[i].geometry.coordinates[0].push([ll.lng,ll.lat]);
        //        }
        //    }
        //    console.log(newDt)
        //    currMap.getSource('leases').setData(newDt);
        //    currMap.getSource('leases').setData(newDt);
        //} else {
        //    console.log('No map passed')
        //}
    }
    const GPSWindow = (props) => {

        return <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                backgroundColor: 'white',
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                {props.lnglat.lat},{props.lnglat.lng}
            </div>
        </Box>
    }
    const InfoWindow = (props) => {
        var pickupDefault = 200;
        var putOutdefault = 250;
        var defaultPick = pickupDefault;

        var pickup = props.feature?.properties?.Crop !== EMPTY;
        if (!pickup) {
            defaultPick = putOutdefault;
        }
        return <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },

            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <IconButton aria-label="add" onClick={props.openHistoryModal(props.feature.properties.id)} style={{
                    position: 'absolute',
                    right: '1%',
                    top: '1%'
                }} >
                    <AccountTree />
                </IconButton>
                {checkAccess(accessPage, requiredUndoAccess) ? <IconButton aria-label="add" onClick={props.openUndoModal(props.feature.properties.id)} style={{
                    position: 'absolute',
                    left: '1%',
                    top: '1%'
                }} >
                    <ReplayIcon />
                </IconButton> : null}
                <Grid container spacing={1} mb={1}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h3"} style={{ textAlign: 'center' }}> {props.feature.properties.rowPos + 1}</Typography>
                    </Grid>
                    {pickup ? <React.Fragment><Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}> {props.feature.properties.CropLabel}</Typography>
                    </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant={"h6"} style={{ textAlign: 'center' }}> {props.feature.properties.Grade}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant={"h6"} style={{ textAlign: 'center' }}> {props.feature.properties.UnitType}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            {props.feature.properties.Average ? <Typography variant={"h6"} style={{ textAlign: 'center' }}> {displayDozFormat(Number(props.feature.properties.Average))}</Typography> : null}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant={"h6"} style={{ textAlign: 'center' }}> {dateFormat(new Date(props.feature.properties.DeploymentDate))}</Typography>
                        </Grid>
                        {props.feature.properties.Notes && (props.feature.properties.Notes !== 'Empty') && (props.feature.properties.Notes !== 'null') ? <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant={"h6"} style={{ textAlign: 'center' }}> {props.feature.properties.Notes}</Typography>
                        </Grid> : null}
                    </React.Fragment> : null}
                    <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                        <Button onClick={props.wholeGroup(props.currMap, props.feature)} variant="contained">{(pickup ? "Whole Group" : "Fill Space")}</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                        <Button onClick={props.pickUp(props.currMap, props.feature, defaultPick)} variant="contained">{(pickup ? "Pick Up " : "Deploy ") + defaultPick}</Button>
                    </Grid>
                </Grid>
            </Div>
        </Box>
    }
    const RowWindow = (props) => {
        return <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },

            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <Grid container spacing={1} mb={1}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h3"} style={{ textAlign: 'center' }}>Row: {props.feature.properties.LineNbr}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}> Total Units: {props.feature.properties.TotalUnits}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}> Available:  {props.feature.properties.Available}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}>Capacity:  {props.feature.properties.Capacity}</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}>Infrastucture:  {props.feature.properties.RowType}</Typography>
                    </Grid>

                </Grid>
            </Div>
        </Box>
    }
    const HAInfo = (props) => {
        return <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },

            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <Grid container spacing={1} mb={1}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}>{props.feature.properties.Conditions}</Typography>
                    </Grid>

                </Grid>
            </Div>
        </Box>
    }
    const handleClose = (currMap) => () => {
        setStartandEnd(currMap, null, null);
    }
    const stopDeploying = () => {
        setSavedGroup(null);
        setSavedUnits(null);
        var currSearch = window.location.search;
        var newSearch = currSearch.replace(/warehouseId=\d.*/, '');
        newSearch = newSearch.replace(/&GroupId=\d.*/, '');
        newSearch = newSearch.replace(/&units=\d.*/, '');
        navigate(newSearch);
    }

    //props.lineLoad
    //            props.leaseLoad
    //           props.otherLeaseLoad
    //            props.haLoad
    //            props.labelLoad 
    return (
        <div  >
            {!IsProd ? <DevRefresh refresh={refreshLines} /> : null}
            { (!isMobile && (currZoom < 15)) || (isMobile &&(currZoom < 13))? <SelectFarmLatLng loadFarm={loadFarm} currMap={map} aocFarms={aocFarms} /> : null}
            {((start && end) || (start && (start.properties?.Crop === EMPTY))) ? <SelectionWindow stopDeploying={stopDeploying} savedGroup={savedGroup} savedUnits={savedUnits} handleClose={handleClose(map)} bagOverflow={bagOverflow} correctValue={unitsValCorrect} moveBagsForTotal={moveBagsForTotal(map)} startFeature={start} endFeature={end} access={props.access} map={map} reloadWarehouseLines={reloadWarehouseLines} reloadLeaseLines={reloadLeaseLines} /> : ''}
            {!isMobile && currZoom >= 15 && !savedGroup && !savedUnits ? <LeaseDetails cropCols={cropCols} currMap={map} lines={rows} leases={dataLeases} renderedFeats={renderedFeats} cropCols={cropCols} gradeCols={grades} utCols={unitTypes} /> : null}
            <Legend isMobile={isMobile} currMap={map} renderedFeats={renderedFeats} cropCols={cropCols} gradeCols={grades} utCols={unitTypes} showOLs={showOLs} setShowOLs={setShowOLs} showHAs={showHAs} setShowHAs={setShowHAs} showGrade={showGrade} setShowGrade={setShowGrade} showDDs={showDepDate} setShowDDs={setShowDepDate} showGMs={showGMs} setShowGMs={setShowGMs} showUTs={showUnitType} setShowUTs={setShowUnitType} satImage={satImage} setSatImage={setSatImage} />
            {savedGroup && savedUnits ? <Deploying group={savedGroup} units={savedUnits} stopDeploying={stopDeploying} /> : null}
            {(!lineLoad || !leaseLoad || !otherLeaseLoad || !haLoad || !labelLoad) ? <Loading lineLoad={lineLoad} leaseLoad={leaseLoad} otherLeaseLoad={otherLeaseLoad} haLoad={haLoad} labelLoad={labelLoad} /> : null}
            <div ref={mapContainer} style={mapStyle} />
            <HistoryModal open={openHistory} setOpen={setOpenHistory} GroupId={selGroupId} />
            <UndoModal reload={reloadWarehouseLines} StillOnWater open={openUndo} setOpen={setOpenUndo} GroupId={selGroupId} cropCols={cropCols} startFeature={start} />
        </div>
    );
};


export default MapPage;

const Legend = (props) => {
    const [showLegend, setShowLegend] = React.useState(!props.isMobile);
    const [showSettings, setShowSettings] = React.useState(!props.isMobile);
    const [showDisplayOptions, setShowDisplayOptions] = React.useState(!props.isMobile);
    //map.setStyle('mapbox://styles/mapbox/' + layerId);
    var sidebar = {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: '#000',
        padding: '6px 12px',
        fontFamily: 'monospace',
        zIndex: '100',
        position: 'absolute',
        bottom: '30px',
        right: '0',
        margin: '12px',
        borderRadius: '4px',
        maxWidth: '320px',
    }
    React.useEffect(() => {
        setShowLegend(!props.isMobile);
        setShowSettings(!props.isMobile);
        setShowDisplayOptions(!props.isMobile);
    }, [props.isMobile]);

    //functionthat gives the intersection of props.gradeCols and the ones that are actually in the renderedFeats
    const filterCols = (renderedFeats, cols, key, isCropLabel) => {
        var selCols = [];
        for (var i = 0; i < renderedFeats.length; i++) {
            var currFeat = renderedFeats[i];
            var currKey = currFeat.properties?.[key];
            if (isCropLabel) {
                currKey = currFeat.properties.CropLabel
            }
            var col = cols.find(col => (col.description === currKey));
            //check if col is already in selCols
            if (col) {
                var found = selCols.find(selCol => (selCol.description === col.description));
                if (!found) {
                    selCols.push(col);

                }
            }
        }
        return selCols;
    }




    var colArr = [];
    if (props.showGrade) {
        colArr = filterCols(props.renderedFeats, props.gradeCols, 'Grade', false);
    } else if (props.showDDs) {

        //colArr = filterCols(props.renderedFeats, props.ddCols,'Date',false);
        //loop through renderedFeats and get unique depdats
        var depDates = [];
        for (var i = 0; i < props.renderedFeats.length; i++) {
            var currFeat = props.renderedFeats[i];
            var currDepDate = currFeat.properties?.DeploymentDate;
            if (currDepDate) {
                var found = depDates.find(depDate => (depDate === currDepDate));
                if (!found) {
                    depDates.push(currDepDate);
                }
            }
        }
        //sort depDates
        depDates.sort((a, b) => {
            var aDate = new Date(a);
            var bDate = new Date(b);
            return aDate - bDate;
        });

        //create colArr as a linear colour shade from red to green based on the number of depDates
        colArr = [];
        var existingMonths = [];
        for (var i = 0; i < depDates.length; i++) {
            var currDepDateMonth = depDates[i].substring(0, 7);
            if (!existingMonths.includes(currDepDateMonth)) {
                existingMonths.push(currDepDateMonth);
            }
        }
        if (existingMonths.length > 0) {//80 to 250
            var step = (170 / existingMonths.length);
            var currVal = 0;
            for (var i = 0; i < existingMonths.length; i++) {
                var currCol = {
                    description: existingMonths[i],
                    colour: 'hsl(' + (80 + currVal) + ',100%,50%)'
                }
                colArr.push(currCol);
                currVal += step;
            }
        }
        console.log(colArr);
        if (colArr.length > 0) {
            props.currMap.setPaintProperty('depdate_layer', 'fill-color', ['case',
                ...generateColCase(colArr, ["slice", ['get', 'DeploymentDate'], 0, 7]),
                'rgba(255,255,255,0)'
            ]);
        }

    } else if (props.showUTs) {
        colArr = filterCols(props.renderedFeats, props.utCols, 'UnitType', false);
    } else {
        colArr = filterCols(props.renderedFeats, props.cropCols, 'CropLabel', true);
    }
    //sort colarr by description
    colArr.sort((a, b) => {
        return a.description > b.description ? 1 : -1;
    });


    return (
        <div style={sidebar}>
            <Typography variant={"h6"} style={{ color: '#000' }} onClick={() => { setShowDisplayOptions(!showDisplayOptions) }}>Display Options</Typography>
            {showDisplayOptions ? <Grid container spacing={0} mb={0} style={{ width: '200px' }}>
                {colArr.length > 0 ? <React.Fragment>
                    <Grid item xs={12} >
                        <FormControlLabel
                            label={"Show Grade"}
                            control={
                                <Checkbox
                                    checked={props.showGrade}
                                    onChange={(event) => { props.setShowGrade(event.target.checked) }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={"Show Unit Type"}
                            control={
                                <Checkbox
                                    checked={props.showUTs}
                                    onChange={(event) => { props.setShowUTs(event.target.checked) }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={"Show Deployment Date"}
                            control={
                                <Checkbox
                                    checked={props.showDDs}
                                    onChange={(event) => { props.setShowDDs(event.target.checked) }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={"Show Group Markers"}
                            control={
                                <Checkbox
                                    checked={props.showGMs}
                                    onChange={(event) => { props.setShowGMs(event.target.checked) }}
                                />
                            }
                        />
                    </Grid>
                </React.Fragment> : null}
                <Grid item xs={12}>
                    <FormControlLabel
                        label={"Harvest Areas"}
                        control={
                            <Checkbox
                                checked={props.showHAs}
                                onChange={(event) => { props.setShowHAs(event.target.checked) }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label={"Other Leases"}
                        control={
                            <Checkbox
                                checked={props.showOLs}
                                onChange={(event) => { props.setShowOLs(event.target.checked) }}
                            />
                        }
                    />
                </Grid>
            </Grid>
                : null}
            {colArr.length > 0 ? <React.Fragment>
                <Typography variant={"h6"} style={{ color: '#000' }} onClick={() => { setShowLegend(!showLegend) }}>Legend</Typography>
                {showLegend ? <div style={{ maxHeight: '300px', overflow: 'auto' }} >
                    {colArr ? colArr.map((cc) => {
                        var inStyle = {
                            backgroundColor: cc.colour,
                            borderRadius: '4px',
                            margin: '3px 0px 3px 0px',
                            padding: '2px 3px 2px 3px',
                            color: textColorContrast(cc.colour, '#FFF', '#000')
                        }
                        return <div style={inStyle}>{cc.description}</div>
                    }) : null}
                </div > : null}
            </React.Fragment> : null}


            <Typography variant={"h6"} style={{ color: '#000' }} onClick={() => { setShowSettings(!showSettings) }}>Settings</Typography>
            {showSettings ? <FormControlLabel
                label={"Satellite"}
                control={
                    <Checkbox
                        checked={props.satImage}
                        onChange={(event) => { props.setSatImage(event.target.checked) }}
                    />
                }
            /> : null}

        </div>
    );
};
const tableStyle = {
    fontSize: '12px',
    padding: '1px',
    paddingRight: '10px',
    paddingLeft: '10px',
}

const LeaseDetails = (props) => {
    const [showTable, setShowTable] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(0);
    //map.setStyle('mapbox://styles/mapbox/' + layerId);
    const setIsOpenCounter = (diff) => {
        setIsOpen(isOpen + diff);
    }
    var sidebar = {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: '#000',
        padding: '6px 12px',
        //fontFamily: 'monospace',
        zIndex: '1000',
        position: 'absolute',
        bottom: '30px',
        right: '320px',
        margin: '12px',
        borderRadius: '4px',
        overflow: 'auto'
    }
    if (isOpen > 0) {
        sidebar.height = 'auto';
    } else {
        sidebar.maxHeight = '200px';
    }





    var leaseIds = [];
    for (var i = 0; i < props.renderedFeats.length; i++) {
        var feat = props.renderedFeats[i];
        if (!leaseIds.find((li) => { return li.id === feat.properties.LeaseId })) {
            leaseIds.push({ id: feat.properties.LeaseId, name: feat.properties.LeaseName, no: feat.properties.LeaseNo });
        }
    }
    var leases = [];
    for (var i = 0; i < props.leases.length; i++) {
        for (var j = 0; j < leaseIds.length; j++) {
            if (Number(props.leases[i].id) === Number(leaseIds[j].id)) {
                leases.push({ ...props.leases[i] });
            }
        }
    }
    for (var i = 0; i < leases.length; i++) {
        leases[i]['groups'] = [];
        for (var j = 0; j < props.lines.features.length; j++) {
            if (Number(props.lines.features[j].properties.LeaseId) === Number(leases[i].id)) {
                leases[i]['groups'].push(props.lines.features[j]);
            }
        }
        //get totals of all groups that have the same grade and croplabel and unittype
        var groups = leases[i].groups;
        var groupTotals = [];
        var used = 0;
        for (var j = 0; j < groups.length; j++) {
            var currGroup = groups[j].properties;
            used += Number(currGroup.TotalUnits);
            var groupIndex = groupTotals.findIndex((g) => g.grade === currGroup.Grade && g.cropLabel === currGroup.CropLabel && g.unitType === currGroup.UnitType);
            if (groupIndex < 0) {
                groupTotals.push({
                    grade: currGroup.Grade,
                    cropLabel: currGroup.CropLabel,
                    unitType: currGroup.UnitType,
                    total: Number(currGroup.TotalUnits) * Number(currGroup.Average),
                    totalUnits: Number(currGroup.TotalUnits)
                });
            } else {
                groupTotals[groupIndex].total += Number(currGroup.TotalUnits) * Number(currGroup.Average);
                groupTotals[groupIndex].totalUnits += Number(currGroup.TotalUnits);
            }
        }
        leases[i].available = 0;
        leases[i].used = used;
        leases[i].groupTotals = groupTotals.sort((a, b) => {
            //sort by cropLabel, grade, unitType
            if (a.cropLabel !== b.cropLabel) {
                return a.cropLabel > b.cropLabel ? 1 : -1;
            } else {
                if (a.grade !== b.grade) {
                    return a.grade?.id > b.grade?.id ? 1 : -1;

                } else {
                    return a.unitType?.description > b.unitType?.description ? 1 : -1;
                }
            }


        });
    }

    if (leases.length > 0) {
        if (showTable) {
            return (
                <div style={sidebar}>
                    <Typography variant={"h6"} style={{ color: '#000' }} onClick={() => { setShowTable(!showTable) }}>Lease Details</Typography>
                    <TableContainer style={tableStyle} >
                        <Table style={tableStyle}>
                            <TableHead style={tableStyle} >
                                <TableCell style={tableStyle}>
                                </TableCell>
                                <TableCell style={tableStyle}>
                                    Name
                                </TableCell>
                                <TableCell style={tableStyle} >
                                    Number
                                </TableCell>
                            </TableHead>
                            {leases?.sort((a, b) => { return a.lease?.name > b.lease?.name ? 1 : -1 }).map((l) => {

                                return <Row setIsOpen={setIsOpenCounter} lease={l} total={l.total} cropCols={props.cropCols} />
                            })}

                        </Table>
                    </TableContainer>
                </div>
            );
        } else {
            return <Button style={{ ...sidebar, fontSize: '80%' }} onClick={() => { setShowTable(true) }}>Show Lease Details</Button>
        }
    } else {
        return null;
    }
};

function Row(props) {
    const { lease, total } = props;
    const [open, setOpen] = React.useState(false);
    var totalUnits = 0;
    var totalOysters = 0;
    const dropDown = () => {
        setOpen(!open);
        props.setIsOpen(!open ? 1 : -1);
    }

    return (
        <React.Fragment>
            <TableRow onClick={dropDown} sx={{ '& > *': { borderBottom: 'unset' }, ...tableStyle }}>
                <TableCell style={tableStyle}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={tableStyle}>
                    {lease?.name}
                </TableCell>
                <TableCell style={tableStyle}>{lease?.number}</TableCell>
            </TableRow>
            <TableRow style={tableStyle}>
                <TableCell style={tableStyle} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            <Table size="small" aria-label="purchases" style={tableStyle}>
                                <TableHead style={tableStyle}>
                                    <TableRow style={tableStyle}>
                                        <TableCell style={tableStyle}>Crop</TableCell>
                                        <TableCell style={tableStyle}>Grade</TableCell>
                                        <TableCell style={tableStyle}>Unit Type</TableCell>
                                        <TableCell style={tableStyle} align="right">Units</TableCell>
                                        <TableCell style={tableStyle} align="right">Total Oysters</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={tableStyle}>
                                    {lease?.groupTotals?.map((gt, k) => {
                                        totalUnits += gt.totalUnits;
                                        totalOysters += gt.total;
                                        var cc = props.cropCols.find((c) => c.crop === gt.cropLabel);

                                        return <TableRow key={k} style={tableStyle}>
                                            <TableCell component="th" scope="row" style={{
                                                ...tableStyle, backgroundColor: cc?.colour,
                                                color: textColorContrast(cc?.colour, '#FFF', '#000')
                                            }}>
                                                {gt.cropLabel}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={tableStyle}>
                                                {gt.grade}
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={tableStyle}>
                                                {gt.unitType}
                                            </TableCell>
                                            <TableCell align="right" style={tableStyle}>{gt.totalUnits}</TableCell>
                                            <TableCell align="right" style={tableStyle}>{displayDozFormat(gt.total)}</TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow hover style={tableStyle} >
                                        <TableCell style={tableStyle}>
                                            <Typography sx={{ fontWeight: 'bold' }} mb={0}>Total</Typography>
                                        </TableCell>
                                        <TableCell style={tableStyle}>
                                        </TableCell>
                                        <TableCell style={tableStyle}>
                                        </TableCell>
                                        <TableCell style={tableStyle}>
                                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} mb={0}>{totalUnits}</Typography>
                                        </TableCell>
                                        <TableCell style={tableStyle}>
                                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} mb={0}>{displayDozFormat(totalOysters)}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const Deploying = (props) => {
    const [showDisplayOptions, setShowDisplayOptions] = React.useState(true);

    var topBar = {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: '#000',
        padding: '6px 12px',
        fontFamily: 'monospace',
        zIndex: '100',
        position: 'absolute',
        top: '90px',
        right: '0',
        margin: '12px',
        borderRadius: '4px'
    }
    const exitIcon = {
        position: 'absolute',
        right: '0%',
        top: '0%',
    }
    return (
        <div style={topBar}>
            <IconButton style={exitIcon} aria-label="close" onClick={props.stopDeploying}>
                <CloseIcon />
            </IconButton>
            <Grid container spacing={1} mb={1}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant={"h5"} style={{ color: '#000' }} >Deploying</Typography>
                </Grid>
                <Grid item xs={9}  >
                    <Typography variant={"h6"} style={{ color: '#000' }} >{props.group?.cropLabel}</Typography>
                    <Typography variant={"h6"} style={{ color: '#000' }} >{props.group?.grade?.description}</Typography>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'center' }}>
                    <Typography variant={"h6"} style={{ color: '#000' }} >{props.units}</Typography>
                    <Typography variant={"h6"} style={{ color: '#000' }} >{props.group?.unitType?.description}</Typography>

                </Grid>

            </Grid>
        </div>
    );
};

const SelectFarmLatLng = (props) => {

    var topBar = {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: '#000',
        padding: '6px 12px',
        fontFamily: 'monospace',
        zIndex: '100',
        position: 'absolute',
        bottom: '30px',
        margin: '12px',
        borderRadius: '4px',
        width: '150px'
    }
    const flyTo = (lnglat, warehouseObj) => () => {
        props.currMap.flyTo
            ({
                center: lnglat,
                zoom: 13.5
            })
        props.loadFarm(warehouseObj);
    }
    return (
        <div style={topBar}>
            <Grid container spacing={1} mb={1}>
                {props.aocFarms.sort((a, b) => { return a.warehouseObject?.lat < b.warehouseObject?.lat ? 1 : -1 })?.map((fm) => {
                    return <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Button onClick={flyTo([fm.warehouseObject?.lng, fm.warehouseObject?.lat], fm.warehouseObject)} >
                            {fm.estuaries}
                        </Button>
                    </Grid>
                })}
            </Grid>
        </div>
    );
};
const DevRefresh = (props) => {
    var topBar = {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: '#000',
        padding: '6px 12px',
        fontFamily: 'monospace',
        zIndex: '100',
        position: 'absolute',
        top: '90px',
        right: '0',
        margin: '12px',
        borderRadius: '4px'
    }
    return <IconButton onClick={props.refresh} style={topBar}>
        <RefreshIcon />
    </IconButton>
}
const Loading = (props) => {
    const [showDisplayOptions, setShowDisplayOptions] = React.useState(true);

    var topBar = {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: '#000',
        padding: '6px 12px',
        fontFamily: 'monospace',
        zIndex: '100',
        position: 'absolute',
        top: '40%',
        left: '40%',
        margin: '12px',
        borderRadius: '4px'
    }
    const exitIcon = {
        position: 'absolute',
        right: '0%',
        top: '0%',
    }
    return (
        <div style={topBar}>
            <IconButton style={exitIcon} aria-label="close" onClick={props.closeLoadWindow}>
                <CloseIcon />
            </IconButton>
            <Grid container spacing={1} mb={1}>

                <Grid item xs={12} >
                    <Typography variant={"h5"} style={{ color: '#000' }} >Lines {(props.lineLoad ? 'Loaded' : <CircularProgress size={15} />)}</Typography>
                    <Typography variant={"h5"} style={{ color: '#000' }} >Leases {(props.leaseLoad ? 'Loaded' : <CircularProgress size={15} />)}</Typography>
                    <Typography variant={"h5"} style={{ color: '#000' }} >Other Leases {(props.otherLeaseLoad ? 'Loaded' : <CircularProgress size={15} />)}</Typography>
                    <Typography variant={"h5"} style={{ color: '#000' }} >Harvest Areas {(props.haLoad ? 'Loaded' : <CircularProgress size={15} />)}</Typography>
                    <Typography variant={"h5"} style={{ color: '#000' }} >Labels {(props.labelLoad ? 'Loaded' : <CircularProgress size={15} />)}</Typography>

                </Grid>

            </Grid>
        </div>
    );
};

// Converts from degrees to radians.
function toRadians(degrees) {
    return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
function toDegrees(radians) {
    return radians * 180 / Math.PI;
}


function bearing(start, dest) {
    const startLat = toRadians(start[1]);
    const startLng = toRadians(start[0]);
    const destLat = toRadians(dest[1]);
    const destLng = toRadians(dest[0]);
    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}

const distance = (start, end) => {
    const lon1 = start[0];
    const lat1 = start[1];
    const lon2 = end[0];
    const lat2 = end[1];
    const R = 6371e3; // metres
    const phi1 = lat1 * Math.PI / 180; // phi, lam in radians
    const phi2 = lat2 * Math.PI / 180;
    const deltaphi = (lat2 - lat1) * Math.PI / 180;
    const deltalam = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(deltaphi / 2) * Math.sin(deltaphi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltalam / 2) * Math.sin(deltalam / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
}

const getPoint = (distance, bearing, center) => {

    let th = Number(distance) / 6371e3;
    let thet = toRadians(Number(bearing));

    let phi1 = toRadians(center[1]);
    let lam1 = toRadians(center[0]);

    let sinphi1 = Math.sin(phi1), cosphi1 = Math.cos(phi1);
    let sinth = Math.sin(th), costh = Math.cos(th);
    let sinthet = Math.sin(thet), costhet = Math.cos(thet);

    let sinphi2 = sinphi1 * costh + cosphi1 * sinth * costhet;
    let phi2 = Math.asin(sinphi2);
    let y = sinthet * sinth * cosphi1;
    let x = costh - sinphi1 * sinphi2;
    let lam2 = lam1 + Math.atan2(y, x);

    return [(toDegrees(lam2) + 540) % 360 - 180, toDegrees(phi2)];
};