import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import { getAPIDataReturn, getAPIDataParamsReturn, postApi, getAPIData, getAPIDataParams } from "../../../utils/apiFunction";
import { dateFormat } from "../../../utils/dateFunctions";
import { getOysterGrades,buildSpeciesList } from "../../../definitions/oysters";
import DataTableStandard from "../../../components/DataTableStandard";
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { dateSort } from '../../../utils/sortFunctions';
import Typography from '@mui/material/Typography';
import LiveInventoryReportTable from '../Tables/LiveInventoryReportTable';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { number } from 'prop-types';
const types = [{ 'name': "Graded", 'value': "Graded" }, { 'name': "WIP", 'value': "WIP" }, { 'name': "UnGraded", 'value': "UnGraded" }, { 'name': "Frozen", 'value': "Frozen" }, { 'name': "Hide Frozen", 'value': "hideFrozen" }];
const products = [{ 'name': "Appellation", 'value': "Appellation" }, { 'name': "AOC", 'value': "AOC" }, { 'name': "Oystersmiths", 'value': "Oystersmiths" }];
const containerList = [{ 'name': "Bagged", 'value': "Bagged" }, { 'name': "Boxed", 'value': "Boxed" }];
const trays = [{ 'name': "1 Doz", 'value': "HS1D" }, { 'name': "2.5 Doz", 'value': "HS2H" }];
const SelectInventoryForm = (props) => {
    const hasStarted = !(props.selectedLine?.processingStatus === 'Not started');
    const [completed, setCompleted] = React.useState(props.completed);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [type, setType] = React.useState((props.typeDefault !== undefined) ? types[props.typeDefault].value : null);
    const [product, setProduct] = React.useState((props.productDefault !== undefined) ? products[props.productDefault].value : null);
    const [container, setContainer] = React.useState((props.containerDefault !== undefined) ? containerList[props.containerDefault].value : null);
    const [species, setSpecies] = React.useState(null);
    const [grade, setGrade] = React.useState( null);
    const [grades, setGrades] = React.useState([]);
    const [speciesList, setSpeciesList] = React.useState([]);
    const [myobDiff, setMyobDiff] = React.useState(null);
    const [stockItems, setStockItems] = React.useState(null);
    var recData = props.data ? props.data : {};
    const Swal = useSwalWrapper();
    const [inventory, setInventory] = React.useState(null);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredMasterAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MASTER };
    const requiredSpecialAcess = { key: ACCESS_SECTIONS.SPECIALACCESS, value: ACCESS.EDIT };
    const [traySize, setTraySize] = React.useState((props.traySizeDefault !== undefined) ? trays[props.traySizeDefault].value : null);


    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(data);
            if(props.gradeDefault!==undefined){
                setGrade(data.find((dt)=>props.gradeDefault.id===dt.id).Grade);
            }
            var specs = buildSpeciesList(data);
            setSpeciesList(specs);
            if(props.speciesDefault!==undefined){
                setSpecies(specs[props.speciesDefault].name);
            }
        });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        setInventory(null);
        getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: props.warehouseId, IncludeMYOB: !!myobDiff }).then((data) => {
            setLoading(false);
            setInventory(data);
        });
    }, [reload, props.reload, props.warehouseId, myobDiff]);
    React.useEffect(() => {
        getAPIData('/StockItems/Get', setStockItems);
    }, [reload]);
    const remSS = (name) => {
        if (name) {
            return name.replace('Single Seed', '');
        } else {
            return '';
        }
    }




    const reloadFunc = () => {
        setReload(!reload);
    }
    const listFilter = (data) => {
        return data.filter((dtS) => {
            var dt = dtS.stockItem;
            var remove = false;
            if (type) {
                if ((type === 'Graded') && !dt.isProcessed) {
                    remove = true;
                } else if ((type === 'UnGraded') && dt.isProcessed) {
                    remove = true;
                } else if ((type === 'WIP')) {
                    if (!(dt.description.includes('WIP'))) {
                        remove = true;
                    }
                } else if ((type === 'Frozen') && !dt.isFrozen) {
                    remove = true;
                } 
            }
            if (traySize) {
                if (!dt.myobid.includes(traySize)) {
                    remove = true;
                }
            }
            if (product) {
                if ((product === 'Appellation') && !dt.isAppelation) {
                    remove = true;
                } else if ((product === 'AOC') && !dt.isAOC) {
                    remove = true;
                } else if ((product === 'Oystersmiths') && !dt.isOysterSmiths) {
                    remove = true;
                }
            }
            if (container) {
                if ((container === 'Bagged') && !dt.isBagged) {
                    remove = true;
                } else if ((container === 'Boxed') && !dt.isBoxed) {
                    remove = true;
                }
            }
            if (species) {
                if (species !== dt.species?.description) {
                    remove = true;
                }
            }
            if (grade) {
                if (grade !== dt.grade?.description) {
                    remove = true;
                }
            }
            if (myobDiff) {
                if ((dtS.portalQuantity + dtS.inFullfillment) === dtS.myobQuantity) {
                    remove = true;
                }
            }
            if (props.hideHalfshell) {
                if (dt.isHalfshell) {
                    remove = true;
                }
            }
            if (props.hideFrozen||(type==="hideFrozen")) {
                if (dt.isFrozen) {
                    remove = true;
                }
            }
            if (props.hideLive) {
                if (!dt.isFrozen && !dt.isHalfshell) {
                    remove = true;
                }
            }
            return !remove;
        });
    }
    //memoize the data so it doesn't re-render every time
    const mapStock = useMemo(() => {
        if (inventory && stockItems) {
            return (inventory.map((inv) => {
                var stock = stockItems.find((s) => s.id === inv.stockItemId);
                var newInv = { ...inv };
                newInv.stockItem = stock;
                newInv.stockItemName = remSS(stock.description);
                newInv.estuary = inv.estuary?.description;
                newInv.expiryDateFormat = dateFormat(inv.expiryDate);
                return newInv;
            }))
        } else {
            return null
        };
    }, [inventory, stockItems]);
    const invStock = useMemo(() => {
        if (mapStock) {
            return listFilter(mapStock);
        } else {
            return null
        };
    }, [inventory, stockItems, type, product, traySize, container, grade, species, myobDiff]);
    React.useEffect(() => {
        var cols = [];

        cols.push({
            name: 'expiryDateFormat',
            label: 'Use by Date',
            options: {
                sortCompare: dateSort,
                sortDescFirst: true,
                filterType: 'multiselect'
            }
            //options: props.dayToggle ? { filterList: daySelection !== null ? [dateFormat(generateDates(props.startDate, props.endDate)[daySelection])] : [] } : {}
        });


        cols.push({
            name: 'lotSerialNbr',
            label: 'Lot Serial Nbr',

        });


        cols.push({
            name: 'stockItemName',
            label: 'StockItem',
        });
        cols.push({
            name: 'portalQuantity',
            label: 'Quantity (Doz)',

        });
        if (myobDiff) {
            cols.push({
                name: 'received',
                label: 'Received (Doz)',
            });
            cols.push({
                name: 'inFullfillment',
                label: 'Fulfilment (Doz)',
            });
            cols.push({
                name: 'myobOnHand',
                label: 'MYOB On Hand (Doz)',
            });
            cols.push({
                name: 'myobQuantity',
                label: 'MYOB Quantity (Doz)',
            });
        }
        cols.push({
            name: 'estuary',
            label: 'Estuary',

        });
        setColumns(cols);
    }, [invStock])
    const validate = () => () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)



        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const submit = async () => {
        //var allOk = false;
        //var respArr = [];
        //setLoading(true);
        //if (hasStarted) {
        //    //update input
        //    if (editInput) {
        //        const params = {
        //            ProcessRunId: processRunId,
        //            ProductionLineId: equipment.id,
        //            Amount: oystersIn[0].quantity,
        //            Bins: oystersIn[0].bins,
        //            Bags: oystersIn[0].bags,
        //            Staff: workers,
        //            StaffMemberId: staffMember.id
        //        }
        //        respArr.push(await postApi('/Processing/UpdateProcess', params));
        //    }
        //}


    }
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '600px',
        responsive: 'standard',
        rowsPerPage: 10,
        onRowClick: props.onClick && (!myobDiff) ? props.onClick(invStock) : (() => { }),
        searchOpen: true

    };
    const selectType = (event, newType) => {
        if (newType !== null) {
            setType(newType);
        }
    };

    if (props.selectedList) {
        options.setRowProps = (row, dataIndex, rowIndex) => {
            for (var i = 0; i < props.selectedList.length; i++) {
                if ((invStock[dataIndex].lotSerialNbr === props.selectedList[i].lotSerialNbr) && (invStock[dataIndex].expiryDate === props.selectedList[i].expiryDate) && (invStock[dataIndex].stockItemId === props.selectedList[i].stockItemId)) {
                    return {
                        style: { background: 'rgba(152, 251, 152,0.3)' }
                    };
                }
            }

        }
    }
    return (
        <Div>
            <span style={{ paddingRight: '15px' }} >
                <ButtonGroup state={type} setState={setType} values={types} clearStates={[setProduct, setContainer]} hideValues={[(props.hideHalfshell ? { 'name': "WIP", 'value': "WIP" } : null), (props.hideFrozen ? { 'name': "Frozen", 'value': "Frozen" } : null), (!props.hideFrozenButton ? { 'name': "Hide Frozen", 'value': "hideFrozen" } : null)]} />
            </span>
            {(type === 'WIP') ? <span style={{ paddingRight: '15px' }} >
                <ButtonGroup state={traySize} setState={setTraySize} values={trays} />
            </span> : null}
            {!type || (type === 'Graded') ? <React.Fragment> <span style={{ paddingRight: '15px' }} >
                <ButtonGroup state={product} setState={setProduct} values={products} clearStates={[setContainer]} />
            </span>
                <span style={{ paddingRight: '15px' }} >
                    <ButtonGroup state={container} setState={setContainer} values={containerList} clearStates={[]} />
                </span>
            </React.Fragment> : null}
            {!props.hideSpecies ? <span style={{ paddingRight: '15px' }} >
                <ButtonGroup state={species} setState={setSpecies} values={speciesList?.map(sl => { return { name: sl.name, value: sl.name } })} clearStates={[setGrade]} />
            </span> : null}
            {species ? <span style={{ paddingRight: '15px' }} >
                <ButtonGroup state={grade} setState={setGrade} values={grades?.sort((a,b)=>{return a.order-b.order}).filter((sl) => (species === sl.Species)).map(sl => { return { name: sl.Grade, value: sl.Grade } })} />
            </span> : null}
            {(checkAccess(props.access, requiredMasterAccess) || checkAccess(props.access, requiredSpecialAcess)) ? <span style={{ paddingRight: '15px' }} >
                <ButtonGroup state={myobDiff} setState={setMyobDiff} values={[{ value: 'Show MYOB Diff', name: 'Show MYOB Diff' }]} />
            </span> : null}
            {props.reportTable?<LiveInventoryReportTable invStock={invStock} reloadFunc={reloadFunc} loading={loading} shrinkTable={props.shrinkTable} />:null}
            <DataTableStandard
                data={invStock ? invStock : []}
                title={"Inventory"}
                columns={columns}
                options={options}
                returnSearch={!loading}
                reload={reloadFunc}
                shrink={props.shrinkTable}
            />
        </Div>
    );
};

const ButtonGroup = ({ state, setState, values, clearStates, hideValues }) => {
    return <ToggleButtonGroup
        value={state}
        color="primary"
        exclusive
        onChange={(el, val) => { clearStates?.map((cs) => { cs(null) }); setState(val) }}
        aria-label="text alignment">
        {values.filter((a) => {
            if (hideValues) {
                return !hideValues.find((b) => b && (b.value === a.value));
            } else {
                return true;
            }
        }).map((v) => {
            return <ToggleButton value={v.value} aria-label="left aligned">
                {v.name}
            </ToggleButton>
        })}

    </ToggleButtonGroup>
}

export default SelectInventoryForm;


