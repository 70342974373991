import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Grid from "@mui/material/Grid";
import FormModal from '../../components/FormModal';
import NewBatch from './Forms/NewBatch';
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, tomorrow, shiftMonth, dateFormat, textfieldDateFormat } from "../../utils/dateFunctions";
import { getAPIData, getAPIDataParamsReturn, getAPIDataParams } from "../../utils/apiFunction";
import { checkAccess } from '../../utils/roleFunctions';
import { DownloadButton, convertArrayToCSV, convertToCSV } from '../../utils/downloadUtils';
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import OnShoreDisplay from './components/OnShoreDisplay';
import SelectAOCFarmWarehouse from '../../components/SelectAOCFarmWarehouse';
import { CircularProgress } from '@mui/material';
import DateSelector from "../../shared/DateSelector";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from '@mui/icons-material/GetApp';
import { fi } from 'date-fns/locale';



const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';

var keyCodes = {
    SHOALHAVEN: 'SH',
    BERMAGUI: 'BM',
    NAROOMA: 'NW',
    WAPENGO: 'WP',
    MERIMBULA: 'MB',
    WALLISLAKE: 'WL',
    HASTINGS: 'HA'
}

var myobCodes = [{
    id: 43,
    name: 'Seedspat'
}, {
    id: 3,
    name: 'Growout'
}]

const DataDownloads = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const requiredEditAccess = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    const [forceTask, setForceTask] = React.useState(null);

    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }

    return (<Grid container spacing={4} mb={1} >
        <Grid item xs={12} >
            <StockList />

        </Grid>
        <Grid item xs={12} >
            <SeedSpatToGrowout />

        </Grid>
        <Grid item xs={12} >
            <AllGrading />

        </Grid>
        <Grid item xs={12} >
            <PurchaseResult />

        </Grid>
    </Grid>


    );
};
const StockList = (props) => {
    const [data, setData] = React.useState(null);
    const [start, setStart] = React.useState(false);
    React.useEffect(() => {
        if (start) {
            getAPIData('/Farms/Groups', (setData));
        }
    }, [start]);

    const convertData = (data) => {
        if (data) {
            var newData = data.map((item) => {
                var gradeGroup = myobCodes.find((mc) => { return mc.id === item.grade.myobGrade });

                return {
                    "Farm": item.warehouse.myobid,
                    "Crop": item.cropLabel,
                    "Grade": item.grade.description,
                    "UnitType": item.unitType.description,
                    "TotalUnits": item.totalUnits,
                    "OysterPerUnit": item.average,
                    "Location": item.location ? item.location?.line?.lease?.name : 'On Shore',
                    "Row": item.location ? item.location?.line?.lineNbr : '-',
                    "Position": item.location ? (item.location?.start + '>' + item.location?.end) : '-',
                    "DeployedDate": item.location ? dateFormat(item.location.deployed) : '',
                    "Batch": keyCodes[item.warehouse?.myobid] + '-' + item.cropLabel.substring(0, 8),
                    "Oysters": item.totalUnits * item.average,
                    "Group": gradeGroup ? gradeGroup.name : 'Growout',
                }
            });
            return (newData);
        } else {
            return null
        }
    }


    return <JumboCardQuick
        title={<span>Oyster Mate Stock List<div style={{ position: 'absolute', top: '2%', right: '1%' }}><DownloadButton start={start} setStart={setStart} data={convertData(data)} /></div></span>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >
    </JumboCardQuick>

}

const SeedSpatToGrowout = (props) => {
    const [data, setData] = React.useState(null);
    const [start, setStart] = React.useState(false);
    React.useEffect(() => {
        if (start) {
            var now = new Date();
            now.setDate(15);
            var endLastMonth = new Date(now);
            var nextDate = new Date(endLastMonth);
            var prmArr = [];
            console.log(nextDate.getMonth(), nextDate)
            var first = 0;
            while ((nextDate.getMonth() !== 5) || (first === 0)) {
                prmArr.push(getAPIDataParamsReturn('/Farms/Groups/SeedSpatToGrowOut', { Date: textfieldDateFormat(nextDate) }));
                nextDate = shiftMonth(nextDate, -1);
                first++;
            }
            Promise.all(prmArr).then((values) => {
                var newData = [];
                values.forEach((value) => {
                    if (value) {
                        newData = newData.concat(value);
                    }
                });
                setData(newData);
            });
        }
    }, [start]);

    const convertData = (data) => {
        if (data) {
            var newData = data.map((item) => {
                var gradeGroup = myobCodes.find((mc) => { return mc.id === item.grade.myobGrade });

                return {
                    "Farm": item.warehouse.myobid,
                    "Crop": item.cropLabel,
                    "TotalUnits": item.totalUnits,
                    "OysterPerUnit": item.average,
                    "Batch": keyCodes[item.warehouse?.myobid] + '-' + item.cropLabel.substring(0, 8),
                    "Oysters": item.totalUnits * item.average,
                    "Group": gradeGroup ? gradeGroup.name : 'Growout',
                    "DateGraded": dateFormat(item.created)
                }
            });
            return newData.sort((a, b) => a.Farm > b.Farm ? 1 : -1);
        } else {
            return null
        }
    }


    return <JumboCardQuick
        title={<span>Seedspat -> Growout <div style={{ position: 'absolute', top: '2%', right: '1%' }}><DownloadButton start={start} setStart={setStart} data={convertData(data)} /></div></span>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >
    </JumboCardQuick>

}

const AllGrading = (props) => {
    const [data, setData] = React.useState(null);
    const [start, setStart] = React.useState(false);
    React.useEffect(() => {
        if (start) {
            var now = new Date();
            now.setDate(15);
            var endLastMonth = new Date(now);
            var nextDate = new Date(endLastMonth);
            var prmArr = [];
            while (nextDate.getMonth() !== 5) {
                prmArr.push(getAPIDataParamsReturn('/Farms/Groups/AllGrading', { Date: textfieldDateFormat(nextDate) }));
                nextDate = shiftMonth(nextDate, -1);
            }
            Promise.all(prmArr).then((values) => {
                var newData = [];
                values.forEach((value) => {
                    if (value) {
                        newData = newData.concat(value);
                    }
                });
                setData(newData);
            });
        }

    }, [start]);

    const convertData = (data) => {
        if (data) {
            var newData = data.map((item) => {
                var gradeGroup = myobCodes.find((mc) => { return mc.id === item.grade.myobGrade });

                return {
                    "Farm": item.warehouse.myobid,
                    "Crop": item.cropLabel,
                    "TotalUnits": item.totalUnits,
                    "OysterPerUnit": item.average,
                    "Batch": keyCodes[item.warehouse?.myobid] + '-' + item.cropLabel.substring(0, 8),
                    "Oysters": item.totalUnits * item.average,
                    "Grade": item.grade.description,
                    "DateGraded": dateFormat(item.created)
                }
            });
            return newData.sort((a, b) => a.Farm > b.Farm ? 1 : -1);
        } else {
            return null
        }
    }


    return <JumboCardQuick
        title={<span>All Oysters Graded<div style={{ position: 'absolute', top: '2%', right: '1%' }}><DownloadButton start={start} setStart={setStart} data={convertData(data)} /></div></span>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >
    </JumboCardQuick>

}
const PurchaseResult = (props) => {
    const [data, setData] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(tomorrow(endOfWeek(new Date())));
    const [start, setStart] = React.useState(false);
    React.useEffect(() => {
        if (start) {
            setData(null);
            getAPIDataParams('/Processing/LiveProcessingReport', setData, { StartDate: textfieldDateFormat(startDate), EndDate: textfieldDateFormat(endDate) })
        }
    }, [startDate, endDate,start]);

    const convertData = (data) => {
        if (data) {
            var newData = data.map((item) => {
                return {
                    "Name": item.name,
                    "Receival Date": dateFormat(item.receivalDate),
                    "Purchase Order Id": item.purchaseOrderId,
                    "Received CO": item.recCO,
                    "Received BO": item.recBO,
                    "Received BI": item.recBI,
                    "Received PL": item.recPL,
                    "Counted CO": item.ctdCO,
                    "Counted BO": item.ctdBO,
                    "Counted BI": item.ctdBI,
                    "Counted PL": item.ctdPL,
                    "Received Total": item.rec,
                    "Counted Total": item.ctd,
                    "Purchase Receipt": item.purchaseReceipt,
                    "Counted Price": item.ctdPrice,
                    "Price CO": item.coPr,
                    "Price BO": item.boPr,
                    "Price BI": item.biPr,
                    "Price PL": item.plPr,
                    "External": item.external,
                }
            });
            return newData;
        } else {
            return null
        }
    }
    const handleClose = () => {
        setOpen(false)
    };

    return <JumboCardQuick
        title={<span>Purchase Results <div style={{ position: 'absolute', top: '2%', right: '1%' }}>
            <Button variant={'contained'} style={{ marginTop: '10px' }} onClick={() => (setOpen(true))}>Download</Button>

        </div></span>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >
        <FormModal open={open} handleClose={handleClose} title={"Select Date Range"} noWidth >
            <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
            <DownloadButton  start={start} setStart={setStart}  data={convertData(data)}  />
        </FormModal >
    </JumboCardQuick>

}


export default DataDownloads;


