import React from 'react';
import { TableContainer, Table, TableBody, TableCell, TableRow, TableHead, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {getOysterGrades,gradeSort} from "../../../definitions/oysters";
import MUIDataTable from "mui-datatables";
import { useJumboTheme } from "@jumbo/hooks";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { getAPIDataParamsReturn } from "../../../utils/apiFunction";

const Summary = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [grades, setGrades] = React.useState([]);
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),
        setRowProps : (row => {
            if (row[3] === ("Completed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (row[3] === ("In Progress")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (row[3] === ("Approval Needed")) {
                return {
                    style: { background: theme.palette.oysterProducts.shipping }
                };
            } 
        })
    };
    var columns = [
        {
            name: 'dateFormat',
            label: 'Date'
        },
        {
            name: 'warehouseName',
            label: 'Warehouse'
        },
        {
            name: 'staffMemberName',
            label: 'Staff Member'
        },
        {
            name: 'status',
            label: 'Status'
        },
        {
            name: 'approvedBy',
            label: 'AprovedBy'
        }];
    var oysterCols = grades?.map((og) => {
        return {
            name: og.abv.toLowerCase(),
            label: og.abv,
            options: {
                filter: false
            }
        }
    })
    columns = [...columns, ...oysterCols];

    React.useEffect(() => {
        setProcurement([]);
        if (props.warehouseId&&grades.length>0) {
            getProcurementData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
        }
    }, [props.startDate, props.endDate, reload, props.warehouseId,props.reload,grades]);
   
    var getProcurementData = async (startDate, endDate) => {
        const data = await getAPIDataParamsReturn('/Inventory/GetStocktakes', { startDate: (startDate), endDate: (endDate),WarehouseId:props.warehouseId });
        const sortData = data.sort((a, b) => {
            return a.date > b.date ? -1 : 1;
        });
        const organiseData = sortData.map((dt) => {
            var newO = { ...dt };
            newO.dateFormat = dateFormat(dt.date);
            newO.warehouseName = dt.warehouse?.description;
            newO.staffMemberName = (dt.staffMember?.firstname) + " " + (dt.staffMember?.surname);
            grades.map((og) => {
                newO[og.abv.toLowerCase()] = dt.stocktakeLines.filter((sti) => { return sti.stockItem.gradeId == og.id }).reduce((p, c) => {return p +c.newQuantity },0);
            })
            newO.status = dt.isComplete ? (dt.syncedToMYOB||(dt.approvedBy !== null )? "Completed" :'Approval Needed') : "In Progress";
            return newO;
        })
        setProcurement(organiseData);
    }
    return (

        <DataTableStandard
            title={"Stocktakes"}
            data={procurement}
            columns={columns}
            options={options}
            reload={reloadFunc}
        />

    );
};

export default Summary;
