import React from 'react';
import { Select, InputLabel, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { shiftHours,startOfFinancialYear,endOfFinancialYear, startOfMonth, endOfMonth, startOfYear, endOfYear, endOfWeek, startOfWeek,tomorrow } from "../utils/dateFunctions";
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DatePickerOutline from './DatePicker';
import FormControl from "@mui/material/FormControl";
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
const DateSelector = (props) => {
    const [type, setType] = React.useState(props.type);
    const [endDate, setEndDate] = React.useState(props.endDate);
    const [startDate, setStartDate] = React.useState(props.startDate);

    const endDateSetter = (date) => {
        setEndDate(date);
        props.setEndDate(date);
    }
    const startDateSetter = (date) => {
        setStartDate(date);
        props.setStartDate(date);
    }
    return (
        <React.Fragment>
            <MonthSelector title='Start' value={DateTime.fromJSDate(startDate)} setDate={()=>{}} setStartDate={startDateSetter} />
            <MonthSelector title='End' value={DateTime.fromJSDate(endDate)} setDate={endDateSetter} setStartDate={()=>{}} /> 
        </React.Fragment>
    )
}

export default DateSelector;


const MonthSelector = (props) => {
    const [value, setValue] = React.useState((props.value));
    const [open, setOpen] = React.useState(false);
    return (<LocalizationProvider dateAdapter={AdapterLuxon} >
        <DatePicker
            views={['month', 'year']}
            label={props.title}
            value={value}
            openTo="month"
            open={open}
            onChange={(newValue) => {
                setValue(newValue);
                console.log(newValue.toJSDate());
                props.setDate(tomorrow(tomorrow(endOfWeek(endOfMonth(newValue.toJSDate())))));
                props.setStartDate(tomorrow(tomorrow(startOfWeek(startOfMonth(newValue.toJSDate())))));
            }}
            onMonthChange={() => { setOpen(false) }}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
            inputFormat="MMM yyyy"
            renderInput={(params) => <TextField {...params} helperText={null} />}
        />
    </LocalizationProvider>)
}
