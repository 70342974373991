import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import { getAPIDataReturn, getAPIDataParamsReturn, postApi, getAPIData, getAPIDataParams } from "../../../utils/apiFunction";
import { dateFormat } from "../../../utils/dateFunctions";
import { getOysterGrades, buildSpeciesList, gradeSort } from "../../../definitions/oysters";
import DataTableStandard from "../../../components/DataTableStandard";
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { dateSort } from '../../../utils/sortFunctions';
import Typography from '@mui/material/Typography';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { number } from 'prop-types';
const types = [{ 'name': "Graded", 'value': "Graded" }, { 'name': "WIP", 'value': "WIP" }, { 'name': "UnGraded", 'value': "UnGraded" }, { 'name': "Frozen", 'value': "Frozen" }, { 'name': "Hide Frozen", 'value': "hideFrozen" }];
const products = [{ 'name': "Appellation", 'value': "Appellation" }, { 'name': "AOC", 'value': "AOC" }, { 'name': "Oystersmiths", 'value': "Oystersmiths" }];
const containerList = [{ 'name': "Bagged", 'value': "Bagged" }, { 'name': "Boxed", 'value': "Boxed" }];
const trays = [{ 'name': "1 Doz", 'value': "HS1D" }, { 'name': "2.5 Doz", 'value': "HS2H" }];
const SelectInventoryForm = (props) => {
    const hasStarted = !(props.selectedLine?.processingStatus === 'Not started');
    const [completed, setCompleted] = React.useState(props.completed);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [type, setType] = React.useState((props.typeDefault !== undefined) ? types[props.typeDefault].value : null);
    const [product, setProduct] = React.useState((props.productDefault !== undefined) ? products[props.productDefault].value : null);
    const [container, setContainer] = React.useState((props.containerDefault !== undefined) ? containerList[props.containerDefault].value : null);
    const [species, setSpecies] = React.useState(null);
    const [grade, setGrade] = React.useState(null);
    const [grades, setGrades] = React.useState([]);
    const [speciesList, setSpeciesList] = React.useState([]);
    const [myobDiff, setMyobDiff] = React.useState(null);
    const [stockItems, setStockItems] = React.useState(null);
    var recData = props.data ? props.data : {};
    const Swal = useSwalWrapper();
    const [inventory, setInventory] = React.useState(null);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredMasterAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MASTER };
    const requiredSpecialAcess = { key: ACCESS_SECTIONS.SPECIALACCESS, value: ACCESS.EDIT };
    const [traySize, setTraySize] = React.useState((props.traySizeDefault !== undefined) ? trays[props.traySizeDefault].value : null);


    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(data);
        });
    }, []);
    React.useEffect(() => {
        var cols = [];

        var oysterCols = gradeSort(grades)?.map((og) => {
            return {
                name: og.abv,
                label: og.abv,
                options: {
                    filter: false
                }
            }
        });
        setColumns([...cols, ...oysterCols]);
    }, [grades])

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '600px',
        responsive: 'standard',
        customFooter: ()=>(<div></div>),

    };

    const convertToTabelData = (data) => {
        var tableData = {};
        if (grades && grades.length > 0) {
            for (var key in data) {
                var selGrade = grades.find((grade) => Number(grade.id) === Number(key));
                tableData[selGrade.abv] = data[key];
            }
        }
            return [tableData];
    }
    const inventoryTotals = React.useMemo(() => {
        console.log(props.invStock)
        // organise data into grade columns
        if (props.invStock) {
            var data = props.invStock;
            var invTotals = {};
            for (var i = 0; i < data.length; i++) {
                if (invTotals[data[i].stockItem?.gradeId]) {
                    invTotals[data[i].stockItem?.gradeId] = invTotals[data[i].stockItem?.gradeId] + data[i].portalQuantity;
                } else {
                    invTotals[data[i].stockItem?.gradeId] = data[i].portalQuantity;
                }
            }

            return convertToTabelData(invTotals);
        }
        return null;
    }, [props.invStock, grades])

    return (
        <Div>

            <DataTableStandard
                data={inventoryTotals ? inventoryTotals : []}
                title={<span><Typography variant='h4' style={{display:'inline'}}>Inventory Totals</Typography><Typography variant='body1'  style={{display:'inline'}}> (based on filters above)</Typography></span>}
                columns={columns}
                options={options}
                returnSearch={!loading}
                reload={reloadFunc}
                shrink={props.shrinkTable}
            />
        </Div>
    );
};

export default SelectInventoryForm;


