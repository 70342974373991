import React from 'react';
import ControlledTextField from "./ControlledTextField";
import { SelectTextFieldNew } from "./SelectTextField";
import Grid from "@mui/material/Grid";
import { getAPIData } from '../utils/apiFunction';
import CropYearSelect from '../components/CropYearSelect';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

const OystersGroup = (props) => {
    const [stockItems, setStockItems] = React.useState(null);
    const [allStockItems, setAllStockItems] = React.useState(null);
    const [reduceItems, setReduceItems] = React.useState(null);
    //var oysters = props.oysters;
    React.useEffect(() => {
        var path = '/StockItems/GetUnprocessed';
        if (props.reduceItems||props.WIPcode) {
            path = '/StockItems/Get';
        } else if (props.processed) {
            if (props.processRunId) {
                path = '/Processing/GetProcessOutputs?ProcessRunId=' + props.processRunId;
            } else {
                path = '/StockItems/GetProcessed';
            }
        } else if (props.allStock) {
            path = '/StockItems/Get';
        } else {
            path = '/StockItems/GetUnprocessed';
        }
        getAPIData(path, sortStockItems(props.reduceItems || props.WIPcode ? setAllStockItems : setStockItems));
    }, [])
    const sortStockItems =(setFunc)=> (list) => {
        setFunc(list.sort((a, b) => {
            console.log(a.myobid, b.myobid,a.myobid.localeCompare(b.myobid))
            if(a.grade?.sortOrder === b.grade?.sortOrder){
                return -a.myobid.localeCompare(b.myobid);
            }
               return a.grade?.sortOrder - b.grade?.sortOrder;
        }));
    }


    React.useEffect(() => {
        if (props.reduceItems && props.supplier) {
            getAPIData('/Transfers/GetStockItems/' + props.supplier?.warehouseId, setReduceItems);
        }
    }, [props.supplier]);
    React.useEffect(() => {
        if (allStockItems) {
            if (reduceItems) {
                var newSI = [...allStockItems.filter((si) => {
                    return reduceItems.find((ri) => {
                        return ((ri.stockItemId === si.id) && (props.sameType === ri.toSame));
                    })
                })];
                setStockItems(newSI);
            } else if (props.WIPcode) {
                var newSI = [...allStockItems.filter((si) => {
                    return (si.myobid.length === 8) && (((si.myobid[4] + si.myobid[5] )===('HS'))|| ((si.myobid[4] + si.myobid[5] )===('LO')));
                })];
                setStockItems(newSI);
            }
        }
    }, [reduceItems, allStockItems]);

    const addLine = () => {
        var oysterListCPY = [...props.oysters];
        oysterListCPY.push({ tempId: uuidv4() });
        props.setOysters(oysterListCPY);
    }
    const removeLine = () => {
        var oysterListCPY = [...props.oysters];
        oysterListCPY.pop({});
        props.setOysters(oysterListCPY);
    }
    const setLine = (valToUpdate, ind) => {
        props.setOysters(oyLt => {
            var oysterListCPY = JSON.parse(JSON.stringify(oyLt));
            oysterListCPY[ind][valToUpdate.name] = valToUpdate.value;
            return oysterListCPY;
        });
    }
    const removeSpecficLine = (ind) => (e) => {
        props.setOysters(oyLt => {
            var oysterListCPY = JSON.parse(JSON.stringify(oyLt));
            oysterListCPY.splice(ind, 1);
            return oysterListCPY;
        });
    }
    console.log(props.oysters)
    return (<React.Fragment>
        {!props.noTitle ? <Grid item xs={12} >
            <h3 style={{ fontWeight: 450 }}>Oysters</h3>
        </Grid> : ''}
        {props.oysters?.map((oL, ind) => {
            console.log(oL)
            return <div style={{ position: 'relative' }} ><OysterLine disableStockItem={props.disableStockItem} showAsUnits={props.showAsUnits} noUnits={props.noUnits} key={oL.id ? oL.id : oL.tempId} data={oL} items={stockItems} setLine={setLine} index={ind} disabled={props.disabled} cropYear={props.cropYear} supplier={props.supplier} nameFunc={props.nameFunc} />
                <IconButton aria-label="remove" onClick={removeSpecficLine(ind)} disabled={props.disabled} style={{
                    position: 'absolute',
                    right: '0px',
                    top: '30%'
                }} >
                    <CloseIcon />
                </IconButton>
            </div>
        })}
        {!props.single ? <React.Fragment><Stack direction="row" spacing={1}>
            <IconButton aria-label="add" onClick={addLine} disabled={props.disabled}>
                <AddIcon />
            </IconButton>
            <IconButton aria-label="remove" onClick={removeLine} disabled={props.disabled}>
                <RemoveIcon />
            </IconButton>
        </Stack>
            <ControlledTextField title="Total Quantity (doz)" state={totalQuantity(props.oysters)} disabled number />
        </React.Fragment> : ''}

    </React.Fragment>)
}
const totalQuantity = (list) => {
    return list?.reduce((p, c) => { return p + (c.quantity ? Number(c.quantity) : 0) }, 0)
}
const OysterLine = (props) => {
    var ol = props.data;

    const remSS = (inp) => {
        if (inp.description.includes('Single Seed')) {
            return inp.description.replace('Single Seed', '');
        } else {
            return inp.description;
        }
    }
    const extractCode = val => {
        if (val) {
            updateFunction('cropYear')(val.code)
        }
    }
    const updateFunction = (name) => (val) => {
        var pv = !props.nonStock && ol.stockItem && props.priceList && (Number(ol.price) !== (props.priceList.find((pl) => { return pl.stockItemId === ol.stockItem.id })?.price))
        props.setLine({ name: 'priceVary', value: pv }, props.index);
        if (((name === 'stockItem') || (name === 'bins') || (name === 'bags')) && (ol.stockItem)) {
            var SI = ol.stockItem;
            var bags = ol.bags;
            var bins = ol.bins;
            if (name === 'stockItem') {
                SI = val;
            }
            if (name === 'bags') {
                bags = val;
            }
            if (name === 'bins') {
                bins = val;
            }

            var quant = (bags ? bags : 0) * SI.bagCount + (bins ? bins : 0) * SI.binCount;
            props.setLine({ name: 'quantity', value: quant }, props.index);
        }

        props.setLine({ name: name, value: val }, props.index);

    }
    if (!ol.stockItem && ol.stockItemId) {
        var newStockItem = props.items?.find((i) => { return i.id === ol.stockItemId });
        if (newStockItem) {
            updateFunction('stockItem')(newStockItem);
        }
    }
    return <Grid key={props.key} container spacing={2} mb={1} style={{ borderBottom: '1px solid lightgrey' }}>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SelectTextFieldNew key={'si' + props.key} title="Stock Item" state={ol.stockItem} nameFunc={props.nameFunc ? props.nameFunc : remSS} valueFunc={v => v?.id} list={props.items} setState={updateFunction('stockItem')} disabled={props.disableStockItem || props.disabled} />
        </Grid>
        {!props.noUnits ?<Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <ControlledTextField key={'bg' + props.key} title={props.showAsUnits ? 'Units' : "Bags"} state={ol.bags} setState={updateFunction('bags')} disabled={props.disabled} number />
        </Grid>: null}
        {!props.showAsUnits && !props.noUnits ? <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <ControlledTextField key={'bi' + props.key} title="Bins" state={ol.bins} setState={updateFunction('bins')} disabled={props.disabled} number />
        </Grid> : null}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <ControlledTextField key={'qua' + props.key} title="Quantity (doz)" state={ol.quantity} setState={updateFunction('quantity')} disabled={props.disabled} number />
        </Grid>
        {props.cropYear ? <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <CropYearSelect key={'cy' + props.key} supplier={props.supplier} estId={ol.cropYear} state={ol.cropYear} setState={extractCode} disabled={props.disabled} />
        </Grid> : ''}
        {props.showAsUnits && ol.stockItem && ol.quantity && ((ol.quantity / ol.stockItem?.bagCount) !== Number(ol.bags)) ? <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <Typography variant="h3" style={{ marginTop: '1rem' }}>{ol.stockItem.bagCount ? ("Calculated Units: " + (Number(ol.quantity) / (Number(ol.stockItem.bagCount))).toFixed(1)):''}</Typography>
        </Grid> : null}

    </Grid>
}
export default OystersGroup;