import React from 'react';
import TextField from "@mui/material/TextField";


const ControlledTextField = (props) => {
    const handleChange = (event) => {
        props.setState(event.target.value);
    }
    var style = {};
    var textStyle = {};
    if (props.width) {
        style = { width: props.width }
    }
    if(props.style){
        textStyle = {...props.style}
    }
    const numberInputOnWheelPreventChange = (e) => {
        //  //Prevent the input value change
        e.target.blur()
    }

    return <TextField
        type={props.number ? "number" : 'text'}
        inputProps={props.noDecimal ? {
            style: {...textStyle},
            onWheel: numberInputOnWheelPreventChange,
            onKeyDown: (e) => { if (e.key === '.') { e.preventDefault(); } },
            ...(props.updateDataOnBlur?{onBlur: (e) => { props.updateDataOnBlur(e.target.value) }}:{})
        } : { onWheel: numberInputOnWheelPreventChange, ...(props.updateDataOnBlur?{onBlur: (e) => { props.updateDataOnBlur(e.target.value) }}:{}), style: {...textStyle}}}
        disabled={props.disabled}
        id="outlined-required"
        key={props.key}
        label={props.title}
        value={props.state}
        onChange={handleChange}
        style={style}
        InputLabelProps={{
            shrink: true,
        }}
    />
}
export default ControlledTextField;