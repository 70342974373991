import { ACCESS, ACCESS_SECTIONS } from "./constants/access";

export const checkAccess = (currAcc, reqAcc) => {
    var allowed = false;
    if (!reqAcc) {
        return true;
    } else {
        if (Array.isArray(currAcc)) {
            for (var i = 0; i < currAcc.length; i++) {
                if (currAcc[i].key === reqAcc.key) {
                    if (greaterRank(currAcc[i].value, reqAcc.value)) {
                        allowed = true;
                    }
                }
            }
        }
    }
    return allowed;
}

export const greaterRank = (rankA, rankB) => {
    const rankWeight = (rank) => {
        if (rank === ACCESS.NONE) {
            return 0;
        } else if (rank === ACCESS.VIEW) {
            return 1;
        } else if (rank === ACCESS.EDIT) {
            return 2;
        } else if (rank === ACCESS.MYOB) {
            return 3;
        } else if (rank === ACCESS.ADMIN) {
            return 4;
        } else if (rank === ACCESS.MASTER) {
            return 5;
        }
    }
    if (rankWeight(rankA) > rankWeight(rankB)) {
        return true;
    } else if (rankWeight(rankA) === rankWeight(rankB)) {
        return true;
    } else {
        return false;
    }
}
export const getUsersAccess = (user) => {
    const roles = user ? user.idTokenClaims ? user.idTokenClaims.roles : [] : [];
    var newAccess = [];


    const updateAccess = (access) => {
        for (var i = 0; i < access.length; i++) {
            var found = false;
            for (var j = 0; j < newAccess.length; j++) {
                if (access[i].key === newAccess[j].key) {
                    found = true;
                    if (!greaterRank(newAccess[j].value, access[i].value)) {
                        newAccess[j].value = access[i].value;
                    }
                }
            }
            if (!found) {
                newAccess.push(access[i]);
            }
        }
    }
    //updateAccess(ViewingUser); // new users default access
    if (roles) {
        for (var i = 0; i < roles.length; i++) {
            if (roles[i] === "StandardUser") {
                updateAccess(StandardUser);
            } else if (roles[i] === "ViewUser") {
                updateAccess(ViewingUser);
            } else if (roles[i] === "Admin") {
                updateAccess(Admin);
            } else if (roles[i] === "Master") {
                updateAccess(Developer);
            } else if (roles[i] === "ProcurementAdmin") {
                updateAccess(ProcurementAdmin);
            } else if (roles[i] === "ProcurementEdit") {
                updateAccess(ProcurementEdit);
            } else if (roles[i] === "ProcessingAdmin") {
                updateAccess(ProcessingAdmin);
            } else if (roles[i] === "ProcessingEdit") {
                updateAccess(ProcessingEdit);
            } else if (roles[i] === "FinanceTeam") {
                updateAccess(FinanceTeam);
            } else if (roles[i] === "AllFarms") {
                updateAccess(AllFarmer);
            } else if (roles[i] === "Bermagui") {
                updateAccess(BERMAGUI);
            } else if (roles[i] === "Merimbula") {
                updateAccess(MERIMBULA);
            } else if (roles[i] === "Hastings") {
                updateAccess(HASTINGS);
            } else if (roles[i] === "Narooma") {
                updateAccess(NAROOMA);
            } else if (roles[i] === "Shoalhaven") {
                updateAccess(SHOALHAVEN);
            } else if (roles[i] === "Wallis") {
                updateAccess(WALLIS);
            } else if (roles[i] === "Wapengo") {
                updateAccess(WAPENGO);
            } else if (roles[i] === "Hatchery") {
                updateAccess(HATCHERY);
            } else if (roles[i] === "ViewAllFarms") {
                updateAccess(ViewAllFarmer);
            } else if (roles[i] === "NewFeatureViewer") {
                updateAccess(NewFeatureViewer);
            } else if (roles[i] === "Fulfilment") {
                updateAccess(FulfilmentUser);
            } else if (roles[i] === "SalesTeam") {
                updateAccess(SalesTeam);
            } else if (roles[i] === "Undo") {
                updateAccess(Undo);
            } else if (roles[i] === "SpecialAccess") {
                updateAccess(SpecialAccess);
            } else if (roles[i] === "FulfilmentMYOB") {
                updateAccess(FulfilmentMYOB);
            } else if (roles[i] === "Sydney") {
                updateAccess(Sydney);
            } else if (roles[i] === "BatemansBay") {
                updateAccess(BatemansBay);
            } else if (roles[i] === "ProcessingMYOB") {
                updateAccess(ProcessingMYOB);
            } else if (roles[i] === "EditNewFeature") {
                updateAccess(EditNewFeature);
            } else if (roles[i] === "SalesAdmin") {
                updateAccess(SalesAdmin);
            } else if (roles[i] === "InventoryView") {
                updateAccess(InventoryView);
            }else {

            }
        }
    }
    return newAccess
}

//Categories for each page - levels of access
//none - cant access page doesnt know it exists
//view - can see tables etc
//edit - input and edit data for that page
//admin - update settings for that section
//master - admin++  - webmaster access

const noAccess = [
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.NONE
    },
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.NONE
    },
    {
        key: ACCESS_SECTIONS.SALES,
        value: ACCESS.NONE
    }
]
const Sydney = [
    {
        key: ACCESS_SECTIONS.SYDNEY,
        value: ACCESS.EDIT
    }
]
const BatemansBay = [
    {
        key: ACCESS_SECTIONS.LIVEPRODUCT,
        value: ACCESS.EDIT
    }
]
const ViewingUser = [
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.SALES,
        value: ACCESS.VIEW
    }
]

const StandardUser = [
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.EDIT
    }
]
const FulfilmentUser = [
    {
        key: ACCESS_SECTIONS.FULFILMENT,
        value: ACCESS.EDIT
    }
]

const ProcurementEdit = [
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.EDIT
    }
]

const ProcurementAdmin = [
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.ADMIN
    },
    ...Sydney,
    ...BatemansBay
]
const ProcessingEdit = [
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.EDIT
    }
]
const InventoryView = [
    {
        key: ACCESS_SECTIONS.INVENTORY,
        value: ACCESS.VIEW
    }
]
const ProcessingAdmin = [
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.ADMIN
    },
    {
        key: ACCESS_SECTIONS.INVENTORY,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.FULFILMENT,
        value: ACCESS.MYOB
    },
    ...Sydney,
    ...BatemansBay
]
const FinanceTeam = [
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.SALES,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.MYOB_PROCUREMENT,
        value: ACCESS.ADMIN
    },
    {
        key: ACCESS_SECTIONS.FULFILMENT,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.FULFILMENT,
        value: ACCESS.MYOB
    },
    ...Sydney,
    ...BatemansBay
]
const SalesTeam = [
    {
        key: ACCESS_SECTIONS.FULFILMENT,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.SALES,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.PROCUREMENT,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.VIEW
    },
    ...Sydney,
    ...BatemansBay
]
const SalesAdmin = [
    {
        key: ACCESS_SECTIONS.SALES,
        value: ACCESS.ADMIN
    }
]
const BERMAGUI = [

    {
        key: ACCESS_SECTIONS.BERMAGUI,
        value: ACCESS.ADMIN
    }
    ,

    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    }
    ,
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];
const HASTINGS = [

    {
        key: ACCESS_SECTIONS.HASTINGS,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];
const MERIMBULA = [

    {
        key: ACCESS_SECTIONS.MERIMBULA,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];
const NAROOMA = [

    {
        key: ACCESS_SECTIONS.NAROOMA,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];

const SHOALHAVEN = [

    {
        key: ACCESS_SECTIONS.SHOALHAVEN,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];
const WALLIS = [

    {
        key: ACCESS_SECTIONS.WALLIS,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];
const WAPENGO = [

    {
        key: ACCESS_SECTIONS.WAPENGO,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];

const HATCHERY = [

    {
        key: ACCESS_SECTIONS.HATCHERY,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.EDIT
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.EDIT
    }
];
const AllFarmer = [

    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.ADMIN
    },
    {
        key: ACCESS_SECTIONS.BERMAGUI,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.HASTINGS,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.MERIMBULA,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.NAROOMA,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.SHOALHAVEN,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.WALLIS,
        value: ACCESS.ADMIN
    }
    ,
    {
        key: ACCESS_SECTIONS.WAPENGO,
        value: ACCESS.ADMIN
    },
    {
        key: ACCESS_SECTIONS.HATCHERY,
        value: ACCESS.ADMIN
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.VIEW
    }
];

const ViewAllFarmer = [

    {
        key: ACCESS_SECTIONS.FARMS,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.BERMAGUI,
        value: ACCESS.VIEW
    }
    ,
    {
        key: ACCESS_SECTIONS.HASTINGS,
        value: ACCESS.VIEW
    }
    ,
    {
        key: ACCESS_SECTIONS.MERIMBULA,
        value: ACCESS.VIEW
    }
    ,
    {
        key: ACCESS_SECTIONS.NAROOMA,
        value: ACCESS.VIEW
    }
    ,
    {
        key: ACCESS_SECTIONS.SHOALHAVEN,
        value: ACCESS.VIEW
    }
    ,
    {
        key: ACCESS_SECTIONS.WALLIS,
        value: ACCESS.VIEW
    }
    ,
    {
        key: ACCESS_SECTIONS.WAPENGO,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.HATCHERY,
        value: ACCESS.VIEW
    },
    {
        key: ACCESS_SECTIONS.OYSTERMATE,
        value: ACCESS.VIEW
    }
];

const Developer = [
    ...Object.keys(ACCESS_SECTIONS).map((key) => {
        return {
            key: ACCESS_SECTIONS[key],
            value: ACCESS.MASTER
        }
    })
]

const Admin = [
    ...Object.keys(ACCESS_SECTIONS).map((key) => {
        return {
            key: ACCESS_SECTIONS[key],
            value: ACCESS.ADMIN
        }
    })
]

const NewFeatureViewer = [
    {
        key: ACCESS_SECTIONS.NEWFEATURE,
        value: ACCESS.MASTER
    },
]

const Undo = [
    {
        key: ACCESS_SECTIONS.UNDO,
        value: ACCESS.EDIT
    }
]

const SpecialAccess = [
    {
        key: ACCESS_SECTIONS.SPECIALACCESS,
        value: ACCESS.EDIT
    }
]

const FulfilmentMYOB = [
    {
        key: ACCESS_SECTIONS.FULFILMENT,
        value: ACCESS.MYOB
    }
]

const ProcessingMYOB = [
    {
        key: ACCESS_SECTIONS.PROCESSING,
        value: ACCESS.MYOB
    }
]
const EditNewFeature = [
    {
        key: ACCESS_SECTIONS.NEWFEATURE,
        value: ACCESS.EDIT
    }
]