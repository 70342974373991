import React from 'react';
import { gradeSort, getOysterGrades } from "../../../definitions/oysters";
import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { tableTheme } from '../../../themes/tableTheme';
import { TableCell, TableRow, Table, TableHead, Typography, TableContainer } from "@mui/material";
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import DayToggle from "../../../components/DayToggle";
import { headerRender } from '../../../utils/renderFunctions';
import TooltipDescription from "../../../components/TooltipDescription";
import { numberFormat } from '../../../utils/numberFunctions';
import { textfieldDateFormat, yesterday, tomorrow, generateDates, dateToZero, dayCompare, shiftHours } from '../../../utils/dateFunctions';
import { useJumboTheme } from "@jumbo/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { handlePagination, getAPIDataParams } from '../../../utils/apiFunction';
import { WAREHOUSEMATCHES } from "../../../utils/constants/warehouse";

const CANCELLED = "Cancelled";
const CANCELED = "Canceled";
const CLOSED = "Closed";
const BACKORDER = "Back Order";
const COMPLETED = "Completed";
const SHIPPING = "Shipping";
const TRANSFERTYPE = 'TR';
const TRANSFERTYPELONG = 'Transfer';
const INVOICED = 'Invoiced';
const CONFIRMED = 'Confirmed';
const OPEN = 'Open';

const WeeklySummary = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [sales, setSales] = React.useState([]);
    const [shipments, setShipments] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const [nonZeroGrades, setNonZeroGrades] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        setSales([]);
        if( (props.location !== null)&&(grades.length > 0)) {
            getProcurementData(props.location, (props.startDate), (props.endDate), abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, reload, props.externalReload,grades]);

    var getProcurementData = async (location, startDateDate, endDateDate, abortController) => {
        var startDate = textfieldDateFormat(startDateDate);
        var endDate = textfieldDateFormat(endDateDate);
        var data = await handlePagination(fetch, uri + ('/purchaseorder/Get'), startDate, textfieldDateFormat(tomorrow(endDate)), { location, UsePromisedDate: true }, abortController);
        var newPOArr = [];
        var formData = data.map((nObj) => {
            if (nObj.orderInputLines) {
                for (var i = 0; i < nObj.orderInputLines.length; i++) {
                    var newPO = { ...nObj };
                    var myobid = newPO.orderInputLines[i].stockItem.myobid;
                    if (nObj.orderInputLines[i].stockItem) {
                        newPO[newPO.orderInputLines[i].stockItem.gradeId] = newPO.orderInputLines[i].ordered;
                    }
                    if (WAREHOUSEMATCHES.BBay.includes(newPO.orderInputLines[i]?.warehouse?.myobid) && (location === 1)) {
                        newPOArr.push(newPO);
                    } else if (WAREHOUSEMATCHES.Syd.includes(newPO.orderInputLines[i]?.warehouse?.myobid) && (location === 2)) {
                        newPOArr.push(newPO);
                    } else if (WAREHOUSEMATCHES.Contractor.includes(newPO.orderInputLines[i]?.warehouse?.myobid) && (location === 3)) {
                        newPOArr.push(newPO);
                    } else if ((location === 0)) {
                        newPOArr.push(newPO);
                    }
                }
            }
            return nObj;
        });
        var sales = await handlePagination(fetch, uri + ('/SalesOrder/Get'), startDate, endDate, {}, abortController);
        var newSalesArr = [];
        for (var l = 0; l < sales.length; l++) {
            var sale = sales[l];
            if (sale.salesOrderLines && (sale.orderType !== TRANSFERTYPE)) {
                for (var i = 0; i < sale.salesOrderLines.length; i++) {
                    var newSI = { ...sale };
                    if (sale.salesOrderLines[i].stockItem) {
                        if (newSI.salesOrderLines[i].complete) {
                            newSI[sale.salesOrderLines[i].stockItem.gradeId] = newSI.salesOrderLines[i].qtyOnShipments;
                        } else {
                            newSI[sale.salesOrderLines[i].stockItem.gradeId] = newSI.salesOrderLines[i].quantity;
                        }
                    }
                    if (WAREHOUSEMATCHES.BBay.includes(newSI.salesOrderLines[i]?.warehouse?.myobid) && (location === 1)) {
                        newSalesArr.push(newSI);
                    } else if (WAREHOUSEMATCHES.Syd.includes(newSI.salesOrderLines[i]?.warehouse?.myobid) && (location === 2)) {
                        newSalesArr.push(newSI);
                    } else if (WAREHOUSEMATCHES.Contractor.includes(newSI.salesOrderLines[i]?.warehouse?.myobid) && (location === 3)) {
                        newSalesArr.push(newSI);
                    } else if ((location === 0)) {
                        newSalesArr.push(newSI);
                    }
                }
            }
        };
        var shipments = await handlePagination(fetch, uri + ('/Shipment/Get'), startDate, endDate, {}, abortController);
        var newShipmentsArr = [];
        for (var l = 0; l < shipments.length; l++) {
            var ship = shipments[l];
            if (ship.shipmentLines && (ship.type !== TRANSFERTYPELONG)) {
                for (var i = 0; i < ship.shipmentLines.length; i++) {
                    var newSI = { ...ship };
                    if (ship.shipmentLines[i].stockItem) {
                        newSI[ship.shipmentLines[i].stockItem.gradeId] = newSI.shipmentLines[i].shippedQty;
                    }
                    if (WAREHOUSEMATCHES.BBay.includes(newSI.shipmentLines[i]?.warehouse?.myobid) && (location === 1)) {
                        newShipmentsArr.push(newSI);
                    } else if (WAREHOUSEMATCHES.Syd.includes(newSI.shipmentLines[i]?.warehouse?.myobid) && (location === 2)) {
                        newShipmentsArr.push(newSI);
                    } else if (WAREHOUSEMATCHES.Contractor.includes(newSI.shipmentLines[i]?.warehouse?.myobid) && (location === 3)) {
                        newShipmentsArr.push(newSI);
                    } else if ((location === 0)) {
                        newShipmentsArr.push(newSI);
                    }
                }
            }
        };
        var nZGrades = [];
        for (var j = 0; j < grades.length; j++) {
            var grade = grades[j];
            var gradeIdString = String(grade.id);
            var hasData = false;
            for (var i = 0; i < formData.length; i++) {
                var dt = formData[i];
                //loop through each key in day

                if (!isNaN(dt[gradeIdString]) && (dt[gradeIdString] !== 0)) {
                    hasData = true
                }
            }
            for (var i = 0; i < newSalesArr.length; i++) {
                var dt = newSalesArr[i];
                //loop through each key in day

                if (!isNaN(dt[gradeIdString]) && (dt[gradeIdString] !== 0)) {
                    hasData = true
                }
            }
            for (var i = 0; i < newShipmentsArr.length; i++) {
                var dt = newShipmentsArr[i];
                //loop through each key in day

                if (!isNaN(dt[gradeIdString]) && (dt[gradeIdString] !== 0)) {
                    hasData = true
                }
            }
            if (hasData) {
                nZGrades.push(grade);
            }
        }
        setNonZeroGrades(nZGrades);
        setProcurement(newPOArr);
        setSales(newSalesArr);
        setShipments(newShipmentsArr);

    }


    const buildSalesRows = (data, ships, nzGrades) => {
        //console.log(data.filter((dt) => { return ((dt.status !== CANCELLED) && (dt.status !== CANCELED) && (dt.status !== BACKORDER)) }).map((s) => {
        //    return {
        //        myobid: s.myobid,
        //        orderDate: s.orderDate,
        //        shipmentDate: s.shipmentDate,
        //        orderType: s.orderType,
        //        customer: s.customer.name,
        //        status: s.status,
        //        ...(nzGrades.reduce((p, c) => {
        //            return {
        //                ...p,
        //                [c.abv]: s[c.abv]
        //            }
        //        }, {}))
        //    }
        //}));
        if (data.length > 0) {
            var rows = [{ title: 'Sales Shipments Completed' }, { title: 'Sales Orders to Go' }];
            for (var i = 0; i < data.length; i++) {
                nzGrades.map((grade) => {
                    var gradeAbv = String(grade.id);
                    var lowGradeAbv = String(grade.id);

                    if (!rows[1][lowGradeAbv]) {
                        rows[1][lowGradeAbv] = 0;
                    }
                    if ((data[i].status !== CANCELLED) && (data[i].status !== CANCELED) && (data[i].status !== BACKORDER)) {
                        if ((data[i].status === COMPLETED) || (data[i].status === CLOSED)) {
                        } else {
                            rows[1][lowGradeAbv] = (rows[1][lowGradeAbv] ? rows[1][lowGradeAbv] : 0) + (data[i][gradeAbv] ? data[i][gradeAbv] : 0);
                        }

                    }
                });
            }
            for (var i = 0; i < ships.length; i++) {
                nzGrades.map((grade) => {
                    var gradeAbv = String(grade.id);
                    var lowGradeAbv = String(grade.id);
                    if (!rows[0][lowGradeAbv]) {
                        rows[0][lowGradeAbv] = 0;
                    }
                    if ((ships[i].status !== OPEN)) {
                        var isCompleted = true;
                        if (ships[i].status === CONFIRMED) {
                            if (ships[i].salesOrder?.status !== COMPLETED) {
                                isCompleted = false;
                            }
                        }
                        if (isCompleted) {
                            rows[0][lowGradeAbv] = (rows[0][lowGradeAbv] ? rows[0][lowGradeAbv] : 0) + (ships[i][gradeAbv] ? ships[i][gradeAbv] : 0);
                        }
                    }
                });
            }


            return rows;
        }
        return [];
    }
    const buildProcRows = (data, nzGrades) => {
        if (data.length > 0) {
            var rows = [{ title: 'Transfers/Purchases Completed' }, { title: 'Purchase Orders to Go' }];
            for (var i = 0; i < data.length; i++) {
                    nzGrades.map((grade) => {
                    var gradeAbv = String(grade.id);
                    var lowGradeAbv = String(grade.id);
                    if (!rows[0][lowGradeAbv]) {
                        rows[0][lowGradeAbv] = 0;
                    }
                    if (!rows[1][lowGradeAbv]) {
                        rows[1][lowGradeAbv] = 0;
                    }
                    if ((data[i].status !== CANCELLED) && (data[i].status !== CANCELED)) {
                        if ((data[i].status === COMPLETED) || (data[i].status === CLOSED)) {
                            rows[0][lowGradeAbv] = (rows[0][lowGradeAbv] ? rows[0][lowGradeAbv] : 0) + (data[i][gradeAbv] ? data[i][gradeAbv] : 0);
                        } else {
                            rows[1][lowGradeAbv] = (rows[1][lowGradeAbv] ? rows[1][lowGradeAbv] : 0) + (data[i][gradeAbv] ? data[i][gradeAbv] : 0);
                        }
                    }
                });
            }
            return rows;
        }
        return [];
    }
    const buildHarvestRows = (data, nzGrades) => {
        if (data.length > 0) {
            var rows = [{ title: 'Harvests Completed' }, { title: 'Harvests to Go' }];
            for (var i = 0; i < data.length; i++) {
                nzGrades.map((grade) => {
                    var gradeAbv = String(grade.id);
                    var lowGradeAbv =  String(grade.id);
                    if (!rows[0][lowGradeAbv]) {
                        rows[0][lowGradeAbv] = 0;
                    }
                    if (!rows[1][lowGradeAbv]) {
                        rows[1][lowGradeAbv] = 0;
                    }
                    if (data[i].supplier?.isInternal) {
                        if ((data[i].status !== CANCELLED) && (data[i].status !== CANCELED)) {
                            if ((data[i].status === COMPLETED) || (data[i].status === CLOSED)) {
                                rows[0][lowGradeAbv] = (rows[0][lowGradeAbv] ? rows[0][lowGradeAbv] : 0) + (data[i][gradeAbv] ? data[i][gradeAbv] : 0);
                            } else {
                                rows[1][lowGradeAbv] = (rows[1][lowGradeAbv] ? rows[1][lowGradeAbv] : 0) + (data[i][gradeAbv] ? data[i][gradeAbv] : 0);
                            }
                        }
                    }
                });
            }
            return rows;
        }
        return [];
    }
    return (<Grid container spacing={2} mb={1} >
        <Grid item xs={12}>
            <SmallTableDetail rows={buildSalesRows(sales, shipments, nonZeroGrades)} nonZeroGrades={nonZeroGrades} title={'Sales'} />
        </Grid>
        <Grid item xs={12}>
            <SmallTableDetail rows={buildProcRows(procurement, nonZeroGrades)} nonZeroGrades={nonZeroGrades} title={'Procurement'} />
        </Grid>
        <Grid item xs={12}>
            <SmallTableDetail rows={buildHarvestRows(procurement, nonZeroGrades)} nonZeroGrades={nonZeroGrades} title={'Harvests'} percOf={buildProcRows(procurement, nonZeroGrades)} />
        </Grid>
    </Grid>

    );
};

const SmallTableDetail = (props) => {
    const { theme } = useJumboTheme();
    var bgCol = theme.palette.oyster.AN;
    const getColOy = (OyAbv) => {
        var col = 'lightgrey';
        if (OyAbv.includes('SR')) {
            col = theme.palette.oyster.SR;
        } else if (OyAbv.includes('PC')) {
            col = theme.palette.oyster.PC;
        } else if (OyAbv.includes('AN')) {
            col = theme.palette.oyster.AN;
        }
        return col;
    }
    var cellStyle = {
        padding: '4px',
        maxWidth: '100px',
        color: 'black'
    }
    const calcTotal = (rows, grade) => {
        var total = 0;
        for (var i = 0; i < rows.length; i++) {
            total = total + (rows[i][grade]?rows[i][grade]:0);
        }
        return total;
    }

    return (
        <JumboCardQuick
            wrapperSx={{
                p: 3,
                pt: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 3
                }
            }}
            title={<Typography variant={'h2'} sx={{ paddingBottom: 0 }} >{props.title}</Typography>}
        >
            {props.rows.length > 0 ? <TableContainer>
                {/*style={{ overflowX: "initial" }} */}
                <Table >
                    {/*// stickyHeader style={{  borderCollapse: 'collapse'  }}*/}
                    <TableHead >
                        <TableRow >

                            <TableCell sx={{ pl: 3 }}>
                            </TableCell>
                            {props.nonZeroGrades.map((og) => {

                                return <TableCell sx={{ textAlign: 'right', fontSize: 'medium', ...cellStyle, background: getColOy(og.abv), fontWeight: 600 }}>
                                    {og.abv}
                                </TableCell>
                            })}

                            <TableCell sx={{ pl: 3, textAlign: 'right', background: getColOy('total'), fontWeight: '600', ...cellStyle, fontSize: 'medium' }}>
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {props.rows.map((row) => {
                        var rowTotal = 0;
                        return <TableRow hover style={{ zIndex: 1000, }} >

                            <TableCell sx={cellStyle}>
                                <Typography variant={"h6"} mb={0}>{row.title}</Typography>
                            </TableCell>
                            {props.nonZeroGrades.map((og) => {
                                rowTotal = rowTotal + row[String(og.id)];
                                return <TableCell sx={{ textAlign: 'right', ...cellStyle, background: getColOy(og.abv) }}>
                                    {numberFormat(row[String(og.id)])}
                                </TableCell>
                            })}
                            <TableCell sx={{ textAlign: 'right', ...cellStyle, background: getColOy('total') }}>
                                {numberFormat(rowTotal)}
                            </TableCell>
                        </TableRow>
                    })}
                    <TableRow hover style={{ zIndex: 1000, borderTop: '2.5px solid black', borderBottom: '2.5px solid black' }} >

                        <TableCell sx={cellStyle}>
                            <Typography style={{ fontWeight: '600' }} variant={"h6"} mb={0}>Total {props.title} for the Week</Typography>
                        </TableCell>
                        {props.nonZeroGrades.map((og) => {
                            return <TableCell sx={{ textAlign: 'right', ...cellStyle, fontWeight: '600', background: getColOy(og.abv) }}>
                                {numberFormat(calcTotal(props.rows, String(og.id)))}
                            </TableCell>
                        })}
                        <TableCell sx={{ textAlign: 'right', ...cellStyle, fontWeight: '600', background: getColOy('total') }}>
                            {numberFormat(props.nonZeroGrades.reduce((p, c) => { return p + calcTotal(props.rows, String(c.id)) }, 0))}
                        </TableCell>
                    </TableRow>
                    {props.percOf ? <TableRow hover style={{ zIndex: 1000, borderTop: '2.5px solid black', borderBottom: '2.5px solid black' }} >

                        <TableCell sx={cellStyle}>
                            <Typography style={{ fontWeight: '600' }} variant={"h6"} mb={0}>Percentage of Total Procurement</Typography>
                        </TableCell>
                        {props.nonZeroGrades.map((og) => {
                            var perc = numberFormat((calcTotal(props.rows, String(og.id)) / calcTotal(props.percOf, String(og.id)) * 100).toFixed(0));
                            return <TableCell sx={{ textAlign: 'right', ...cellStyle, fontWeight: '600', background: getColOy(og.abv) }}>
                                {Number(perc) ? perc : 0}%
                            </TableCell>
                        })}
                        <TableCell sx={{ textAlign: 'right', ...cellStyle, fontWeight: '600', background: getColOy('total') }}>
                            {numberFormat((props.nonZeroGrades.reduce((p, c) => { return p + calcTotal(props.rows,String(c.id)) }, 0) / props.nonZeroGrades.reduce((p, c) => { return p + calcTotal(props.percOf, String(c.id)) }, 0) * 100).toFixed(0))}%
                        </TableCell>
                    </TableRow> : null}
                </Table>
            </TableContainer> : <CircularProgress />}
        </JumboCardQuick>
    );
}

export default WeeklySummary;
