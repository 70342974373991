import React, { useMemo } from 'react';
import { dateFormat, textfieldDateFormat, generateTextDays, generateDates, dayCompare } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { getAPIDataParamsReturn, postApi, getAPIData } from "../../../utils/apiFunction";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import FormModal from '../../../components/FormModal';
import ProcessStockForm from '../Forms/ProcessStockForm';
import SelectInventoryForm from '../Forms/SelectInventoryForm';
import { Table, TableBody, TableRow, TableCell, Avatar, TableHead } from "@mui/material";
import SelectTextField from "../../../components/SelectTextField";
import TableContainer from "@mui/material/TableContainer";
import { useJumboTheme } from "@jumbo/hooks";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { checkAccess } from '../../../utils/roleFunctions';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Button from "@mui/material/Button";
import { numberFormat } from '../../../utils/numberFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ViewQAButton } from "../../../components/TableItemViews";
import AddCommentIcon from '@mui/icons-material/AddComment';
import RefreshIcon from '@mui/icons-material/Refresh';
import { comment } from 'stylis';
const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

const PurchaseOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [openInv, setOpenInv] = React.useState(false);
    const [commentOpen, setCommentOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [daySelection, setDaySelection] = React.useState([generateDates(props.startDate, props.endDate).findIndex((vl) => { return dayCompare(vl, new Date()) })]);
    const [procurement, setProcurement] = React.useState(null);
    const [unlock, setUnlock] = React.useState(false);
    const [savingOrder, setSavingOrder] = React.useState(false);
    const [originalOrderString, setOriginalOrderString] = React.useState(null);
    const [selectedLine, setSelectedLine] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    const [equipments, setEquipments] = React.useState([]);
    const [processed, setProcessed] = React.useState(false);
    const [QAList, setQAList] = React.useState([]);
    var showOrderButton = false;
    const getOrderString = (dta) => {
        var os = '';
        if (dta !== null) {
            for (var i = 0; i < dta.length; i++) {
                if (i !== 0) {
                    os += ',';
                }
                os += dta[i]?.id;
            }
        }
        return os;

    }
    if (getOrderString(procurement) !== originalOrderString) {
        showOrderButton = true;
    } else {
        showOrderButton = false;
    }
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setProcurement(arrayMove(procurement, oldIndex, newIndex));
    };
    const updateProcurement = (index, key) => (value) => {
        var proc = [...procurement];
        proc[index][key] = value;
        setProcurement(proc);
    };
    React.useEffect(() => {
        getAPIData('/Processing/GetProductionLines', setEquipments);
    }, []);
    React.useEffect(() => {
        if (procurement && procurement.length > 0) {
            var prms = [];
            procurement?.forEach((p) => {
                prms.push(getAPIDataParamsReturn('/Inventory/QAInfo', { lotSerialNbr: p.lotSerialNbr, StockItemId: p.stockItemId, ExpiryDate: textfieldDateFormat(new Date(p.expiryDate)) }));
            });
            Promise.all(prms).then((data) => {
                var qas = [];
                
                for (var i = 0; i < data.length; i++) {
                    qas = [...qas, ...data[i]];
                }
                //remove duplicate LotSerialNbr
                qas = qas.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.lotSerialNbr === item.lotSerialNbr
                    ))
                );
                setQAList(qas);
            });
        }
    }, [procurement]);
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        if (props.startDate && !isNaN(props.startDate.valueOf())) {
            getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, props.reload, daySelection, reload]);

    var getProcurementData = async (location, startDate, endDate, abortController) => {
        setLoading(true);
        if (location) {
            var promarr = [];
            promarr.push(await getAPIDataParamsReturn('/Processing/GetProductionLines', {}, abortController));
            promarr.push(await getAPIDataParamsReturn('/Processing/GetActiveProcessRuns', { WarehouseId: location, Date: startDate }, abortController));
            promarr.push(await getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: location, IncludeMYOB: false, IncludeZero: false }, abortController));
            Promise.all(promarr).then((data) => {
                var pl = data[0];
                var activeRuns = data[1];
                var inv = data[2];
                var prodLines = pl.filter((p) => {
                    return p.isHalfshell === false && p.warehouseId === location && p.isCurrent;
                });
                setEquipments(prodLines);
                var relevantRuns = activeRuns.filter((ar) => { return prodLines.find((prdL) => { return prdL.id === ar.processLineId }); });
                relevantRuns = relevantRuns.map((rr) => {
                    var ll = { ...rr };
                    ll.status = 'Processing';
                    ll.processingStatus = 'Started';
                    ll.expiryDate = ll.coreInventory?.expiryDate;
                    ll.supplier = ll.coreInventory?.receival?.supplier?.name;
                    ll.processRunId = ll.id;
                    ll.amount = ll.quantity;
                    var invTotalItem = inv.find((i) => { return (i.lotSerialNbr === ll.lotSerialNbr) && (i.stockItemId === ll.stockItemId) && (i.expiryDate === ll.expiryDate) });
                    ll.totalAvailable = ll.quantity + (invTotalItem ? invTotalItem.portalQuantity : 0);
                    ll.processRun = {
                        staff: ll.staff,
                        comment: ll.comment,
                        staffMemberId: ll.staffMemberId,
                        processLineId: ll.processLineId,
                    }
                    // ll.canStart = true;
                    return ll;
                });
                console.log(relevantRuns);
                var selectInv = inv.filter((i) => { return !i.stockItem?.isProcessed && i.warehouseId === location && !i.stockItem?.isHalfshell });
                selectInv = selectInv.map((si) => {
                    var ll = { ...si };
                    ll.status = 'Process';
                    ll.processingStatus = 'Not started';
                    ll.quantity = si.portalQuantity;
                    ll.canStart = true;
                    ll.supplier = si.supplier?.name;
                    ll.amount = si.portalQuantity;
                    ll.totalAvailable = si.portalQuantity;
                    return ll;
                });
                var lines = [...relevantRuns, ...selectInv];

                setProcurement(lines);
                setLoading(false);

            }).catch((e) => { console.log(e); });

            //get receival
            // setProcurement(data);
            // setOriginalOrderString(getOrderString(data));
        }
    }
    const unlockLock = (stat) => () => {
        setUnlock(stat);
    }
    const saveOrder = () => {
        //post api
        setLoading(true);
        setSavingOrder(true);
        postApi('/Processing/UpdateSchedule', { WarehouseId: props.location, Date: textfieldDateFormat(props.startDate), Ids: getOrderString(procurement) }).then(async (resp) => {
            if (resp.ok) {
                var data = await resp.json()
                setProcurement(data);
                setSavingOrder(false);
                setLoading(false);
                setOriginalOrderString(getOrderString(data));

            }
        }).catch((e) => { console.log(e); });

    }
    const processOysters = (data) => (e) => {
        if (!unlock) {
            setOpen(true);
            setSelectedLine(data);
        }
        console.log(data);
    }
    const addAdminComment = (data) => (e) => {
        setCommentOpen(true);
        setSelectedLine(data);
    }
    const handleClose = () => {
        setOpen(false);
        setReload(!reload);
        props.reloadPage();
        setProcessed(false);
    }
    const selectInv = (e) => {
        setOpenInv(true);
    }
    const handleCloseInv = () => {
        setOpenInv(false);
        setReload(!reload);
        props.reloadPage();
        setProcessed(false);
    }
    const handleCloseComment = () => {
        setCommentOpen(false);
        setReload(!reload);
    }
    const useInProcess = (data) => (row, index) => {
        var selData = data[index.dataIndex];
        selData = {
            ...selData,
            processingStatus: 'Not started',
            stockItem: selData.stockItem?.description,
            stockItemId: selData.stockItem?.id,
            amount: selData.portalQuantity,
            quantity: selData.portalQuantity,
            supplier: selData.supplier?.name,
            totalAvailable: selData.portalQuantity
        };
        processOysters(selData)();
    }
    // const filteredProcurement = procurement?.filter((p) => {
    //     if (p.processRun) {
    //         var line = equipments.find((e) => { return e.id === p.processRun?.processLineId });
    //         if (line) {
    //             return !line.isHalfshell;
    //         }
    //     }
    //     return true;
    // });
    return (
        <JumboCardQuick
            title={<div>Daily Runs {checkAccess(props.access, requiredEditAccess) ? <React.Fragment><IconButton onClick={unlockLock(!unlock)}>{unlock ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}</IconButton></React.Fragment> : null}<IconButton onClick={() => { setReload(!reload) }} ><RefreshIcon /></IconButton><Button variant={'outlined'} onClick={selectInv} >Select From Inventory</Button></div>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >
            <InvTable {...props} QAList={QAList} data={procurement} onSortEnd={onSortEnd} useDragHandle={true} unlock={unlock} processOysters={processOysters} updateProcurement={updateProcurement} addAdminComment={addAdminComment} loading={loading} equipments={equipments} />
            <FormModal open={open} handleClose={handleClose} title={"Process Task"} fixedWidth>
                <ProcessStockForm onlyLive allStock={true} processed={processed} access={props.access} handleClose={handleClose} selectedLine={selectedLine} scheduleList={procurement} warehouseId={props.location} />
            </FormModal >
            {/* <FormModal open={commentOpen} handleClose={handleCloseComment} title={"Note"} fixedWidth >
                <CommentBox access={props.access} handleClose={handleCloseComment} selectedLine={selectedLine} />
            </FormModal > */}
            <FormModal open={openInv} handleClose={handleCloseInv} title={"Process Task"} fixedWidth>
                <SelectInventoryForm onClick={useInProcess} access={props.access} handleClose={handleCloseInv} warehouseId={props.location} showType />
            </FormModal >
        </JumboCardQuick>

    );
};
const removeRL = (inp) => {
    if (inp.includes('RL')) {
        return inp.replace('RL', '');
    } else {
        return inp;
    }
}
const CommentBox = (props) => {
    const [comment, setComment] = React.useState(props.selectedLine.comment);
    const [loading, setLoading] = React.useState(false);
    const saveComment = () => {
        setLoading(true);
        postApi('/Processing/UpdateAdminComment', { Id: removeRL(props.selectedLine.id), Comment: comment }).then(async (resp) => {
            if (resp.ok) {
                var data = await resp.json()
                props.handleClose();
            }
        }).catch((e) => { console.log(e); });
    }
    return (
        <React.Fragment>
            <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                fullWidth
                value={comment}
                onChange={(e) => { setComment(e.target.value) }}
                variant="outlined"
            />
            <Button onClick={saveComment} variant="contained">{loading ? <CircularProgress /> : 'Save'}</Button>
        </React.Fragment>
    );
}
const getTotalsInfo = (data, type) => {
    return data?.reduce((p, c) => {
        if (c.stockItem?.description.includes(type)) {
            if (c.status !== DoNotProcess) {
                p += c.quantity;
            }
        }
        return p;
    }, 0);
}
const InvTable = SortableContainer((props) => {
    var data = props.data;
    console.log(data);
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>

                        </TableCell>
                        <TableCell>
                            Lot Nbr
                        </TableCell>
                        <TableCell>
                            Supplier
                        </TableCell>
                        <TableCell>
                            ExpiryDate
                        </TableCell>
                        <TableCell>
                            Grade
                        </TableCell>
                        <TableCell>
                            Production Line
                        </TableCell>
                        <TableCell>
                            Quantity (Doz)
                        </TableCell>
                        <TableCell>
                            QA
                        </TableCell>
                        {/*<TableCell>*/}
                        {/*    Est. App*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*    Est. Premium*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*    Est. Loss*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*    Graded/Ungraded*/}
                        {/*</TableCell>*/}
                        <TableCell>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!props.loading ? data?.map((dt, index) => (
                        <InvRow key={index} index={index} ind={index} data={dt} QAInfos={props.QAList.filter((q)=>(q.lotSerialNbr===dt.lotSerialNbr))} addAdminComment={props.addAdminComment} processOysters={props.processOysters} unlock={props.unlock} updateProcurement={props.updateProcurement} equipments={props.equipments} />
                    )) : <CircularProgress />}
                    <TableRow style={{ zIndex: 1000 }} >
                        <TableCell sx={{ pl: 3 }}>
                        </TableCell>
                        <TableCell sx={{ pl: 3 }} colSpan={10} >
                            <Grid container spacing={2} mb={1}>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div" style={{ fontWeight: 'bold' }} >
                                        Totals:
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Cocktail: {getTotalsInfo(data, 'Cocktail')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Bottle: {getTotalsInfo(data, 'Bottle')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Bistro: {getTotalsInfo(data, 'Bistro')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h4" gutterBottom component="div">
                                        Plate: {getTotalsInfo(data, 'Plate')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </TableCell>

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
});
const DoNotProcess = 'Do Not Process';
const OnHold = 'On Hold';
const Process = 'Process';
const InvRow = props => {

    const { data } = props;
    var status = data?.doNotProcess ? DoNotProcess : Process;
    const [starred, setStarred] = React.useState(data.starred);
    const { theme } = useJumboTheme();
    var hasStarted = false;
    var processTypeList = ['Grade, Wash, Count', 'Wash, Count'];
    if (!(data?.bins && data?.bins > 0)) {
        processTypeList.push('Rebrand');
    }

    var getCol = theme.palette.oysterProducts.open;
    if (data.processingStatus === 'Started') {
        hasStarted = true;
        getCol = theme.palette.oysterProducts.shipping;

    } else if (!data?.canStart) {
        getCol = theme.palette.oysterProducts.onHold;

    }
    var clickable = data?.canStart || hasStarted;
    if (status === DoNotProcess) {
        getCol = theme.palette.oysterProducts.cancelled;
        clickable = false;
    } else if (status === OnHold) {
        getCol = theme.palette.oysterProducts.onHold;
        clickable = false;
    }
    //if ((data.status === Process)&& !data?.canStart) {
    //    status = 'Not Received';
    //}
    const remSS = (inp) => {
        if (inp.includes('Single Seed')) {
            return inp.replace('Single Seed', '');
        } else {
            return inp;
        }
    }
    return (
        //row colour based on status refer to customer order shet
        <TableRow style={{ zIndex: 1000, background: getCol }} onClick={clickable ? props.processOysters(data) : () => { }} >
            <TableCell sx={{ pl: 3 }}>

                <Typography variant={"h6"} mb={0}>{props.ind + 1}</Typography>

            </TableCell>
            <TableCell >

                <Typography variant={"h6"} mb={0}>{data?.lotSerialNbr}</Typography>

            </TableCell>
            <TableCell >

                <Typography variant={"h6"} mb={0}>{data?.supplier}</Typography>

            </TableCell>
            <TableCell >

                <Typography variant={"h6"} mb={0}>{dateFormat(data?.expiryDate)}</Typography>

            </TableCell>
            <TableCell >

                <Typography variant={"h6"} mb={0}>{remSS(data.stockItem?.description)}</Typography>

            </TableCell>
            <TableCell >

                <Typography variant={"h6"} mb={0}>{props.equipments.find((e) => { return e.id === data?.processLineId })?.description}</Typography>

            </TableCell>
            <TableCell >

                <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{numberFormat(data?.quantity)}</Typography>

            </TableCell>
            <TableCell >
                {props.QAInfos.map((q)=>{
                    return <ViewQAButton access={props.access} id={q.qaId} recId={q.receivalId} />
                })}
            </TableCell>
            {/*<TableCell >*/}
            {/*    */}
            {/*        <Typography variant={"h6"} mb={0}></Typography>*/}
            {/*    */}
            {/*</TableCell>*/}
            {/*<TableCell >*/}
            {/*    */}
            {/*        <Typography variant={"h6"} mb={0}></Typography>*/}
            {/*    */}
            {/*</TableCell>*/}
            {/*<TableCell >*/}
            {/*    */}
            {/*        <Typography variant={"h6"} mb={0}></Typography>*/}
            {/*    */}
            {/*</TableCell>*/}
            {/*<TableCell >*/}
            {/*    <Typography variant={"h6"} mb={0}>{!props.unlock || hasStarted ? (data?.graded) : <SelectInTable key={props.ind + 'e'} api='UpdateGraded' id={data?.id} list={['Graded', 'UnGraded']} state={data?.graded} updateProcurement={props.updateProcurement(props.ind, 'graded')} />}</Typography>*/}

            {/*</TableCell>*/}

            <TableCell >
                <Typography variant={"h6"} mb={0}>{!props.unlock || hasStarted ? (status) : <SelectInTable key={props.ind + 'a'} api='ChangeProcessStatus' id={data?.id} data={data} list={[Process, DoNotProcess]} state={status} updateProcurement={props.updateProcurement(props.ind, 'doNotProcess')} />}</Typography>
            </TableCell>

        </TableRow>
    );
};

const SelectInTable = (props) => {
    const [value, setValue] = React.useState(props.state);
    const [loading, setLoading] = React.useState(false);
    const changeValue = async (state) => {
        setValue(state);
        props.updateProcurement(state==='Do Not Process'?true:false)
        setLoading(true);
        var params = {};
        if (props.api === 'ChangeProcessStatus') {
            params = { LotSerialNbr: props.data.lotSerialNbr,StockItemId:props.data.stockItemId,ExpiryDate:textfieldDateFormat(new Date(props.data.expiryDate)), DoNotProcess: (state===DoNotProcess?true:false) };
        
        }
        postApi('/Processing/' + props.api, params).then(() => {
            setLoading(false);
        })
    }
    if (loading) {
        return <CircularProgress />
    } else {
        return <SelectTextField key={'si' + props.id} title="" state={value} nameFunc={(n) => (n)} list={props.list} setState={changeValue} disabled={props.disabled} />
    }
}

export default PurchaseOrders;
