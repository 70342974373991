import React from 'react';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { WAREHOUSESTRINGS } from "../utils/constants/warehouse";
import { getAPIData } from 'app/utils/apiFunction';

const WarehouseToggle = (props) => {
    const [warehouses, setWarehouses] = React.useState(null);
    const [selection, setSelection] = React.useState(props.location);
    React.useEffect(() => {
        getAPIData('/Warehouses/Get', setWarehouses);
    }, [])

    if(warehouses && selection){
        props.setLocation(warehouses.find((wh) => { return wh.myobid === selection }));
    }

    const switchWH = (event, newselection) => {
        setSelection(newselection);
        if(!newselection){
            props.setLocation(null);
        }
    }
    return (
        <ToggleButtonGroup
            color="primary"
            exclusive={props.multipleChoices ? false : true}
            disabled={props.disabled}
            value={selection}
            onChange={switchWH}
            aria-label="text alignment"
        >
            <ToggleButton key={0} value={null} >
                <div  >All</div>
            </ToggleButton>
            <ToggleButton key={2} value={WAREHOUSESTRINGS.BBayLive} >
                <div  >Batemans Bay</div>
            </ToggleButton>
            <ToggleButton key={1} value={WAREHOUSESTRINGS.Sydney} >
                <div  >Sydney</div>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
export default WarehouseToggle;