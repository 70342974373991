import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { numberFormat } from "../../../utils/numberFunctions";
import Div from "@jumbo/shared/Div";
import { getAPIData, getAPIDataReturn, getAPIDataParams, getAPIDataParamsReturn, postApi } from '../../../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
//import 'jspdf-autotable'
import DateSelector from "../../../shared/DateSelector";
import { adalApiFetch } from '../../../../adalConfig';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from "@mui/material/Button";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ArticleIcon from '@mui/icons-material/Article';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import ControlledTextField from "../../../components/ControlledTextField";
import { CircularProgress } from '@mui/material';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import { saveLabel } from '../../../utils/fulfilment/labels';
import { Warehouse } from '@mui/icons-material';
const outputStruct = { prn: null, quantity: null, units: null };
const FulfilmentForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const Swal = useSwalWrapper();

    const [outputs, setOutputs] = React.useState(null);
    const [notEnoughInventory, setNotEnoughInventory] = React.useState(false);
    const [saleItem, setSaleItem] = React.useState(null);
    const [delLoading, setDelLoading] = React.useState(false);
    const [noShipment, setNoShipment] = React.useState(null);
    const [salesItems, setSalesItems] = React.useState(null);
    const [OrderColumns, setOrderColumns] = React.useState([]);
    const [date, setDate] = React.useState(new Date());
    const [existingPRNs, setExistingPRNs] = React.useState([{}]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredMYOBAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.MYOB };
    const requiredMasterAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.MASTER };
    const requiredSalesAdminAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.ADMIN };
    var submitToMyobAccess = checkAccess(props.access, requiredMYOBAccess);
    const [recs, setRec] = React.useState(null);
    const [NSIQuantity, setNSIQuantity] = React.useState([]);
    const [loadingOnCreate, setLoadingOnCreate] = React.useState(false);
    const [errorOnCreate, setErrorOnCreate] = React.useState(null);
    const [inventory, setInventory] = React.useState(null);
    const [warehouseId, setWarehouseId] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [alreadyCompleted, setAlreadyCompleted] = React.useState(false);
    var allComplete = true;
    const id = props.rowData?.salesOrderId
    React.useEffect(() => {
        //postApi('/Fullfillment/ShipmentExists', { SalesOrderId: id }).then(async (result) => {
        //    var test = await result.json();
        //    if (test) {
        getAPIDataReturn('/SalesOrder/Get/' + id).then((result) => {
            if (result && result[0]) {
                if (result[0].status === 'Completed') {
                    setAlreadyCompleted(true);
                } else {
                    setAlreadyCompleted(false);
                }
            }
        });
        getAPIDataReturn('/Warehouses/Get').then((result) => {
            var NSIQList = [];
            if (props.displayOptions?.length === 1) {
                var wId = result.find((w) => (w.myobid === props.displayOptions[0].warehouse)).id;
                setWarehouseId(wId);
                getAPIDataParamsReturn('/Fullfillment/Preview', { SalesOrderId: id, WarehouseId: wId }).then((result) => {
                    var invPrmArr = [];
                    setSalesItems(result);
                    for (var i = 0; i < result.lines.length; i++) {
                        NSIQList.push(result.lines[i].isNonStockItem ? (result.lines[i].fulfillNonStockQuantity ? result.lines[i].fulfillNonStockQuantity : result.lines[i].orderedQuantity) : null);
                        var line = result.lines[i];
                        for (var j = 0; j < line.details.length; j++) {
                            invPrmArr.push(getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: wId, IncludeMYOB: true, LotNbrSubString: line.details[j].lotSerialNbr, StockItemId: line.details[j].stockItemId }));
                        }
                    }
                    setNSIQuantity(NSIQList);
                    Promise.all(invPrmArr).then((inv) => {
                        setInventory(inv.reduce((p, c) => { return (c ? (p.concat(c)) : p) }, []));
                        setLoadingOnCreate(false);
                        if (checkAccess(props.access, requiredMasterAccess)) {
                            setNotEnoughInventory(false)
                        }

                    })
                });

            }
        });

        //} else {
        //    setNoShipment(true);
        //}
        //});
    }, [reload]);

    const createStock = (details, old, newA) => async () => {
        console.log(details, old, newA)
        if (newA) {
            const params = {
                lotSerNbr: details.lotSerialNbr,
                ExpiryDate: textfieldDateFormat(new Date(details.expiryDate)),
                StockItemId: details.stockItemId,
                NewAmount: newA,
                WarehouseId: warehouseId,
                OldAmount: old,
                ReasonCode: 'ADJQTYVAR',
                Description: 'AOC Portal - Fulfilment Write Up - salesOrderId: ' + id
            }
            setLoadingOnCreate(true);
            var respArr = [await postApi('/Processing/AdjustQuantity', params)];

            if (respArr.reduce((p, c) => (p && c.ok), true)) {
            } else {
                setErrorOnCreate("Failed to submit");
            }
            setReload(!reload);
        }
    }
    if (salesItems) {
        salesItems.lines.map((si) => {
            if (!si.isNonStockItem) {
                var fulfil = si.details.reduce((p, c) => (p + c.quantity), 0);
                if (fulfil < si.orderedQuantity) {
                    allComplete = false;
                }
            }
        });
    }

    const NSIQuantUpdate = (index) => (value) => {
        var newNSI = [...NSIQuantity];
        newNSI[index] = Number(value);
        setNSIQuantity(newNSI);
    }

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];

        for (var i = 0; i < salesItems?.lines.length; i++) {
            if (salesItems.lines[i].isNonStockItem) {
                warningMessages.push('Have you entered the correct values for non stock items? (Knives, Bagfee, etc)');
            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    setLoading(true);
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            setLoading(true);
            //setCompleted(true);
            submit();
        }
    }
    const markComplete = async () => {
        console.log(salesItems)
        await postApi('/Fullfillment/MarkComplete', {
            SalesOrderId: id,
            WarehouseId: warehouseId
        });
        //saveLabel(newOuts, saleItem, codeInfo);
        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }

    }
    const submit = async () => {
        for (var i = 0; i < NSIQuantity.length; i++) {
            if ((NSIQuantity[i] !== null) && (NSIQuantity[i] !== '')) {
                await postApi('/Fullfillment/SetNonStockQty', {
                    SalesOrderLineId: salesItems?.lines[i].id, Quantity: NSIQuantity[i]
                });
            }
        }

        await postApi('/Fullfillment/Complete', {
            SalesOrderLineIds: salesItems?.lines.reduce((p, c) => { return ((p === '') ? '' : (p + ',')) + String(c.id) }, '')
        });
        //saveLabel(newOuts, saleItem, codeInfo);


        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }

    }
    const deleteShipment = async () => {
        setDelLoading(true);
        postApi('/Fullfillment/ReverseShipment', {
            SalesOrderId: id,
            WarehouseId: warehouseId
        }).then((result) => {
            setDelLoading(false);
            props.handleClose();
            if (props.reload) {
                props.reload();
            }
        });
    }
    var allDetailLines = salesItems ? salesItems?.lines.reduce((p, c) => { return p.concat(c.details) }, []) : [];
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                {/*<TextField*/}
                {/*    disabled*/}
                {/*    id="outlined-required"*/}
                {/*    label="Date"*/}
                {/*    value={dateFormat(date)}*/}
                {/*    InputLabelProps={{*/}
                {/*        shrink: true,*/}
                {/*    }}*/}
                {/*/>*/}
            </Div>

            <Div>



                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} >
                        <Typography variant={"h1"} mb={0}>{props.rowData?.customer?.name}</Typography>
                        <Typography variant={"h3"} mb={0}>Order No: {props.rowData?.orderNo}</Typography>
                    </Grid>
                    {salesItems?.lines?.filter((a) => (a.orderedQuantity !== 0)).map((siL, i) => {
                        return <Grid item xs={12} >
                            <Grid container spacing={2} mb={1} style={{ borderBottom: '1px solid black', margin: '5px' }} >
                                <Grid item xs={12} md={6} >
                                    {/*One line that shows the order*/}
                                    <Typography variant={"h2"} mb={0}>{siL?.stockItemDescription + '      ' + siL?.stockItem}</Typography>
                                    {/*<Typography variant={"h4"} mb={0}>{codeInfo?.gradeCode + (codeInfo?.traySize ? (' (' + (codeInfo?.traySize) + ')') : '')}</Typography>*/}
                                    {/*<Typography variant={"h5"} mb={0}>{saleItem.code}</Typography>*/}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant={"h3"} mb={0}>Sales Order Total: {numberFormat(siL.orderedQuantity)}</Typography>
                                    {!siL.isNonStockItem ? <Typography variant={"h3"} mb={0}>Fulfilled Total: {numberFormat(siL.details.reduce((p, c) => (p + c.quantity), 0))}</Typography> :
                                        <React.Fragment>
                                            <Typography variant={"h3"} mb={0}>Fulfilled Total:</Typography>
                                            <ControlledTextField label={'Fulfilled Total'} state={NSIQuantity[i]} setState={NSIQuantUpdate(i)} disabled={completed} number />
                                        </React.Fragment>}
                                </Grid>
                                {
                                    siL.details?.map((a, i) => {
                                        var nEI = false
                                        if (inventory && props.forShipping) {
                                            var invTotal = inventory?.find((inv) => ((inv.lotSerialNbr === a.lotSerialNbr) && (inv.stockItemId === a.stockItemId) && (inv.expiryDate === a.expiryDate)));
                                            var allSameStock = allDetailLines.filter((b) => ((b.stockItemId === a.stockItemId) && (b.lotSerialNbr === a.lotSerialNbr) && (b.expiryDate === a.expiryDate)));
                                            var totalOfthatStock = allSameStock.reduce((p, c) => (p + c.quantity), 0);
                                            if ((!invTotal && (a.quantity > 0)) || ((invTotal?.myobQuantity < totalOfthatStock) && (a.quantity > 0))) {
                                                nEI = true;
                                                if (!notEnoughInventory) {
                                                    setNotEnoughInventory(true);
                                                }
                                            }
                                        }
                                        return (
                                            <Grid item xs={12} key={i}>
                                                <Grid container spacing={2} mb={1}>
                                                    <Grid item xs={4} >
                                                        <Typography variant={"h4"} mb={0}>{a.supplier + '\u00A0\u00A0' + a.lotSerialNbr}</Typography>
                                                        <Typography variant={"h4"} mb={0}>{a.stockItemDescription}</Typography>
                                                        {nEI && !completed ? <Typography variant={"h4"} style={{ color: 'red' }} mb={0}>Not enough inventory in MYOB (Only {invTotal?.myobQuantity ? invTotal?.myobQuantity : 0} available), please see manager</Typography> : null}
                                                        {nEI && !completed && checkAccess(props.access, requiredMasterAccess) ? (errorOnCreate ? errorOnCreate : (loadingOnCreate ? <CircularProgress /> : <Button onClick={createStock(a, (invTotal?.myobQuantity ? invTotal?.myobQuantity : 0), totalOfthatStock)}>Create Additonal {totalOfthatStock - (invTotal?.myobQuantity ? invTotal?.myobQuantity : 0)} doz</Button>)) : null}
                                                    </Grid>
                                                    <Grid item xs={4} >
                                                        <Typography variant={"h4"} mb={0}>{'Quantity: ' + a.quantity}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>)
                                    })
                                }
                            </Grid>
                        </Grid>
                    })}
                    {/*<StaffMember staffId={loadedQA?.staffMemberId} driver={driver} setDriver={setDriver} disabled={completed} />*/}
                    <Grid item xs={12} >
                        {!allComplete && !completed ? <Typography variant={"h4"} style={{ color: 'red' }} mb={0}>Not all orders have been fulfilled. Are you sure you want to submit?</Typography> : null}
                        {/*{noShipment ? <Typography variant={"h4"} style={{ color: 'red' }} mb={0}>No shipment has been created for this order. Talk to IT.</Typography> : null}*/}
                    </Grid>


                    {console.log(noShipment, notEnoughInventory)}
                    {submitToMyobAccess && props.forShipping ?
                        <Grid item xs={12} >
                            {notEnoughInventory || alreadyCompleted ? <SubmitButtonDisplayer completed={completed} title={alreadyCompleted ? 'Already Submitted Close and Refresh' : 'Close'} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={props.handleClose} handleClose={props.handleClose} /> :
                                (salesItems && inventory && !notEnoughInventory ? <SubmitButtonDisplayer completed={completed} title={'Submit to MYOB'} loading={loading} allowed={checkAccess(props.access, requiredMYOBAccess)} submitFunc={validate} handleClose={props.handleClose} /> : <CircularProgress />)}
                        </Grid> :
                        
                    <Grid item xs={12} >
                        <SubmitButtonDisplayer completed={completed} title={'Mark as Complete'} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={markComplete} handleClose={props.handleClose} />
                    </Grid>}
                    { checkAccess(props.access, requiredSalesAdminAccess)&&props.hasBeenShipped&&props.completed?<Grid item xs={12} >
                        {!delLoading?<Button onClick={deleteShipment}>Delete this Shipment</Button>:<CircularProgress/>}
                    </Grid>:null}
                </Grid>


            </Div>
        </Box >
    );
};



export default FulfilmentForm;
