import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import SalesOrdersCreate from './FunctionalComp/SalesOrdersCreate';
import SalesOrdersEditable from './Tables/SalesOrdersEditable';
import FormModal from '../../components/FormModal';
import ProjectSales from './Forms/ProjectSales';
import SalesOrdersComp from './FunctionalComp/SalesOrders'
import { checkAccess } from '../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
const SalesOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(false);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };

    const reloadFunc = () => {
        setReload(!reload);
    }

    const createPO = (ser) => () => {
        setOpen(true);
        setEdit(false);
        setData(null);
        setSeries(ser);
    }
    
    const viewPO = (array) => (rowd, rowState) => {
        setData(array[rowState.dataIndex]);
        setOpen(true);
        setEdit(false);
        setCompleted(true);
    }
   
    return (
        <React.Fragment>
            
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>*/}
                {/*    {checkAccess(props.access, requiredEditAccess) ?*/}
                {/*        <RollOutButton {...props} />:*/}
                {/*        null}*/}
                {/*</Grid>*/}
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    {/*<WarehouseSelector location={location} setLocation={setLocation} />*/}
                </Grid>
            </Grid>
            
            <Grid container spacing={3.5}>

                <Grid item xs={12}>
                    {checkAccess(props.access, requiredEditAccess) ?
                        <SalesOrdersCreate access={props.access} reloadFunc={reloadFunc} reload={reload} createOne/> :
                        null}
                </Grid>
                <Grid item xs={12}>
                    <SalesOrdersEditable location={location} startDate={startDate} endDate={endDate} access={props.access} onClick={viewPO}  reloadFunc={reloadFunc} reload={reload} dashboard summary/>
                </Grid>
        </Grid>
        </React.Fragment >
    );
};

const RollOutButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);

    const openForm = () => {
        setOpen(true);

    }
    const handleClose = () => {
        setCompleted(false);
        setOpen(false);
    }


    return <React.Fragment>
        <FormModal open={open} handleClose={handleClose} title={"Project Sales"} >
            <ProjectSales access={props.access} reload={props.reloadFunc} handleClose={handleClose} completed={completed} />
        </FormModal>
        <Button onClick={openForm} variant="contained" style={{ marginBottom: '5px' }}>{"Project Sales forward"}</Button>

    </React.Fragment>
}

export default SalesOrdersPage;
