import React from 'react';
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

const CustomerSelect = (props) => {
    const [farmr, setFarmr] = React.useState([]);
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');
    const filterVals = (lists) => {
        var list = lists;
        setFarmr(list.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        }))
    }
    React.useEffect(() => {

        if (props.frmrId) {
            var est = farmr.find((dr) => { return (dr.id === props.frmrId) });
            props.setState(est);
            setValue(est);
        }
    }, [farmr, props.frmrId])
    React.useEffect(() => {
        getAPIData('/Customers/Get', filterVals);
    }, [])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) =>( option.myobid+' - '+ option.name)}
        disablePortal
        id="farmrbox"
        options={farmr}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Customer" />}
    />);



}
export default CustomerSelect;