import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { startOfWeek, endOfWeek, textfieldDateFormat } from "../../../utils/dateFunctions";
import { getAPIDataReturn, getAPIDataParamsReturn, postApi, getAPIData, getAPIDataParams } from "../../../utils/apiFunction";
import { numberFormatwithNeg } from "../../../utils/numberFunctions";
import Div from "@jumbo/shared/Div";
import { adalApiFetch } from '../../../../adalConfig';
import HistoryIcon from '@mui/icons-material/History';
import ArticleIcon from '@mui/icons-material/Article';
import ProcessStockForm from './ProcessStockForm';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { saveLabel } from '../../../utils/processing/labels';
import FormModal from '../../../components/FormModal';
import SelectInventoryForm from '../../Processing/Forms/SelectInventoryForm';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import DropZone from "../../../components/DropZone";
import { SelectTextFieldNew } from "../../../components/SelectTextField";
import ControlledTextField from "../../../components/ControlledTextField";
import DriverSelect from "../../../components/DriverEntry";
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import StaffMember from "../../../components/StaffMember";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { number } from 'prop-types';
import { getOysterGrades,gradeSort } from "../../../definitions/oysters";
import { CircularProgress } from '@mui/material';
import { WAREHOUSEMATCHES } from '../../../utils/constants/warehouse';
import HalfShellStartProcessStockForm from './HalfShellStartProcessStockForm';
import { isNotNullorUndefined } from '../../../utils/appHelpers';
import FinishStaffFromBench from './FinishStaffFromBenchForm';
import DeleteCurrentRun from '../../../components/DeleteCurrentRun';

const HalfshellProcessForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const hasStarted = !(props.selectedLine?.processingStatus === 'Not started');
    const [totalToAdd, setTotalToAdd] = React.useState(0);
    const [completed, setCompleted] = React.useState(props.completed);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [oystersIn, setOystersIn] = React.useState([{}]);
    const [currentOutputs, setCurrentOutputs] = React.useState(null);
    const [traySize, setTraySize] = React.useState(1);//get prev used tray size
    const [lidOff, setLidOff] = React.useState(0);
    const [stockItems, setStockItems] = React.useState(null);
    const [stockItem, setStockItem] = React.useState(null);
    const [openCFD, setOpenCFD] = React.useState(false);
    const [openInv, setOpenIv] = React.useState(false);
    const [editBench, setEditBench] = React.useState(null);
    const [openAddInv, setOpenAddInv] = React.useState(false);
    const [grades, setGrades] = React.useState([]);
    const [showDelete, setShowDelete] = React.useState(false);
    const [showHistory, setShowHistory] = React.useState(false);
    const Swal = useSwalWrapper();
    const [equipment, setEquipment] = React.useState(null);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const [reload, setReload] = React.useState(false);
    const [selectedBench, setSelectedBench] = React.useState(props.selectedBench);
    const [prOutputs, setPROutputs] = React.useState(null);
    const buildSelectedBench = (data) => {
        setSelectedBench({ ...data, benchDetails: props.selectedBench.benchDetails });
    }

    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(data);
        });
    }, []);
    //Reload this page
    React.useEffect(() => {
        getAPIData('/StockItems/Get', setStockItems);
    }, []);
    console.log(props.selectedBench)
    React.useEffect(() => {
        getAPIDataReturn('/Processing/GetProcessRun/' + props.selectedBench.id).then((result) => {
            buildSelectedBench(result);
            //getAPIDataParamsReturn('/Processing/GetProcessingOutputs', { ProcessRunId: result.id }).then((data) => {
            //    setCurrentOutputs(data);
            //}).catch((error) => {
            //    console.log(error);
            //});
        });
        getAPIDataParams('/Processing/GetTodaysLiveOutputs', setPROutputs, { WarehouseId: props.warehouse?.id, StaffMemberId: props.selectedBench.staffMemberId, Date: textfieldDateFormat(props.startDate) })
    }, [reload]);

    React.useMemo(() => {
        setStockItem(selectedBench.stockItem?.grade ? grades?.find((ogn) => { return ((ogn.id === selectedBench.stockItem?.grade?.id) && (ogn.speciesId === selectedBench.stockItem?.species?.id)) }) : null);
    }, [selectedBench,grades]);

    const validate = async () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)
        var wipStockCode = generateStockCode(stockItem, traySize,lidOff);
        var currentProcessingOutputs = await getAPIDataParamsReturn('/Processing/GetProcessingOutputs', { ProcessRunId: props.selectedBench.id });
        console.log(selectedBench.quantity, totalToAdd, generateTotalToAdd(totalToAdd, traySize, currentProcessingOutputs, wipStockCode, true))
        if (selectedBench.quantity < generateTotalToAdd(totalToAdd, traySize, currentProcessingOutputs, wipStockCode, false)) {
            warningMessages.push("There is more output than input. Remember to add more input to this bench.");
        }
        //output must be less than = to input


        if (totalToAdd <= 0) {
            errorMessages.push("You must select more than 0");
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit(currentProcessingOutputs);
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit(currentProcessingOutputs);
        }
    }
    const submit = async (CPO) => {
        var allOk = false;
        var respArr = [];
        setLoading(true);

        var wipStockCode = generateStockCode(stockItem, traySize,lidOff);
        var params = {
            ProcessRunId: selectedBench.id,
            StockItemId: wipStockCode,
            NoOfUnits: generateTotalToAdd(totalToAdd, traySize, CPO, wipStockCode, false),
            Amount: generateTotalToAdd(totalToAdd, traySize, CPO, wipStockCode, true),
        }
        respArr.push(await postApi('/Processing/AddUpdateProcessingOutput', params));
        setLoading(false);
        saveLabel(selectedBench, stockItem, totalToAdd, totalToAdd * traySize, false);
        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            props.handleClose();
        } else {
            setError("Failed to submit");
        }

    }
    const generateTotalToAdd = (trays, traySiz, CPO, wipStockCode, oysters) => {
        var totalExisting = CPO.reduce((p, c) => {
            if (wipStockCode === c.stockItemId) {
                if (oysters) {
                    return p + c.quantity;
                } else {
                    return p + c.noOfUnits;
                }
            } else {
                return p;
            }
        }, 0);
        return Number(oysters ? generateTotal(trays, traySiz) : trays) + Number(totalExisting);
    }
    const remSR = (name) => {
        if (name && name.description) {
            return name.description.replace('Sydney Rock', '');
        } else {
            return '';
        }
    }
    const addToTotal = (value) => {
        setTotalToAdd((totalToAdd ? totalToAdd : 0) + value);
    }
    const clearTotal = (value) => {
        setTotalToAdd(0);
    }
    const openSelectInv = () => {
        setOpenIv(true);
    }
    const openCompleteFD = () => {
        setOpenCFD(true);
    }
    const openAddInvPage = () => {
        setOpenAddInv(true);
    }
    const handleCloseAddInv = () => {
        setOpenAddInv(false);
        setReload(!reload)
    }
    const handleCloseCFD = () => {
        setOpenCFD(false);
        setReload(!reload)
        props.handleClose();
    }
    const handleCloseInv = () => {
        setOpenIv(false);
        setReload(!reload)
        props.handleClose();
    }
    const handleCloseHistory = () => {
        setShowHistory(false);
        setReload(!reload)
    }
    const handleCloseDelete = () => {
        setShowDelete(false);
        props.handleClose();
        setReload(!reload)
    }

    const addToPRN = (list) => (value, indexs) => {
        var dataIndex = indexs.dataIndex;
        var rowIndex = indexs.rowIndex;
        console.log(list, value)
        //add extra to bench
        //reload form
        handleCloseInv();
    }
    const generateStockCode = (selStockItem, traySiz,LO) => {
        //check that all stockitems are loaded
        if (stockItems) {
            return stockItems.find((si) => { return si.myobid === (selStockItem.abv + (LO===1?'LO':'HS') + (traySiz === 1 ? '1D' : '2H')) })?.id;
        }
    }
    const generateTotal = (trays, traySiz) => {
        var dozCount = (trays * (traySiz ? traySiz : 0));
        return dozCount;
    }
    const historyEdit = (index) => {
        setEditBench({ ...prOutputs[index] });
        setShowHistory(!showHistory);
    }
    const deleteCurrentRun = (index) => {
        setShowDelete(true);
        setEditBench({ ...prOutputs[index] });

    }
    const getOldLabel = (index, savestockitem) => {
        console.log(savestockitem, prOutputs[index])
        saveLabel({ benchDetails: selectedBench.benchDetails, ...prOutputs[index] }, { Species: savestockitem.species ? savestockitem.species.description : '', Grade: savestockitem.grade.description }, '', '', false);
    }
    var dozCount = numberFormatwithNeg(generateTotal(totalToAdd, traySize));
    console.log(stockItem)
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Typography variant={'h3'} style={{ right: '10%', position: 'absolute', fontSize: '550%' }} >{selectedBench?.benchDetails?.description}</Typography>

            <FormModal open={showHistory} handleClose={handleCloseHistory} title={"Select Inventory"} fixedWidth>
                <ProcessStockForm WIPcode={true} access={props.access} selectedLine={editBench} completed warehouseId={props.warehouse?.id} reload={reload} setReload={setReload} handleClose={handleCloseHistory} edit={true} />
            </FormModal >
            <FormModal open={openInv} handleClose={handleCloseInv} title={"Select Inventory"} fixedWidth>
                <HalfShellStartProcessStockForm access={props.access} handleClose={handleCloseInv} selectedBench={selectedBench} warehouseId={props.warehouse?.id} warehouse={props.warehouse} endProcess={isNotNullorUndefined(props.selectedBench.id)} procRun={prOutputs?.find((pr)=>(pr.id === selectedBench.id))}/>
            </FormModal >
            <FormModal open={openAddInv} handleClose={handleCloseAddInv} title={"Add to Input"} noWidth >
                <AddInventoryForm access={props.access} handleClose={handleCloseAddInv} selectedBench={selectedBench} warehouseId={props.warehouse?.id} warehouse={props.warehouse} />
            </FormModal >
            <FormModal open={showDelete} handleClose={handleCloseDelete} title={"Delete Current Process"} noWidth >
                <DeleteCurrentRun outputs={editBench} access={props.access} handleClose={handleCloseDelete} selectedBench={selectedBench} warehouseId={props.warehouse?.id} warehouse={props.warehouse} />
            </FormModal >
            <FormModal open={openCFD} handleClose={handleCloseCFD} title={"Complete for Day"} noWidth >
                <FinishStaffFromBench access={props.access} handleClose={handleCloseCFD} selectedBench={selectedBench} warehouseId={props.warehouse?.id} warehouse={props.warehouse} />
            </FormModal >
            <Div>
                {/*//comments*/}
                {/*//processing time*/}
                {/*//no of staff on line*/}

                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} sm={6} >
                        <Typography variant={'h2'}>{selectedBench?.coreInventory.lotSerialNbr}</Typography>
                        <Typography variant={'h3'}>{selectedBench?.stockItem?.description}</Typography>
                        <Typography variant={'h3'}>Input (Doz): {selectedBench.quantity}</Typography>
                        <Grid container spacing={2} mb={1}>
                            <Grid item xs={12}  >
                                <Button variant="contained" color="primary" onClick={openAddInvPage}>Add more of this PRN to bench</Button>
                            </Grid>
                            <br />
                            <br />
                            <br />
                            <br />
                            <Grid item xs={12} style={{ borderTop: '1px solid black', marginTop: '30px' }} >
                                <Button variant="contained" color="primary" onClick={openSelectInv}>Select New PRN</Button>
                            </Grid>
                            <br />
                            <br />
                            <br />
                            <br />
                            <Grid item xs={12} style={{ borderTop: '1px solid black', marginTop: '30px' }} >
                                <Button variant="contained" color="primary" onClick={openCompleteFD}>Complete For The Day</Button>
                            </Grid>
                            <Grid item xs={12} style={{ borderTop: '1px solid black', marginTop: '30px' }} >
                                <Typography variant={'h2'} style={{ textDecoration: 'underline' }} >History</Typography>

                                {prOutputs ? prOutputs.map((pr, index) => {
                                    //pr.processrunoutputs organise by stockitemid and get totals
                                    var outputs = {}
                                    for (var i = 0; i < pr.processRunOutputs.length; i++) {
                                        if (outputs[pr.processRunOutputs[i].stockItemId]) {
                                            outputs[pr.processRunOutputs[i].stockItemId].quantity = outputs[pr.processRunOutputs[i].stockItemId].quantity + pr.processRunOutputs[i].quantity;
                                        } else {
                                            outputs[pr.processRunOutputs[i].stockItemId] = {
                                                quantity: pr.processRunOutputs[i].quantity, stockItem: pr.processRunOutputs[i].stockItem
                                            }
                                        }
                                    }


                                    return <div style={{ display: 'block' }} >
                                        <IconButton style={{ paddingBottom: '15px' }} variant={'h3'} onClick={() => { historyEdit(index) }}><EditIcon /></IconButton>
                                        <Typography style={{ display: 'inline' }} variant={'h5'}>{pr.lotSerialNbr}

                                            <Typography style={{ display: 'inline', paddingLeft: '20px' }} variant={'h5'}>In: {pr.quantity}</Typography>
                                            <Typography style={{ display: 'inline', paddingLeft: '20px' }} variant={'h5'}>Out: {Object.keys(outputs).map((c) => (<span><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => getOldLabel(index, outputs[c].stockItem)} >{outputs[c].stockItem ? outputs[c].stockItem?.grade?.description : '+'}</a> :{outputs[c].quantity} </span>))}</Typography>
                                            {pr.id === selectedBench.id ? <IconButton style={{ paddingBottom: '15px' }} variant={'h3'} onClick={() => { deleteCurrentRun(index) }}>< DeleteIcon /></IconButton> : null}
                                        </Typography>
                                    </div>
                                }) : null}

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'h5'}> Tray Size:&nbsp;
                            <ToggleButtonGroup
                                color="primary"
                                aria-label="text alignment"
                                value={traySize}
                                exclusive
                                onChange={(e, v) => { setTraySize(v) }}>
                                <ToggleButton value={1}>1 Doz</ToggleButton>
                                <ToggleButton value={2.5}>2.5 Doz</ToggleButton>
                            </ToggleButtonGroup>
                        </Typography>
                        <Typography variant={'h5'}> Lid Off Only:&nbsp;
                            <ToggleButtonGroup
                                color="primary"
                                aria-label="text alignment"
                                value={lidOff}
                                exclusive
                                onChange={(e, v) => { setLidOff(v) }}>
                                <ToggleButton value={1}>Yes</ToggleButton>
                                <ToggleButton value={0}>No</ToggleButton>
                            </ToggleButtonGroup>
                        </Typography>
                        <SelectTextFieldNew disabled={stockItems ? completed : false} state={stockItem} setState={setStockItem} valueFunc={v => v?.id} list={selectedBench.stockItem?.species ? gradeSort(grades?.filter((ogn) => { return ogn.speciesId === selectedBench.stockItem?.species?.id })) : gradeSort(grades)} nameFunc={(g) => g.Grade} />
                        {/*Icon button edit */}
                        <Typography variant={'h1'}>Trays:</Typography>
                        <Grid container spacing={2} mb={1}>
                            {traySize === 1 ?
                                <React.Fragment>
                                    <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                        <AddValueButton addValue={addToTotal} value={20} />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                        <AddValueButton addValue={addToTotal} value={10} />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                        <AddValueButton addValue={addToTotal} value={5} />
                                    </Grid></React.Fragment> : <React.Fragment>
                                    <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                        <AddValueButton addValue={addToTotal} value={16} />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                        <AddValueButton addValue={addToTotal} value={8} />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                        <AddValueButton addValue={addToTotal} value={4} />
                                    </Grid>
                                </React.Fragment>}
                            <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                <AddValueButton addValue={addToTotal} value={2} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                <AddValueButton addValue={addToTotal} value={1} />
                            </Grid>
                            <Grid item xs={4} sm={4} md={3} lg={2.4} >
                                <Button variant="contained" color="error" onClick={clearTotal}>Clear</Button>
                            </Grid>
                        </Grid>

                        <Typography variant={'h1'}>{numberFormatwithNeg(totalToAdd)} Trays</Typography>
                        <Typography variant={'h1'}>{dozCount} Doz</Typography>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <br />
                            <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                {checkAccess(props.access, requiredEditAccess) && !error ? (loading ? <CircularProgress /> : <Button variant="contained" color="success" style={{ whiteSpace: 'nowrap', height: '50px', margin: 0 }} onClick={validate} handleClose={props.handleClose} >Add {dozCount} Doz to {selectedBench?.staffMember?.firstname + ' ' + selectedBench?.staffMember?.surname} ({selectedBench?.benchDetails.description})</Button>) : null}
                                {error ? <Typography style={{ color: 'red' }} variant={'h3'} >Error updating call IT</Typography> : null}
                            </div>
                        </Grid>
                    </Grid>


                </Grid>
            </Div>
        </Box >
    );
};

const AddInventoryForm = (props) => {
    const Swal = useSwalWrapper();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [addQuantity, setAddQuantity] = React.useState(null);
    const [inv, setInv] = React.useState(null);
    React.useEffect(() => {
        setLoading(true)
        getAPIDataParamsReturn('/Inventory/Get', { IncludeMYOB: false, ExpiryDate: props.selectedBench?.coreInventory?.expiryDate, LotNbrSubString: props.selectedBench.lotSerialNbr, StockItemId: props.selectedBench.stockItemId, IncludeZero: true }).then((minv) => {
            setLoading(false)
            setInv(minv[0] ? minv[0] : null);
        })
    }, []);
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)

        //output must be less than = to available stock

        if (addQuantity === null) {
            errorMessages.push("You must enter an aditional quantity");
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const submit = async (finished) => {
        var allOk = false;
        var respArr = [];
        setLoading(true);

        console.log(props.selectedBench)
        const params = {
            ProcessRunId: props.selectedBench?.id,
            ProductionLineId: props.selectedBench?.benchDetails?.id,
            Amount: Number(props.selectedBench?.quantity) + Number(addQuantity),
            Bins: 0,
            Bags: Number(props.selectedBench?.bags) + 1,
            Staff: props.selectedBench?.staff,
            StaffMemberId: props.selectedBench?.staffMember.id
        }
        respArr.push(await postApi('/Processing/UpdateProcess', params));

        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            props.handleClose();
        } else {
            setError("Failed to submit");
        }

    }
    return <React.Fragment>
        <ControlledTextField label="Add to Input" type="number" name="add" state={addQuantity} setState={setAddQuantity} number />
        {inv ? <Typography variant={'h5'}>There is {inv?.portalQuantity} Doz Remaining in this PRN</Typography> : null}
        {loading ? <CircularProgress /> : <Button variant="contained" color="primary" style={{ whiteSpace: 'nowrap', height: '50px', margin: 0 }} onClick={validate} handleClose={props.handleClose} >Add</Button>}

    </React.Fragment>
}


const AddValueButton = (props) => {

    return <Button
        style={{ width: '60px', height: '60px' }}
        variant="contained"
        color="primary"
        onClick={() => { props.addValue(props.value) }}>
        <Typography variant={'h1'} style={{ color: 'white', margin: 0 }} > +{props.value}</Typography>
    </Button>
}

export default HalfshellProcessForm;


